import { useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import TableOfContentsNew from "../../TableOfContaint/TableOfContentsNew";
import { LoginContext } from '../../contexts/LoginContext';
import { TestContext } from '../../contexts/TestContext';
import Styles from '../../styles/QuestionWrapper.module.css';


export default function TOCDwawer({ tatalQuestionCount, remainingQuestions, bookmarked, QuestionPerPage }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { userPrivileges } = useContext(LoginContext);
    const { test } = useContext(TestContext);
    console.log(test, "testfromcontect");
    const isPrivilege = (x) => {
        if (userPrivileges?.privileges?.test?.$values?.includes(x)) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <div>

            <div style={{ border: `solid 1px #c2c1c1`, boxShadow: '-7px 2px 6.7px 0 rgba(0, 0, 0, 0.1)', background: '#fff', marginRight: '-15px', marginLeft: '-15px' }}>
                <div className={Styles.TableOfContaintHeadingContainer}>
                    <div className={Styles.TableOfContaintHeadingTotal} style={{ marginLeft: '12px' }} >
                        <h6 style={{ fontSize: '9px' }}>{t('total')} </h6>
                        <h6 style={{ fontSize: '16px', fontWeight: 'bold' }}>{tatalQuestionCount}</h6>
                    </div>
                    <div className={Styles.TableOfContaintHeadingRemaining}>
                        <h6 style={{ fontSize: '9px' }}>{t('remaining')}</h6>
                        <h6 style={{ fontSize: '16px', fontWeight: 'bold' }}>{remainingQuestions}</h6>
                    </div>
                    <div className={Styles.TableOfContaintHeadingAttempted}>
                        <h6 style={{ fontSize: '9px' }}>{t('attempted')}</h6>
                        <h6 style={{ fontSize: '16px', fontWeight: 'bold' }}>{tatalQuestionCount - remainingQuestions}</h6>
                    </div>
                    {
                        isPrivilege("Bookmark") && test && test?.showBookmark == 2 ?
                            <div className={Styles.TableOfContaintHeading} style={{ marginRight: '12px' }}>
                                <h6 style={{ fontSize: '9px' }}>{t('bookmark')}</h6>
                                <h6 style={{ fontSize: '16px', fontWeight: 'bold' }}>{bookmarked}</h6>
                            </div> : null
                    }
                </div>
                <div>
                    {QuestionPerPage == 1 ?
                        <TableOfContentsNew /> : null}
                </div>
            </div>

        </div>
    )
}
