import React from 'react';

// Create a functional component for the "Page Under Development"
const PageUnderDevelopment = () => {
    return (
        <div style={styles.container}>
            <div style={styles.innerContainer}>
                <h1 style={styles.heading}>Page Under Development</h1>
                <div style={styles.iconContainer}>
                    <img
                        src={'/Wavy.JPG'}
                        alt="Under Construction"
                        style={styles.icon}
                    />
                </div>
            </div>
        </div>
    );
};

// Styling for the component (you can adjust these styles or use CSS-in-JS libraries like styled-components)
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f7f7f7',
        padding: '20px',
    },
    innerContainer: {
        textAlign: 'center',
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '40px',
        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '600px',
    },
    heading: {
        fontSize: '32px',
        fontWeight: 'bold',
        color: '#333',
    },
    message: {
        fontSize: '18px',
        color: '#777',
        margin: '20px 0',
    },
    iconContainer: {
        marginTop: '30px',
    },
    icon: {
        width: '100px',
        height: '100px',
    },
};

export default PageUnderDevelopment;