
import { useTheme } from '@emotion/react';
import { getMessaging, getToken } from '@firebase/messaging';
import { useMediaQuery } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockIcon from '@mui/icons-material/Lock';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Alert, Box, Button, Container, Grid, IconButton, InputAdornment, Link, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { message, Modal } from 'antd';
import axios from 'axios';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { configUrl } from '../../config';
import { LoginContext } from '../../contexts/LoginContext';
import app from '../../firebase';
import getPrivilegeService from '../../services/getPrivilegeService';
import { getSiblingData, getStudentDetail } from '../../services/parentService';
import styles from "../../styles/signin.module.css";
import useGetMedineerUser from '../../userHooks/useGetMedineerUser';
import useParentLearnerLogin from '../../userHooks/useParentLearnerLogin';
import Google from '../Common/GoogleAuthLogin';
import SwitchProvider from '../SwitchProvider';
import MedineerLogin from './MedineerLogin';
function NewSignIn() {
    const navigate = useNavigate();
    const location = useLocation();
    const { series } = location?.state || {};
    console.log("coursedata", series);
    const theme = useTheme();
    const [loginUsername, setLoginUsername] = React.useState("");
    const [parentUsername, setParentUsername] = React.useState("");
    const [isLoginAsParent, setLoginAsParent] = React.useState(false);
    const [loginPassword, setLoginPassword] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [severty, setSeverty] = React.useState("success");
    const [googleAuth, setGoogleAuth] = React.useState(false);
    const [showModel, setShowModel] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);

    const { t } = useTranslation();

    const {
        setUserName,
        UserName,
        setProviderKey,
        setProviderName,
        setEmailC,
        setProviderId,
        userPrivileges,
        setPackageId,
        setUserPrivileges,
        setUserId,
        setUserDetail,
        userDetail,
        setUserType,
        siblingDetail,
        setSiblingDetail,
        setEducationDetails,
        setIsDrawerNavigation,
        setPageKey, setIsDefaultSplash,
        setIsParentLogin
    } = useContext(LoginContext);

    const {
        data: loginDetails,
        isLoading,
        isSuccess,
        mutate: getUserDetails,
        error: userFetchError,
    } = useGetMedineerUser();

    const {
        data: parentLoginDetails,
        isLoading: isLoadingParentLogin,
        isSuccess: isSuccessParentLogin,
        mutate: getParentUserDetails,
        error: parentFetchError,
    } = useParentLearnerLogin();

    const handleChangeEmail = (e) => {
        console.log("providerIds123", e.target.value);
        setLoginUsername(e.target.value);

    };

    const handleParentUserName = (e) => {
        console.log(e.target.value, "setParentUsername");
        setParentUsername(e.target.value);
    };

    const handleChangePassword = (e) => {
        setLoginPassword(e.target.value);

    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    const handleShowCheckout = () => {
        setShowCheckout(true);
    };

    const onGoogleAuthClick = (email, auth, newUser, providerIdByUrl) => {
        if (auth) {
            setGoogleAuth(true);
            setLoginUsername(email);
            let loginInfo = {};
            loginInfo.username = email;
            loginInfo.providerId = configUrl.MediNeerProviderId;
            loginInfo.isMedinerLogin = series == null ? false : true;
            loginInfo.courseId = series == null ? 0 : series?.courseId;
            getUserDetails(loginInfo);
        } else {
            setGoogleAuth(false);
        }
    };

    const handleHideCheckout = () => {
        setShowCheckout(false);
        navigate("/medineer");
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let loginInfo = {};
        console.log("Submitting login:", loginUsername, loginPassword);

        loginInfo.username = loginUsername;
        loginInfo.providerId = configUrl.MediNeerProviderId;
        loginInfo.isMedinerLogin = series == null ? false : true;
        loginInfo.courseId = series == null ? 0 : series?.courseId;
        try {
            await getUserDetails(loginInfo); // Wait for the API call to complete.
            console.log("Login successful");
        } catch (error) {
            console.error("Error during login:", error);
        }
    };

    const onParentLoginSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let parentLoginInfo = {};
        parentLoginInfo.parentEmail = parentUsername;
        parentLoginInfo.studentUserName = loginUsername;
        parentLoginInfo.providerId = configUrl.MediNeerProviderId;
        console.log("providerIds", parentLoginInfo);
        getParentUserDetails(parentLoginInfo);
    };

    const getData = async () => {
        if (loginDetails?.userDetails) {
            // const getEduDta = await geteducationData(loginDetails?.userDetails.userId);
            // setEducationDetails(getEduDta);
            const getSiblingDta = await getSiblingData(
                loginDetails?.userDetails.userId
            );
            setSiblingDetail(getSiblingDta.$values[0]);
            const StudentDetail = await getStudentDetail(
                loginDetails?.userDetails.userId
            );
            setUserDetail(StudentDetail?.studetnInfo);
            setEducationDetails(StudentDetail?.educationInfo);

            console.log(StudentDetail, "getStudentDetail123");
        }
    };

    const getDataParent = async () => {
        if (parentLoginDetails?.userDetails) {
            const StudentDetail = await getStudentDetail(
                parentLoginDetails?.userDetails.userId
            );
            setUserDetail(StudentDetail?.studetnInfo);
            setEducationDetails(StudentDetail?.educationInfo);
            console.log(StudentDetail, "getStudentDetail123");
        }
    };
    const messaging = getMessaging(app);

    const activateMessages = async () => {
        console.log("messaging", messaging);
        const token = await getToken(messaging, {
            vapidKey:
                "BFLHmJUchm5llz8UsotJ8ztrq30QWeATAIwEb-Oxl2lEjztAG1qYRWXxkccoQJfLGY2vVbYj0NuQHdUteFT0570",
        }).catch((error) => console.log("error generatin token"));

        if (token) {
            console.log("After Login token", token);
            const payload = {
                userId: loginDetails?.userDetails.userId,
                fcmToken: token,
            };
            let isParentLogin = false;
            let response = await axios.post(
                `${configUrl.AssessmentServer}/api/Counselling/setFCMKey/${isParentLogin}`,
                payload
            );

            return response.data;
        } else if (!token) {
            console.log("no token");
        }
    };

    const handleValidityExpired = () => {
        setOpen(true);
        setSeverty("error");
        setAlertMessage("User account is not active.");
        console.log("email reset successfully.");
        setTimeout(() => {
            setOpen(false);
        }, 2500);
    };
    const showProviderList = async (providerlist) => {
        console.log(providerlist, "providerlists");
        setShowModel(
            <Modal
                style={{ fontFamily: 'Poppins', color: '#002147' }}
                title={t("select")}
                open={true}
                onOk={hideEvaluationModel}
                onCancel={hideEvaluationModel}
                footer={null}
                centered
            >
                <SwitchProvider
                    providerlist={providerlist}
                    onClose={hideEvaluationModel}
                    onValidityExpired={handleValidityExpired}
                    redirectToMain={false}
                />
            </Modal>
        );
    };

    const hideEvaluationModel = (event, reason) => {
        console.log("reason", reason);
        if (reason === "backdropClick") {
            return;
        }
        setShowModel(null);
    };
    const setPrivileges = async () => {
        const privilegesDetails = await getPrivilegeService.getPrivileges(
            loginDetails?.userDetails.userId
        );
        setUserPrivileges(privilegesDetails);
        setPackageId(privilegesDetails.packageId);
    };
    const convertToLocalTime = (utcDateTime, endOfDay = false) => {
        const date = new Date(utcDateTime);
        const localDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60 * 1000
        );

        if (endOfDay) {
            localDate.setHours(23, 59, 59, 999);
        } else {
            localDate.setHours(0, 0, 0, 0);
        }
        console.log(localDate, "localDates");
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, "0");
        const day = String(localDate.getDate()).padStart(2, "0");
        const hours = String(localDate.getHours()).padStart(2, "0");
        const minutes = String(localDate.getMinutes()).padStart(2, "0");
        const seconds = String(localDate.getSeconds()).padStart(2, "0");
        const milliseconds = String(localDate.getMilliseconds()).padStart(3, "0");

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    };
    const getCurrentDateTimeFormatted = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
        return `${year}-${month}-${day}T00:00:00.00Z`;
    };

    useEffect(() => {
        if (userFetchError === "Not Found") {
            setLoading(false);
            setOpen(true);
            setSeverty("error");
            setAlertMessage("Please check userId or password");
            console.log("user name not exist.");
        }
        if (isSuccess) {
            if (
                loginDetails?.userDetails &&
                loginDetails?.userDetails.userName.toLowerCase() ==
                loginUsername.toLowerCase() &&
                (loginDetails?.userDetails.passCode == loginPassword ||
                    googleAuth == true)
            ) {
                console.log(loginDetails, "CVer123");
                setUserId(loginDetails?.userDetails.userId);
                setUserName(loginDetails?.userDetails.fullName);
                setEmailC(loginDetails?.userDetails.userName);
                // let privileges = loginDetails?.privilegeObject ? JSON.parse(loginDetails?.privilegeObject) : "";
                // setUserPrivileges(privileges?.Value);

                setPageKey(1);

                if (loginDetails.userDetails.providerLists["$values"].length === 1) {
                    const validFromLocal = convertToLocalTime(
                        loginDetails?.userDetails.validFrom,
                        false
                    );
                    const validToLocal = convertToLocalTime(
                        loginDetails?.userDetails.validTo,
                        false
                    );
                    const now = getCurrentDateTimeFormatted();

                    if (
                        new Date(now) >= new Date(validFromLocal) &&
                        new Date(now) <= new Date(validToLocal)
                    ) {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        setProviderId(loginDetails.userDetails.providerId);
                        axios.defaults.headers.common = {
                            providerId: loginDetails.userDetails.providerId,
                        };
                        setProviderName(
                            loginDetails.userDetails.providerLists["$values"][0].providerName
                        );
                        setPrivileges();
                        getData();
                        sessionStorage.setItem("login", true);
                        console.log("navigatingggg  from signup");
                        setIsDefaultSplash(false);
                        if (series && loginDetails?.isAlreadyPurchasedCourse == true) {
                            message.success("You've already purchased this test.It's assigned to you.");
                            setPageKey(6);
                            setIsDrawerNavigation(false);
                            setTimeout(() => {
                                navigate("/Home");
                            }, 1000);
                        } else if (series) {
                            //navigate("/Home", { state: { series } });
                            handleShowCheckout();
                        } else {
                            navigate("/Home");
                        }
                        setLoading(false);
                        activateMessages();
                    } else {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        console.log(
                            new Date(now) >= new Date(validFromLocal),
                            "xx",
                            new Date(now) <= new Date(validToLocal),
                            "case"
                        );
                        setLoading(false);
                        setAlertMessage("User account is not active.");
                        setSeverty("error");
                        setOpen(true);
                    }
                } else {
                    setLoading(false);
                    showProviderList(
                        loginDetails.userDetails.providerLists != null &&
                        loginDetails.userDetails.providerLists["$values"]
                    );
                }
            } else {
                setLoading(false);
                setOpen(true);
                setSeverty("error");
                setAlertMessage("Please check userId or password");
                console.log("check username and password ");
            }
        } else {
            setLoading(false);
            console.log("user name not exist.");
        }
    }, [loginDetails]);

    useEffect(() => {
        if (parentFetchError === "Not Found") {
            setLoading(false);
            setOpen(true);
            setSeverty("error");
            setAlertMessage("Please check userId or password");
            console.log("user name not exist.");
        }
        console.log("parentdata", parentLoginDetails);
        if (isSuccessParentLogin) {
            if (
                parentLoginDetails?.userDetails &&
                parentLoginDetails?.userDetails.userName.toLowerCase() ==
                loginUsername.toLowerCase() &&
                (parentLoginDetails?.userDetails.passCode == loginPassword ||
                    googleAuth == true)
            ) {
                console.log(parentLoginDetails, "CVer123");
                setUserId(parentLoginDetails?.userDetails.userId);
                setUserName(parentLoginDetails?.userDetails.fullName);
                setEmailC(parentLoginDetails?.userDetails.userName);
                setIsParentLogin(true);
                // let privileges = loginDetails?.privilegeObject ? JSON.parse(loginDetails?.privilegeObject) : "";
                // setUserPrivileges(privileges?.Value);

                setPageKey(1);
                // Common Url - check diff 18/05/2024.
                if (parentLoginDetails.userDetails.providerLists["$values"].length === 1) {
                    const validFromLocal = convertToLocalTime(
                        parentLoginDetails?.userDetails.validFrom,
                        false
                    );
                    const validToLocal = convertToLocalTime(
                        parentLoginDetails?.userDetails.validTo,
                        false
                    );
                    const now = getCurrentDateTimeFormatted();

                    if (
                        new Date(now) >= new Date(validFromLocal) &&
                        new Date(now) <= new Date(validToLocal)
                    ) {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        setProviderId(parentLoginDetails.userDetails.providerId);
                        axios.defaults.headers.common = {
                            providerId: parentLoginDetails.userDetails.providerId,
                        };
                        setProviderName(
                            parentLoginDetails.userDetails.providerLists["$values"][0].providerName
                        );
                        //setPrivileges();
                        let privileges = parentLoginDetails?.privilegeObject
                            ? JSON.parse(parentLoginDetails?.privilegeObject)
                            : "";
                        console.log("Provider Privileges", privileges);
                        setUserPrivileges(privileges?.Value);
                        console.log("Provider PackageId", privileges?.Value.PackageId);
                        setPackageId(privileges?.Value.PackageId);
                        getDataParent();
                        sessionStorage.setItem("login", true);
                        console.log("navigatingggg  from signup");
                        navigate("/Home");
                        activateMessages();
                    } else {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        console.log(
                            new Date(now) >= new Date(validFromLocal),
                            "xx",
                            new Date(now) <= new Date(validToLocal),
                            "case"
                        );
                        setAlertMessage("User account is not active.");
                        setSeverty("error");
                        setOpen(true);
                        setLoading(false);
                    }
                } else {
                    // showProviderList(
                    //     loginDetails.userDetails.providerLists != null &&
                    //     loginDetails.userDetails.providerLists["$values"]
                    // );
                    const validFromLocal = convertToLocalTime(
                        parentLoginDetails?.userDetails.validFrom,
                        false
                    );
                    const validToLocal = convertToLocalTime(
                        parentLoginDetails?.userDetails.validTo,
                        false
                    );
                    const now = getCurrentDateTimeFormatted();

                    if (
                        new Date(now) >= new Date(validFromLocal) &&
                        new Date(now) <= new Date(validToLocal)
                    ) {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        setProviderId(parentLoginDetails.userDetails.providerId);
                        axios.defaults.headers.common = {
                            providerId: parentLoginDetails.userDetails.providerId,
                        };
                        let providerLists = parentLoginDetails.userDetails.providerLists["$values"];
                        let data = providerLists.find(item => item.id == parentLoginDetails.userDetails.providerId);
                        console.log("doublelogin", providerLists, data);
                        setProviderName(
                            data?.providerName
                        );
                        let privileges = parentLoginDetails?.privilegeObject
                            ? JSON.parse(parentLoginDetails?.privilegeObject)
                            : "";
                        console.log("Provider Privileges", privileges);
                        setUserPrivileges(privileges?.Value);
                        console.log("Provider PackageId", privileges?.Value.PackageId);
                        setPackageId(privileges?.Value.PackageId);
                        getDataParent();
                        sessionStorage.setItem("login", true);
                        console.log("navigatingggg  from signup");
                        navigate("/Home");
                        activateMessages();
                    } else {
                        console.log(
                            "is inactive acc1",
                            now,
                            "From",
                            validFromLocal,
                            "To",
                            validToLocal
                        );
                        console.log(
                            new Date(now) >= new Date(validFromLocal),
                            "xx",
                            new Date(now) <= new Date(validToLocal),
                            "case"
                        );
                        setLoading(false);
                        setAlertMessage("User account is not active.");
                        setSeverty("error");
                        setOpen(true);
                    }
                }
            } else {
                setLoading(false);
                setOpen(true);
                setSeverty("error");
                setAlertMessage("Please check userId or password");
                console.log("check username and password ");
            }
        } else {
            setLoading(false);
            console.log("user name not exist.");
        }
    }, [parentLoginDetails]);


    const changeLang = (data) => {
        // alert(data);
        sessionStorage.setItem("lang", data);
        i18next.changeLanguage(data);
    };
    useEffect(() => {
        let currentLang = sessionStorage.getItem("lang");
        i18next.changeLanguage(currentLang);
    }, []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Grid container>
            {/* <Grid item xs={12} sm={12} md={7}
                style={{
                    backgroundImage: 'url("/SigninBanner2.PNG")',
                    backgroundSize: 'contain',  // Use 'contain' to avoid cropping the image
                    backgroundPosition: 'center',  // Keep the image centered
                    backgroundRepeat: 'no-repeat',  // Prevent image from repeating
                    height: '100vh',  // Adjust the height to your needs
                }}></Grid> */}
            <Grid
                item
                xs={12}
                sm={12}
                md={7}
                style={{
                    backgroundColor: '#fef8f8', // Set background color
                    height: '100vh',  // Adjust height as needed
                    display: isMobile ? 'none' : 'flex',  // Use flexbox to center the SVG if needed
                    // justifyContent: 'center',  // Center horizontally
                    alignItems: 'center',  // Center vertically
                }}
            >
                <img
                    src="/SignInBannermerged.PNG"  // Replace with your SVG path
                    alt="SVG Image"
                    style={{
                        width: '50%',   //'auto',  // Adjust width as necessary
                        height: 'auto',  // Adjust height as necessary
                        position: 'absolute',
                        left: '10%',
                        // overflow: 'hidden'
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={5}
                sx={{ background: '#fff', boxShadow: '-5px 0 9.2px 0 rgba(0, 0, 0, 0.05)' }}>
                {/* <div style={{ height: '33vh', display: !isMobile ? 'none' : '' }}> */}
                <img
                    src="/SignInBanner11.PNG"  // Replace with your SVG path
                    alt="SVG Image"
                    style={{
                        width: '100%',   //'auto',  // Adjust width as necessary
                        height: '100%',  // Adjust height as necessary
                        position: 'relative',
                        height: '33vh',
                        display: !isMobile ? 'none' : ''
                        // left: '10%',
                        // overflow: 'hidden'
                    }}
                />
                {/* </div> */}
                <Container maxWidth="sm"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: isMobile ? '67vh' : '100vh',
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            padding: '2rem',
                            boxShadow: 'none !important',
                            background: 'transparent',
                            borderRadius: '10px',
                            maxWidth: "450px",
                            width: "100%",
                        }}
                    >
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontSize: '32px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'center',
                            color: '#002147',
                        }} align="center" gutterBottom>
                            LOGIN
                        </Typography>
                        {isLoginAsParent ?
                            (<>
                                <Box component="form" onSubmit={onParentLoginSubmit} sx={{ mt: 3 }}>
                                    <Grid item xs={12}>
                                        {/* <Typography variant="h5" align="left" gutterBottom>
                                            Parent Email
                                        </Typography> */}
                                        <TextField fullWidth type="email" variant="outlined" required
                                            name="parentName"
                                            size="small"
                                            onChange={handleParentUserName}
                                            sx={{ mb: 1 }}
                                            InputProps={{
                                                style: {
                                                    fontSize: "1.5rem",
                                                    borderRadius: '30px', // Apply borderRadius to the input element itself
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailOutlinedIcon sx={{ fontSize: "x-large" }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            InputLabelProps={{ shrink: false }}
                                            placeholder='Parent Email'
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'Poppins', // Apply Poppins font to placeholder text
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Typography variant="h5" align="left" gutterBottom>
                                            Password
                                        </Typography> */}
                                        <TextField fullWidth
                                            type={showPassword ? "text" : "password"}
                                            variant="outlined"
                                            required
                                            name="password"
                                            size="small"
                                            sx={{ mb: 1 }}
                                            onChange={handleChangePassword}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleTogglePasswordVisibility}
                                                            onMouseDown={(e) => e.preventDefault()} // Prevent focus loss on click
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockIcon sx={{ fontSize: "x-large" }} />
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    fontSize: "1.5rem",
                                                    borderRadius: '30px', // Apply borderRadius to the input element itself
                                                }
                                            }}
                                            InputLabelProps={{ shrink: false }}
                                            placeholder='Password'
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'Poppins', // Apply Poppins font to placeholder text
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Typography variant="h5" align="left" gutterBottom>
                                            Student Email
                                        </Typography> */}
                                        <TextField fullWidth type="email" variant="outlined" required
                                            name="userName"
                                            size="small"
                                            onChange={handleChangeEmail}
                                            sx={{ mb: 1 }}
                                            InputProps={{
                                                style: {
                                                    fontSize: "1.5rem",
                                                    borderRadius: '30px', // Apply borderRadius to the input element itself
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailOutlinedIcon sx={{ fontSize: "x-large" }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder='Student Email'
                                            InputLabelProps={{ shrink: false }}
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'Poppins', // Apply Poppins font to placeholder text
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size='small'

                                            //onClick={onSubmit}
                                            sx={{
                                                width: '100%', // Button width reduced and centered
                                                paddingY: '10px',
                                                fontSize: '1.4rem',
                                                borderRadius: '32px',
                                                backgroundColor: '#012248',
                                                color: '#fff',
                                                fontFamily: 'Poppins',
                                                textTransform: 'none',
                                                '&:hover': {
                                                    backgroundColor: '#012248', // Keep the background color the same on hover
                                                },
                                            }}
                                            disabled={loading}
                                        >
                                            {loading ? "logging..." : "Login"}
                                        </Button>
                                    </Box>
                                </Box>
                                <Box textAlign="center" sx={{ mt: 2 }}>
                                    <Typography variant="h6" sx={{ color: '#817c7c', fontFamily: 'Poppins' }}>
                                        Back to &nbsp;
                                        <u>
                                            <Link onClick={() => setLoginAsParent(false)} style={{ cursor: 'pointer', color: '#333333' }}>
                                                sign in
                                            </Link>
                                        </u>
                                    </Typography>
                                </Box>
                            </>
                            )
                            :
                            (
                                <>
                                    <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
                                        <Grid item xs={12}>
                                            {/* <Typography variant="h5" align="left" gutterBottom>
                                        <EmailOutlinedIcon sx={{ fontSize: '21px' }} />
                                        Email
                                    </Typography> */}
                                            <TextField
                                                fullWidth
                                                type="email"
                                                variant="outlined"
                                                required
                                                name="userName"
                                                size="small"
                                                onChange={handleChangeEmail}
                                                sx={{ mb: 1, borderRadius: '32px', }}
                                                placeholder='Email Address'
                                                InputProps={{
                                                    style: {
                                                        fontSize: "1.5rem",
                                                        borderRadius: '30px', // Apply borderRadius to the input element itself
                                                    },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EmailOutlinedIcon sx={{ fontSize: "x-large" }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                // label="Email" // This is the label you want to add
                                                InputLabelProps={{ shrink: false }}
                                                inputProps={{
                                                    style: {
                                                        fontFamily: 'Poppins', // Apply Poppins font to placeholder text
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <Typography variant="h5" align="left" gutterBottom>
                                        <LockIcon />
                                        Password
                                    </Typography> */}
                                            <TextField fullWidth
                                                type={showPassword ? "text" : "password"}
                                                variant="outlined"
                                                required
                                                name="password"
                                                size="small"
                                                sx={{ mb: 1, borderRadius: '32px', }}
                                                onChange={handleChangePassword}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={handleTogglePasswordVisibility}
                                                                onMouseDown={(e) => e.preventDefault()} // Prevent focus loss on click
                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockIcon sx={{ fontSize: "x-large" }} />
                                                        </InputAdornment>
                                                    ),
                                                    style: {
                                                        fontSize: "1.5rem",
                                                        borderRadius: '30px', // Apply borderRadius to the input element itself
                                                    }
                                                }}
                                                placeholder='Password'
                                                InputLabelProps={{ shrink: false }}
                                                inputProps={{
                                                    style: {
                                                        fontFamily: 'Poppins', // Apply Poppins font to placeholder text
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        {/* <Box textAlign="center" sx={{ mt: 2 }}>
                                            <Typography variant="h6">
                                                <Link onClick={() => setLoginAsParent(true)} style={{ cursor: 'pointer', color: theme.palette.primary.main }}>
                                                    Login as Parent
                                                </Link>
                                            </Typography>
                                        </Box> */}
                                        {/* <Form.Item
                                        >
                                            <a
                                                className={styles.forgotPass}
                                                style={{
                                                    color: '#333',
                                                    fontFamily: 'Poppins',
                                                    opacity: '0.7'
                                                }}
                                            // onClick={handleForgotPasswordStatus}
                                            >
                                                {t("forgotPass")}
                                            </a>
                                        </Form.Item> */}
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                size='small'
                                                fullWidth
                                                //onClick={onSubmit}
                                                sx={{
                                                    width: '100%', // Button width reduced and centered
                                                    paddingY: '10px',
                                                    fontSize: '1.4rem',
                                                    borderRadius: '32px',
                                                    backgroundColor: '#012248',
                                                    color: '#fff',
                                                    fontFamily: 'Poppins',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        backgroundColor: '#012248', // Keep the background color the same on hover
                                                    },
                                                }}
                                                disabled={loading}
                                            >
                                                {loading ? "logging..." : "Login"}
                                            </Button>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                size='small'
                                                fullWidth
                                                //onClick={onSubmit}
                                                onClick={() => setLoginAsParent(true)}
                                                sx={{
                                                    width: '100%', // Button width reduced and centered
                                                    paddingY: '10px',
                                                    fontSize: '1.4rem',
                                                    border: 'none',
                                                    borderRadius: '32px',
                                                    backgroundColor: '#ededed',
                                                    color: '#535050',
                                                    fontFamily: 'Poppins',
                                                    textTransform: 'none',
                                                }}
                                                disabled={loading}
                                            >
                                                <SupervisorAccountIcon sx={{ fontSize: 'x-large', color: '#a4a4a4', marginInline: '3%' }} />
                                                Login as Parent
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            )}
                        {isLoginAsParent == false &&
                            <Box textAlign="center" sx={{ mt: 2 }}>
                                <div className={styles.flotingButtons}>
                                    <Button>
                                        <GoogleOAuthProvider clientId="233834340339-695vkqrle4p424vl6sspaj5t716l3246.apps.googleusercontent.com">
                                            <Google
                                                onClick={(email, auth, newUser) => {
                                                    setLoading(true);
                                                    onGoogleAuthClick(email, auth, newUser, configUrl.providerIdByUrl);
                                                }
                                                }
                                                providerId={configUrl.providerIdByUrl}
                                                medineer={"SignIn"}
                                            />
                                        </GoogleOAuthProvider>
                                    </Button>
                                </div>
                                <Typography variant="h6" fontFamily={'Poppins'} color={'#535050'}>
                                    {series != null ? <>
                                        Don't have an account?{' '}
                                        <Link onClick={() => navigate('/medineer/SignUp', { state: { series } })} style={{ cursor: 'pointer', color: '#002147' }}>
                                            Sign Up
                                        </Link>
                                    </> : ''}
                                </Typography>
                            </Box>
                        }
                    </Paper>
                    <Snackbar
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        open={open}
                        onClose={handleClose}
                        autoHideDuration={3000}
                    >
                        <Alert
                            severity={severty}
                            onClose={handleClose}
                            sx={{ fontSize: "15px", width: "100%" }}
                        >
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                    {showModel}
                    {
                        showCheckout && <MedineerLogin course={series} existingUserEmail={loginDetails?.userDetails?.userId != 0 ? null : loginDetails?.userDetails?.userName} closeModal={handleHideCheckout} />
                    }
                </Container>
            </Grid>
        </Grid>
    );
}

export default NewSignIn;
