import React, { useContext } from "react";
// material-ui components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import { useMediaQuery } from "@material-ui/core";
import Button from "../CustomButtons/Button";

import MuiAlert from "@material-ui/lab/Alert";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardHeader from "../../Card/CardHeader";
import styles from "../../assets/modalStyle";
import { LoginContext } from "../../contexts/LoginContext";
import iconStyle from '../../styles/TestPlayer.module.css';
import WarningMessege from "../WarningMessege";
import WarningCustomModal from "./WarningCustomModal";
const useStyles = makeStyles(styles);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={1000} />;
});

export default function CustomModal(props) {
  const { t } = useTranslation();
  const { alertFlag, setAlertFlag } = useContext(LoginContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [warningModal, setWarningModal] = React.useState(null);

  const hideNotificationModal = (event, reason) => {
    if (reason) console.log("reason", reason);
    setWarningModal(null);
  };

  console.log("alertFlag", alertFlag)
  const classes = useStyles();
  const okayClick = () => {
    props.setModal(false)
  }
  const onHandleClose = () => {
    if (props.showAlertAllowed) {
      if (alertFlag) {
        setWarningModal(
          <WarningCustomModal
            modal={true}
            setModal={hideNotificationModal}
            content={
              <WarningMessege message={t('alertMsg3')} />
            }
            showOk={true}
            okButtonMessage={'Yes'}
            showCancel={true}
            cancelButtonMessage={'No'}
            onOkClick={okayClick}
            onCancelClick={hideNotificationModal}
            title={'Warning'}
            // maxWidth="lg"
            fullWidth={false}
            hideTitle={true}
          />
        )

        console.log("you cannot close while test ongoing");
        // props.setModal(false);
      } else {
        console.log("you can close now")
        props.setModal(false);
      }
    } else {
      props.setModal(false);
    }
  }
  return (
    <div>
      <Dialog
        disableEnforceFocus
        open={props.modal}
        transition={Transition}
        // onClose={props.setModal}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        fullWidth={'90%'}
        fullScreen={props.fullScreen ?? fullScreen}

        maxWidth={props.maxWidth}

      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{ background: theme.palette.background.default }}
        >
          <CardHeader className={iconStyle.HeaderIcon}>
            {/*BSS121  */}
            {/* <CardIcon color={"warning"} style={{ color: "white" }}>
              <HelpOutline />
            </CardIcon> */}
          </CardHeader>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => { onHandleClose() }}
          >
            <Close className={classes.modalClose} />
          </Button>
          {props.hideTitle ? (
            <h4 className={classes.modalTitle} style={{ color: theme.palette.primary.main }}>{props.title}</h4>
          ) : null}
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={
            props.padding1
              ? {
                height: props.height ? props.height : "inherit",
                background: '#f5f6fa'
              }
              : {
                padding: props.padding ? props.padding : 1,
                height: props.height ? props.height : "inherit",
                background: props.isGrayBackground == true ? '#f5f6fa' : ''
              }
          }
        >
          {props.content}
        </DialogContent>
        {/* <ModalFooter>
          Balaji
        </ModalFooter> */}
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          {props.showCancel ? (
            <Button
              onClick={() =>
                props.onCancelClick
                  ? props.onCancelClick()
                  : props.setModal(false)
              }
              style={{
                boxShadow: '0 1px 4px 0 rgba(25, 33, 61, 0.08)',
                borderRadius: '10px',
                lineHeight: '1.38',
                fontWeight: 'bold',
                fontFamily: 'Poppins',
              }}
            >
              {props.cancelButtonMessage}
            </Button>
          ) : null}
          {props.showOk ? (
            <Button
              onClick={() =>
                props.onOkClick ? props.onOkClick(props.setModal(false)) : props.setModal(false)
              }
              // color="primary"
              style={{
                backgroundColor: '#001124',
                boxShadow: '0 1px 4px 0 rgba(25, 33, 61, 0.08)',
                borderRadius: '10px',
                lineHeight: '1.38',
                fontWeight: 'bold',
                fontFamily: 'Poppins',
              }}
            >
              {props.okButtonMessage}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      {warningModal}
    </div>
  );
}
