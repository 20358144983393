import { GoogleLogin } from '@react-oauth/google';
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React from 'react';
import { useParams } from "react-router-dom";
import { configUrl } from "../../config";
import userService from "../../services/userService";

const Google = (props) => {
    const { pKey } = useParams();
    let providerId = 0;
    console.log('google-login : ' + props.providerId)
    if (props.providerId) {
        providerId = props.providerId;
    }

    let defaultPassword = "Abc@1234";
    return (
        <GoogleLogin
            onSuccess={async (credentialResponse) => {

                if (credentialResponse && credentialResponse.credential) {
                    const decoded = jwtDecode(credentialResponse.credential);
                    console.log('decoded', decoded, 'provider', providerId)
                    console.log('decoded email ', decoded.email, "props: ", props);
                    let isMedineer = false;
                    let courseId = 0;
                    if (props?.medineer) {
                        isMedineer = props?.medineer == "SignUp" ? true : false;
                        courseId = props?.courseId ? props?.courseId : 0;
                    }
                    // CommonUrl-
                    // axios.get(`${configUrl.AssessmentServer}/api/User/Name/${decoded.email}/${providerId}`)
                    axios.get(isMedineer == true ? `${configUrl.AssessmentServer}/api/User/Name/${decoded.email}/${0}?isMedineerLogin=${isMedineer}&&courseId=${courseId}`
                        : `${configUrl.AssessmentServer}/api/User/Name/${decoded.email}/${0}`)
                        .then(response => {
                            // Handle response
                            console.log('response log: ', response.data);
                            let userData = response.data?.userDetails;
                            if (response.data?.isAlreadyPurchasedCourse == true) {
                                userData.isAlreadyPurchasedCourse = true
                            }
                            console.log('user exist will login')
                            props.onClick(decoded.email, true, userData)
                        })
                        .catch(async err => {
                            // Handle errors
                            if (err.response.data.status == '404') {

                                // if (pKey) {
                                //     providerId = pKey
                                // }
                                let newUser = {
                                    userName: decoded.email,
                                    firstName: decoded.given_name,
                                    lastName: decoded.family_name,
                                    fullName: decoded.given_name + decoded.family_name,
                                    middleName: '',
                                    mobile: '',
                                    email: decoded.email,
                                    passCode: defaultPassword,
                                    address: '',
                                    isActive: true,
                                    userTypeId: 4,
                                    gender: 0,
                                    topics: "",
                                    createdBy: 'system',
                                    createdDate: new Date(),
                                    updatedBy: 'system',
                                    updatedDate: new Date(),
                                    providerId: providerId,
                                }
                                console.log('will add new user', newUser)
                                if (props?.medineer == "SignUp" || props?.medineer == "SignIn") {
                                    console.log("call from medineer sign up.")
                                } else {
                                    await userService.createUser(newUser)
                                }
                                console.log('user added')
                                props.onClick(decoded.email, true, newUser)
                            }
                        });

                }
            }}
            onError={() => {
                props.onClick('', false, null)
                console.log('Login Failed');
            }}
        />
    )
}
export default Google;