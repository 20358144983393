import { useTheme } from '@mui/material';
import { Tooltip } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../contexts/LoginContext';
import styles from '../styles/Dashboardtable.module.css';

export default function DashboardTable({ topFive }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const { UserId, packageId } = useContext(LoginContext);
    console.log(topFive, 'topFive123');

    // Inline styles for the table
    const tableStyle = {
        width: '100%',
        // border: '1px solid black',
        borderCollapse: 'collapse',
        fontFamily: 'Poppins',
        fontSize: '16px',
        // fontWeight: '600'
    };

    const cellStyle = {
        border: '1px solid #cbd0e3',
        padding: '8px',
        // textAlign: 'left',
    };
    const evenRowStyle = {
        backgroundColor: '#f2f2f2',
    };
    return (
        <table style={tableStyle}>
            <thead>
                <tr>
                    <th className={styles.HeadLeft} style={{ ...cellStyle, color: theme.palette.sectionHeading.main }}>{t('code')}</th>
                    <th className={styles.HeadLeft} style={{ ...cellStyle, color: theme.palette.sectionHeading.main }}>{t('testname')}</th>
                    {
                        (packageId === 1 || packageId === 2 || packageId === 3) && (
                            <th className={styles.Head} style={{ ...cellStyle, color: theme.palette.sectionHeading.main }}>{t('you')}</th>
                        )
                    }
                    {
                        packageId === 3 && (
                            <>
                                <th className={styles.Head} style={{ ...cellStyle, color: theme.palette.sectionHeading.main }}>{t('avg')}</th>
                                <th className={styles.Head} style={{ ...cellStyle, color: theme.palette.sectionHeading.main }}>{t('top')}</th>
                            </>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    topFive && topFive.length > 0 ? topFive.map((i, index) => (
                        <tr key={index} style={index % 2 === 0 ? evenRowStyle : {}}>
                            <td style={{ ...cellStyle, textAlign: 'left' }}>{i.code}</td>
                            <td style={{ ...cellStyle, textAlign: 'left', maxWidth: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                <Tooltip title={i.name} placement="bottom" arrow={false} style={{ fontSize: "1em" }}>
                                    <span>{i.name}</span>
                                </Tooltip>
                            </td>
                            {
                                (packageId === 1 || packageId === 2 || packageId === 3) && (
                                    <td style={{ ...cellStyle, textAlign: 'center' }}>{i?.your?.toFixed(2)}</td>
                                )
                            }
                            {
                                packageId === 3 && (
                                    <>
                                        <td style={{ ...cellStyle, textAlign: 'center' }}>{i?.average?.toFixed(2)}</td>
                                        <td style={{ ...cellStyle, textAlign: 'center' }}>{i?.top?.toFixed(2)}</td>
                                    </>
                                )
                            }
                        </tr>
                    )) :
                        (
                            <tr className={styles.spanrow}>
                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                    <h5 style={{ marginTop: '15%', marginBottom: '15%' }}>{t("TestsNotFoundAnalytics")}</h5>
                                </td>
                            </tr>
                        )
                }
            </tbody>
        </table>
    );
}
