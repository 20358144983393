import { grayColor } from "./material-dashboard-pro-react";

const modalStyle = (theme) => ({
  modalRoot: {
    overflow: "auto",
    alignItems: "unset",
    justifyContent: "unset",
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "1500px",
      margin: "auto",
    },
    borderRadius: "6px",
    marginTop: "40px !important",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content",
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: '7rem',  //"16.43px",
    // modal Header BSS121
    background: '#ffffff !important',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)',
  },
  modalTitle: {
    margin: "0",
    lineHeight: "1.42857143",
    color: '#001124 !important',
    fontFamily: 'Poppins !important',
    // fontSize: 16px
  },
  modalCloseButton: {
    color: grayColor[0],
    marginTop: "-12px",
    WebkitAppearance: "none",
    padding: "0",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
    alignItems: 'center',
    // borderRadius: '20px'
  },
  modalClose: {
    width: "16px",
    height: "16px",
  },
  modalMenu: {
    width: "3rem !important",
    height: "3rem !important",
    color: '#443a3a !important',
  },
  modalBody: {
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "16px",
    paddingLeft: "24px",
    position: "relative",
    overflow: "visible",
    fontFamily: 'Poppins !important',
    fontColor: '#001124'

  },
  modalFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0",
  },
  hideFooter: {
    display: 'none'
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  instructionNoticeModal: {
    marginBottom: "25px",
  },
  imageNoticeModal: {
    maxWidth: "150px",
  },
  modalSmall: {
    width: "300px",
  },
  modalSmallBody: {
    paddingTop: "0",
  },
  modalSmallFooterFirstButton: {
    margin: "0",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "auto",
  },
  modalSmallFooterSecondButton: {
    marginBottom: "0",
    marginLeft: "5px",
  },
});

export default modalStyle;
