import { CircularProgress } from '@material-ui/core';
import { Grid, useTheme } from '@mui/material';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import CustomModal from '../Components/CustomModal/CustomModal';
import { UseGetPieChartHeirarchy } from '../Components/analyticsHooks/UseGetPieChartData';
import TreeHierarchy from '../Tables/TreeHierarchy';
import { LoginContext } from '../contexts/LoginContext';

const PieChartComponent = ({ pieChartAnalyticsData }) => {
    console.log("Received data:", pieChartAnalyticsData);
    const { UserId } = useContext(LoginContext);
    const theme = useTheme();
    const [evaluationModel, setEvaluationModel] = React.useState(null);
    const [pieChartHeirarchyData, setPieChartHeirarchyData] = React.useState();

    useEffect(() => {
        const getHeirarchyData = async () => {
            try {
                const heirarchydata = await UseGetPieChartHeirarchy(UserId);
                console.log("sampledata", heirarchydata);
                if (heirarchydata != null) {
                    setPieChartHeirarchyData(heirarchydata);
                }
            } catch (e) {
                console.log("errrr getting heirarchy data", e);
            }
        }
        getHeirarchyData();
    }, [UserId])
    const [data, setData] = useState({
        totalQuestions: 0,
        unattempted: 0,
        correct: 0,
        incorrect: 0,
    });

    const showAnalytics = async (lOid, testName) => {
        console.log('Click3')
        setEvaluationModel(
            <CustomModal
                modal={true}
                setModal={hideEvaluationModel}
                content={
                    <TreeHierarchy analyticsData={pieChartHeirarchyData}
                        pieChartAnalyticsData={pieChartAnalyticsData}
                    />
                }
                showOk={false}
                showCancel={false}
                onCancelClick={hideEvaluationModel}
                title={t("whereareYou")}
                maxWidth="lg"
                hideTitle={true}
                fullScreen={true}
                fullWidth={false}
                padding={-1}
                isGrayBackground={true}
            />
        )
    }
    const hideEvaluationModel = (event, reason) => {
        if (reason) console.log("reason", reason);
        setEvaluationModel(null);
    };


    useEffect(() => {
        if (pieChartAnalyticsData) {
            setData({
                totalQuestions: pieChartAnalyticsData.totalQuestions ?? 0,
                unattempted: pieChartAnalyticsData.unattempted ?? 0,
                correct: pieChartAnalyticsData.correct ?? 0,
                incorrect: pieChartAnalyticsData.incorrect ?? 0,
            });
        }
    }, [pieChartAnalyticsData]);

    const pieData = {
        series: [data.unattempted, data.correct, data.incorrect],
        options: {
            chart: {
                type: 'donut',
            },
            // #1D63E7(blue)#A9A9A9(dark grey) #D3D3D3(light grey)
            // '#9da39d' (Try grey learnerinterface)
            labels: [t("unattempted"), t("correct"), t("incorrect")],
            colors: ['#dbdbdb', '#23eb23', '#ff6961'], // colors for unattempted, correct, incorrect
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + t("questions");
                    }
                }
            },
            responsive: [{
                breakpoint: 450,
                options: {
                    chart: {
                        // width: 500
                    },
                    legend: {
                        show: false,
                        // position: 'bottom'
                    }
                }
            }],
            legend: {
                // position: 'bottom',
                show: false,
                labels: {
                    colors: ['#dbdbdb', '#23eb23', '#ff6961'],
                    useSeriesColors: false
                }
            }
        }
    };

    return (
        <div style={{ width: '100%', height: '100%', minHeight: '375px', margin: '0 auto', }}>
            <Grid container justifyContent='center'>
                {pieChartAnalyticsData && pieChartAnalyticsData != null && pieChartAnalyticsData?.totalQuestions == 0 ? (<h5 style={{ color: '#aaaaaa', fontFamily: 'Poppins', paddingTop: '150px' }}>No progress yet. Please attempt a test to see your progress here.</h5>)
                    : <><Grid item xs={12} sm={6} md={6}>
                        {pieChartAnalyticsData ?
                            <ReactApexChart options={pieData.options} series={pieData.series} type="donut" height={350} />
                            : <CircularProgress
                                style={{ size: "1rem", color: theme.palette.heading.main, marginLeft: '40px', marginTop: '120px' }}
                            />}
                    </Grid>
                        <Grid item xs={12} sm={5} md={5} sx={{ padding: '7% 0' }}>
                            {
                                pieChartAnalyticsData && pieChartHeirarchyData ?
                                    (
                                        <>
                                            {/* Custom Legends */}
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                    <div
                                                        style={{
                                                            borderRadius: '50%',
                                                            width: '15px',
                                                            height: '15px',
                                                            backgroundColor: '#dbdbdb',
                                                            marginRight: '10px',
                                                            fontFamily: 'Poppins',
                                                            fontSize: '18px',
                                                            color: '#2e2f30'
                                                        }}
                                                    ></div>
                                                    <span style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#2e2f30' }}>{t("unattempted")}</span>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                    <div
                                                        style={{
                                                            borderRadius: '50%',
                                                            width: '15px',
                                                            height: '15px',
                                                            backgroundColor: '#23eb23',
                                                            marginRight: '10px',
                                                            fontFamily: 'Poppins',
                                                            fontSize: '18px',
                                                            color: '#2e2f30'
                                                        }}
                                                    ></div>
                                                    <span style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#2e2f30' }}>{t("correct")}</span>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div
                                                        style={{
                                                            borderRadius: '50%',
                                                            width: '15px',
                                                            height: '15px',
                                                            backgroundColor: '#ff6961',
                                                            marginRight: '10px',
                                                            fontFamily: 'Poppins',
                                                            fontSize: '18px',
                                                            color: '#2e2f30'
                                                        }}
                                                    ></div>
                                                    <span style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#2e2f30' }}>{t("incorrect")}</span>
                                                </div>
                                            </div>
                                            <button
                                                style={{
                                                    // position: 'absolute',
                                                    // marginTop: '8%',
                                                    // bottom: '119px',
                                                    // right: '150px',
                                                    // padding: '3% 15%',
                                                    // bottom: '20px',
                                                    // right: '15%',
                                                    padding: '5px 25px',
                                                    backgroundColor: '#002147',
                                                    color: '#FFF',
                                                    border: '2px ',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    fontSize: '16px',
                                                    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '18px',
                                                    marginTop: '5rem'
                                                }}
                                                onClick={showAnalytics}
                                            >
                                                {t("seeDetails")}
                                            </button>
                                        </>
                                    )
                                    : null
                            }
                        </Grid>
                    </>
                }
            </Grid>
            {evaluationModel}
        </div>
    );
};

export default PieChartComponent;
