import { useTheme } from "@emotion/react";
import { Alert, Grid, Snackbar } from "@mui/material";
import {
  Button,
  Card,
  Form,
  Input,
  Select
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoginContext } from "../contexts/LoginContext";
import { getEducationBoard, getProfession } from "../services/dropdownService";
import {
  onParentInfo,
  onSiblingInfo
} from "../services/parentService";
import styles from "../styles/signin.module.css";

const ParentDetails = () => {
  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 20,
    },
  };
  const formItemLayout1 = {
    labelCol: {
      span: 15,
    },
    wrapperCol: {
      span: 15,
    },
  };
  const { t } = useTranslation();
  const theme = useTheme();
  const dateFormat = "DD/MM/YYYY";
  const { setProviderId, providerId, user, UserId, userDetail, siblingDetail, setUserDetail, setSiblingDetail } = useContext(LoginContext);
  const [EduboardData, setEduboardData] = useState([]);
  const [ProfessiondData, setProfessionData] = useState([]);
  const [parentObj, setParentObj] = useState(
    // JSON.parse(localStorage.getItem("ParentInfo"))
    userDetail
  );
  const [siblingObj, setSiblingObj] = useState(
    // JSON.parse(localStorage.getItem("SiblingInfo"))
    siblingDetail

  );
  // const [parentResp, setParentResp] = useState(
  //   JSON.parse(localStorage.getItem("PersonalInfo"))
  // );
  // const [siblingResp, setSiblingResp] = useState(
  //   JSON.parse(localStorage.getItem("SiblingInfo"))
  // );
  const [open, setOpen] = React.useState(false);
  let defalutDta = {
    userId: UserId,
    siblingId: siblingObj ? siblingObj.siblingId : 0,
    providerId: providerId,
    createdBy: user ?? "system",
    updatedBy: user ?? "system",
    createdDate: new Date(),
    updatedDate: new Date(),
    userTypeId: 4,
    isActive: true,
    educationId: 0,
  };
  const getServices = async () => {
    let isActive = true;
    const eduboard = await getEducationBoard("EduBoard", isActive);
    console.log("eduboard", eduboard);
    const profession = await getProfession("Profession", isActive);
    console.log("profession", profession);
    // const getParent = await getstudentData(UserId);
    // const getSibling = await getSiblingData(UserId);
    // console.log(getSibling, "sibling Details");
    // console.log(getParent, "parent Details");
    if (!eduboard && !profession) {
      // & !getParent.fatherName ===null && !getSibling.$values === []
      setProfessionData([]);
      setEduboardData([]);
      // setParentObj([]);
      // setSiblingObj([]);
    } else {
      setEduboardData(eduboard.$values);
      setProfessionData(profession.$values);
      // setParentObj(getParent.fatherName !== undefined && getParent.fatherName !== null ? getParent:null);
      // localStorage.setItem("ParentInfo", JSON.stringify(getParent.fatherName !== undefined && getParent.fatherName !== null ? getParent:null));

      // setSiblingObj(
      //   getSibling.$values[0] !== undefined ? getSibling.$values[0] : null
      // );
      // localStorage.setItem(
      //   "SiblingInfo",
      //   JSON.stringify(
      //     getSibling.$values[0] !== undefined ? getSibling.$values[0] : null
      //   )
      // );
    }
  };
  useEffect(() => {
    setProviderId(providerId);
    // BSS121
    axios.defaults.headers.common = {
      providerId: providerId,
    };

  }, []);
  useEffect(() => {
    getServices();
  }, []);

  const mobileValidation = (rule, value, callback) => {
    if (value && (value.length !== 10 || isNaN(value))) {
      callback("Mobile should 10 digit number");
    } else {
      callback();
    }
  }
  const onSave = async (values) => {
    console.log(values, "onSaveValuesssss")
    setOpen(true);
    const g = [values.fatherName, values.fatherProfession, values.fatherEmail, values.fatherMobile];
    let parent = {};
    parent.fatherName = values.fatherName
    parent.fatherProfession = values.fatherProfession
    parent.fatherMobile = values.fatherMobile
    parent.fatherEmail = values.fatherEmail

    if (parent.fatherProfession == "") {
      parent.fatherProfession = 0;
    }
    let sibling = {};
    sibling.siblingName = values.siblingName
    sibling.dob = values.dob
    parent.education = values.education
    let parentPayload = Object.assign(parentObj, parent);
    const updateParent = await onParentInfo(parentPayload);
    console.log(updateParent, 'updateParentResponse')
    if (updateParent?.response?.status == 200) {
      setUserDetail(updateParent);
    }
    // localStorage.setItem("ParentInfo", JSON.stringify(updateParent));
    let siblingPayload = Object.assign(defalutDta, sibling);
    const updateSibling = await onSiblingInfo(siblingPayload);
    console.log(updateSibling, 'updateParentResponse')
    if (updateParent?.response?.status == 200) {
      setSiblingDetail(updateSibling.$values[0]);
    }
    // localStorage.setItem(
    //   "SiblingInfo",
    //   JSON.stringify(updateSibling.$values[0])
    // );
    console.log(values);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const LabelColors = {
    color: '#001124'
  }
  return (
    <div className="container">
      <Card style={{ backgroundColor: 'transparent' }}>
        <Form
          name="normal_signup"
          className="signUpForm"
          onFinish={(values) => onSave(values)}
          //  BSS121
          initialValues={{
            fatherName: parentObj ? parentObj.fatherName !== null ? `${parentObj.fatherName}` : "" : "",
            fatherProfession: parentObj ? parentObj.fatherProfession !== 0 ? `${parentObj.fatherProfession}` : "" : "",
            fatherEmail: parentObj ? parentObj.fatherEmail !== null ? `${parentObj.fatherEmail}` : "" : "",
            fatherMobile: parentObj ? parentObj.fatherMobile !== null ? `${parentObj.fatherMobile}` : "" : "",
            siblingName: siblingObj ? `${siblingObj.siblingName}` : "",
            dob: siblingObj ? moment(`${siblingObj.dob}`) : "",
            education: siblingObj ? `${siblingObj.education}` : "",
          }}
        >
          <Grid container>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={open}
              onClose={handleClose}
              autoHideDuration={1000}
            >
              <Alert
                severity="success"
                onClose={handleClose}
                sx={{ fontSize: "15px", width: "100%" }}
              >
                {t("userUpdated")}
              </Alert>
            </Snackbar>
            {/* <div className="row"> */}
            <Grid item xs={12} sm={12} md={12}>
              <h3
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: '600',
                  color: '#002147',    // theme.palette.heading.main,   //"#EC9706",
                  textAlign: "left",
                  marginBottom: "25px",
                  paddingLeft: '0'
                }}
              >
                {t("parentdetails")}
              </h3>
            </Grid>
            {/* </div> */}
            <Grid item xs={12} sm={12} md={12} sx={{ background: '#fff', boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)', borderRadius: '5px', padding: '32px 45px 32px 43px' }}>
              <div className="row">
                <div className="col-sm">
                  <label className={styles.label} style={LabelColors} for="fatherName">{t("parentName")}</label>
                  <Form.Item
                    {...formItemLayout}
                    name="fatherName"
                    rules={[
                      {
                        required: true,
                        message: t("reqFathername")
                      },
                    ]}
                  >
                    <Input placeholder={t("parentName")} style={{ fontFamily: 'Poppins', }} />
                  </Form.Item>
                </div>
                <div className="col-sm">
                  <label className={styles.label} style={LabelColors} for="fatherProfession">{t("profession")}</label>

                  <Form.Item
                    {...formItemLayout}
                    name="fatherProfession"
                    rules={[
                      {
                        required: false,
                        message: t("reqParentProfession")
                      },
                    ]}
                  >
                    <Select
                      style={{ textAlign: "left", fontFamily: 'Poppins' }}
                      dropdownStyle={{ fontFamily: 'Poppins' }}
                      placeholder={t("profession")}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {ProfessiondData && ProfessiondData.map((st, index) => {
                        console.log(ProfessiondData);
                        return (
                          <option
                            value={`${ProfessiondData[index].valueInt}`}
                          >{`${ProfessiondData[index].valueString}`}</option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm">
                  <label className={styles.label} style={LabelColors} for="fatherEmail">{t("email")}</label>

                  <Form.Item
                    {...formItemLayout}
                    name="fatherEmail"
                    rules={[
                      {
                        type: "email",
                        message: t("reqEmailValid"),
                      },
                      {
                        required: true,
                        message: t("@reqEmail"),
                      },
                    ]}
                  >
                    <Input placeholder={t("email")} style={{ fontFamily: 'Poppins', }} />
                  </Form.Item>
                </div>
                <div className="col-sm">
                  <label className={styles.label} style={LabelColors} for="fatherMobile">{t("mobile")}</label>

                  <Form.Item
                    {...formItemLayout}
                    name="fatherMobile"
                    rules={[
                      {
                        required: true,
                        message: t("@reqMobile")
                      },
                      {
                        validator: mobileValidation
                      }
                    ]}
                  >
                    <Input placeholder={t("mobile")} style={{ fontFamily: 'Poppins', }} />
                  </Form.Item>
                </div>
              </div>
            </Grid>

            {/* <h4
            style={{
              fontFamily:'Roboto, Helvetica, Arial, sans-serif',
              color: "#EC9706",
              textAlign: "left",
              marginBottom: "25px",
            }}
          >
            {t("siblingdetails")}
          </h4> */}

            {/* <div className="row">
            <div className="col-sm">
            <label className={styles.label} for="siblingName">Name</label>

              <Form.Item
                {...formItemLayout1}
                name="siblingName"
                rules={[
                  {
                    // required: true,
                    // message:t("siglingName")
                  },
                ]}
              >
                <Input placeholder="Name" />
                {

                //  <TextField id="outlined-basic" label="Name" variant="outlined" /> 
                }
              </Form.Item>
            </div>
            <div className="col-sm">
            <label className={styles.label} for="dob">Date of Birth</label>

              <Form.Item
                {...formItemLayout1}
                name="dob"
              >
                <DatePicker
                  style={{ width: "-webkit-fill-available" }}
                  placeholder="Date of Birth"
                  format={dateFormat}

                />
              </Form.Item>
            </div>

            <div className="col-sm">
            <label className={styles.label} for="education">Sibling Education</label>

              <Form.Item
                {...formItemLayout1}
                name="education"
                rules={[
                  {
                    // required: true,
                    // message:t("@reqBoard")
                  },
                ]}
              >
                <Select
                  style={{ textAlign: "left" }}
                  placeholder="Sibling Education"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {EduboardData.map((st, index) => {
                    return (
                      <option
                        value={`${EduboardData[index].valueInt}`}
                      >{`${EduboardData[index].valueString}`}</option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div> */}
            {/* <div className="row"> */}

            <Grid item xs={12} sm={12} md={9}>
            </Grid>
            <Grid item xs={12} sm={12} md={3} sx={{ marginTop: '32px' }}>
              {/* <Form.Item style={{ paddingRight: '0', marginTop: '32px' }}> */}
              <Button
                fullWidth
                style={{
                  width: '100%',
                  height: '42px',
                  fontWeight: 'bold',
                  fontFamily: 'Poppins',
                  backgroundColor: '#002147', //theme.palette.primaryButtonBackground.main,  // "#EC9706",
                  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)',
                  borderRadius: '5px',
                  // padding: '8px 51px 16px',
                  color: "#ffff",
                  marginBottom: "22px",
                  float: 'right'
                }}
                htmlType="submit"
              >
                {t("saveBtn")}
              </Button>
              {/* </Form.Item> */}
            </Grid>
          </Grid>
          {/* </div> */}
        </Form>
      </Card>
    </div >
  );
};

export default ParentDetails;
