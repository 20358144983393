import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../Card/Card';
import Charts from '../Chart/Charts';
import GridContainer from '../Components/Grid/GridContainer';
import GridItem from '../Components/Grid/GridItem';
import AnalyticsTable from '../Tables/AnalyticsTable';
import ChartTable from '../Tables/ChartTable';
import { LoginContext } from '../contexts/LoginContext';
import styles from '../styles/DetailingTable.module.css';
import UseGetAnalytics2D from './analyticsHooks/UseGetAnalytics2D';
import UseGetGraph from './analyticsHooks/UseGetGraphAnalytics';

export default function Analytics({ lOid, studentId }) {

  const [contentId, setContentId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [subTypeId, setSubTypeId] = useState(1);
  const [graphAnalyticsData, setGraphAnalyticsData] = useState();
  const { packageId } = useContext(LoginContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const getGraphData = async () => {
    setIsLoading(true);
    const testany = await UseGetGraph(studentId ? studentId : null, lOid)
    setGraphAnalyticsData(testany);
    setIsLoading(false);
  }
  useEffect(() => {
    getGraphData();
  }, [])
  //, isLoading: isLoadingAnalytics
  const { data, isLoading: isLoadingAnalytics } = UseGetAnalytics2D(contentId, subTypeId, studentId, lOid);

  const handleContentChange = (event) => {
    setContentId(event.target.value);
  };
  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const handleExecutionChange = (event) => {
    setSubTypeId(event.target.value);
  };
  console.log(graphAnalyticsData, 'findingOjbs')
  console.log(packageId, 'packageId1')

  // BSS121 will need to take a change like whrough 400 Bad Request
  let analyticsResultErr = "No Record Found"
  return (
    <GridContainer className={styles.container}>

      {
        graphAnalyticsData && graphAnalyticsData?.analyticsResult != analyticsResultErr && isLoading == false ?
          <>
            <GridItem xs={12} sm={12} md={6} lg={3}>
              <div className={styles.Rectangle72}>
                <h4 className={styles.tablefont} style={{
                  color: '#594e4e',  // theme.palette.heading.main,
                  fontFamily: 'Poppins',
                  fontSize: '20px !important'

                }}>{t("objective")} : <br />
                  <span style={{
                    color: '#594e4e',
                    fontFamily: 'Poppins',
                    fontSize: '20px',
                    fontWeight: 'bold'
                  }}>{graphAnalyticsData && graphAnalyticsData?.objective?.trim().length == 0 ? 'none' : graphAnalyticsData?.objective}</span>
                </h4>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={9} className={styles.resultItem}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className={styles.rectangle73}>
                    <h4 className={styles.tablefont} style={{
                      color: '#594e4e',  // theme.palette.heading.main,
                      fontFamily: 'Poppins',
                      fontSize: '20px !important'
                    }}>{t("targetAudience")} : <br />
                      <span style={{
                        color: '#594e4e',
                        fontFamily: 'Poppins',
                        fontSize: '20px',
                        fontWeight: 'bold'

                      }}>{graphAnalyticsData && graphAnalyticsData?.targetAudience?.trim().length == 0 ? 'none' : graphAnalyticsData?.targetAudience}</span>
                    </h4>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'end' }}>
                  <div className={styles.resultHalfCircle}>
                    {/* {actualScore}/{totalScore} */}
                    {graphAnalyticsData && graphAnalyticsData?.totalScore == 0 ? 'none' : `${graphAnalyticsData?.actualScore} / ${graphAnalyticsData?.totalScore}`}
                    <br />
                    <span className={styles.resultInCircle}> {t("Result")}</span>
                  </div>
                </Grid>
              </Grid>
              {/* </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={3}> */}
              {/* <div className={styles.Rectangle72}>
                <h4 className={styles.tablefont} style={{ color: theme.palette.heading.main }}>{t("testResult")} <br /> <span style={{ color: 'gray' }}>{graphAnalyticsData && graphAnalyticsData?.totalScore == 0 ? 'none' : `${graphAnalyticsData?.actualScore} / ${graphAnalyticsData?.totalScore}`}</span></h4>
              </div> */}
            </GridItem>
            {/* chart Table */}
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <Card style={{ cursor: "pointer" }}>
                <ChartTable
                  studentId={studentId}
                  lOid={lOid}
                  analyticsData={graphAnalyticsData && graphAnalyticsData?.analyticsResult ? isJSON(graphAnalyticsData?.analyticsResult) ? JSON.parse(graphAnalyticsData?.analyticsResult) : '' : ''}
                />
              </Card>
            </GridItem>
            {/* Chart  */}
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <Card style={{ cursor: "pointer" }}>
                <Charts
                  studentId={studentId}
                  lOid={lOid}
                  analyticsData={graphAnalyticsData && graphAnalyticsData?.analyticsResult ? isJSON(graphAnalyticsData?.analyticsResult) ? JSON.parse(graphAnalyticsData?.analyticsResult) : '' : ''}
                />
              </Card>

            </GridItem>
            {/* analytics Table */}
            <GridItem styles={{ marginBottom: '5px' }}>
              <h5><u>{t('note')} </u>{t('analyticsNote')}</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card style={{ cursor: "pointer", padding: isMobile ? '' : '3% 10%' }}>
                <Grid container justifyContent='space-between' >
                  {/* <Box sx={{ minWidth: 100 }}> */}
                  <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginBottom: '5%' }}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel className={styles.tablefont} style={{ color: '#001124', fontFamily: 'Poppins' }} id="demo-simple-select">{t("content")}</InputLabel>
                      <Select
                        className={styles.tablefont}
                        labelId="demo-simple-select"
                        id="demo-simple-select"
                        value={contentId}
                        label="Content"
                        onChange={handleContentChange}
                      >
                        <MenuItem style={{ color: '#001124', fontFamily: 'Poppins' }} value={1}>{t("categorySubject")}</MenuItem>
                        <MenuItem style={{ color: '#001124', fontFamily: 'Poppins' }} value={2}>{t("dificultyLevel")}</MenuItem>
                        <MenuItem style={{ color: '#001124', fontFamily: 'Poppins' }} value={3}>{t("questionType")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginBottom: '5%' }}>
                    <FormControl style={{ float: 'right', width: "100%" }}>
                      <InputLabel className={styles.tablefont} style={{ color: '#001124', fontFamily: 'Poppins' }} id="demo-simple-select-label">{t("execution")}</InputLabel>
                      <Select
                        className={styles.tablefont}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subTypeId}
                        label="Execution"
                        onChange={handleExecutionChange}
                      >
                        {
                          packageId == 1 || packageId == 2 || packageId == 3 ?
                            <MenuItem style={{ color: '#001124', fontFamily: 'Poppins' }} value={1}>{t("score")}</MenuItem> : null

                        }
                        {
                          packageId == 2 || packageId == 3 ?
                            <MenuItem style={{ color: '#001124', fontFamily: 'Poppins' }} value={2}>{t("questionAttemptRate")}</MenuItem> : null
                        }
                        {packageId == 2 || packageId == 3 ?
                          <MenuItem style={{ color: '#001124', fontFamily: 'Poppins' }} value={3}>{t("accuracyRate")}</MenuItem> : null
                        }
                        {
                          packageId == 1 || packageId == 2 || packageId == 3 ?
                            <MenuItem style={{ color: '#001124', fontFamily: 'Poppins' }} value={4}>{t("duration")}</MenuItem> : null
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* </Box> */}
                </Grid>
                {data && data?.analyticsResult != analyticsResultErr && isLoadingAnalytics == false ?
                  <AnalyticsTable
                    analyticsData={data && data?.analyticsResult != analyticsResultErr ? JSON.parse(data?.analyticsResult) : ''}
                  />
                  : <CircularProgress
                    style={{ size: "1rem", color: theme.palette.heading.main, marginLeft: '400px', marginTop: '10px' }}
                  />
                }
              </Card>
            </GridItem>
          </>
          :
          graphAnalyticsData?.analyticsResult == "No Record Found" ?

            <Card style={{ cursor: "pointer" }}>
              <h5 style={{ textAlign: 'center', fontSize: 'large' }}>{t('pleaseWaitAnalytics')}</h5>
            </Card>
            : isLoading == true ?
              <CircularProgress
                style={{ size: "1rem", color: theme.palette.heading.main, position: 'relative', marginLeft: '600px', marginTop: '300px' }}
              />
              :
              <Card style={{ cursor: "pointer" }}>
                <h5 style={{ textAlign: 'center', fontSize: 'large' }}>{t('pleaseWaitAnalytics')}</h5>
              </Card>
      }
    </GridContainer>
  )
}




