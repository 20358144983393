import { useTheme } from "@emotion/react";
import { Alert, Grid, Snackbar } from "@mui/material";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select
} from "antd";
import axios from "axios";
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoginContext } from "../contexts/LoginContext";
import { getCity, getClasses, getEducationBoard, getLanguage, getState } from "../services/dropdownService";
import { onParentInfo } from "../services/parentService";
import { onEducationInfo } from "../services/personalDServices";
import styles from "../styles/signin.module.css";
const PersonalDetails = () => {

  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 25,
    },
  };
  const formItemLayout1 = {
    labelCol: {
      span: 15,
    },
    wrapperCol: {
      span: 20,
    },
  };
  const { setProviderId, providerId, user, UserId, userDetail, setUserDetail, educationDetails } = useContext(LoginContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const dateFormat = "DD/MM/YYYY";
  const [StatesData, setStatesData] = useState([]);
  const [CityData, setCityData] = useState([]);
  const [LanguageData, setLanguageData] = useState([]);
  const [EduboardData, setEduboardData] = useState([]);
  const [ClassesdData, setClassesdData] = useState([]);
  const [studentResp, setStudentResp] = useState(userDetail)
  const [educationResp, setEducationResp] = useState(educationDetails)
  const [eduResp, setEduResp] = useState([])
  const [open, setOpen] = React.useState(false);

  // const { data: getStudentDetail } = useGetStudentById(UserId)
  // const getData=async()=>{

  //   const getEduDta = await geteducationData(UserId);  
  //   setEducationResp(getEduDta); 
  // }
  // useEffect(() => {
  //   getData();
  // }, [])

  console.log("education details", educationResp)
  const getServices = async () => {
    let isActive = true;
    const states = await getState("State", isActive);
    const city = await getCity("City", isActive);
    const language = await getLanguage("Language", isActive);
    const eduboard = await getEducationBoard("EduBoard", isActive);
    const classes = await getClasses("Class", isActive);
    // const getStudData = await getstudentData(UserId);   

    // console.log("update student data resp",getStudData);
    // console.log("update education data resp",getEduDta);

    if (!states && !city && !language && !eduboard && !classes) {
      setStatesData([]);
      setCityData([])
      setLanguageData([])
      setEduboardData([])
      setClassesdData([])

    } else {
      setStatesData(states.$values);
      setCityData(city.$values)
      setLanguageData(language.$values)
      setEduboardData(eduboard.$values)
      setClassesdData(classes.$values)

    }
  };
  const handleDisabledDate = current => {
    // Disable future dates
    const today = new Date();
    return current && current > today;
  };
  useEffect(() => {
    setProviderId(providerId);
    // BSS121
    axios.defaults.headers.common = {
      providerId: providerId,
    };
  }, []);

  // const getData=async()=>{
  //   const getStudentDta = await getstudentData(UserId);
  //   const getEduDta = await geteducationData(UserId);   
  //   setStudentResp(getStudentDta)
  //   setEducationResp(getEduDta)
  //   console.log("update student api resp",typeof(getStudentDta))
  // }

  // useEffect(() => {
  //   getData();
  // }, [])

  useEffect(() => {
    getServices();
  }, [])


  const mobileValidation = (rule, value, callback) => {
    if (value && (value.length !== 10 || isNaN(value))) {
      callback("Mobile should 10 digit number");
    } else {
      callback();
    }
  }
  const onSave = async (values) => {
    let pylod = Object.assign(studentResp, values);
    pylod.state = pylod?.state == "" ? 0 : pylod?.state;
    pylod.city = pylod?.city == "" ? 0 : pylod?.city;
    const savePersonal = await onParentInfo(pylod)
    console.log(savePersonal, 'savePersonalResponse')
    if (savePersonal?.response?.status == 200) {
      setUserDetail(savePersonal)
    }
    // localStorage.setItem("PersonalInfo",JSON.stringify(savePersonal))
    let eduPylod = Object.assign(educationResp, values);

    eduPylod.board = eduPylod?.board == "" ? 0 : eduPylod?.board;
    eduPylod.class = eduPylod.class == "" ? 0 : eduPylod.class;
    eduPylod.language = eduPylod.language == "" ? 0 : eduPylod.language;
    const saveEducation = await onEducationInfo(eduPylod)
    console.log(saveEducation, 'saveEducationResponse')
    if (saveEducation?.response?.status == 200) {
      setEducationResp(saveEducation);
    }
    // localStorage.setItem("EducationInfo",JSON.stringify(saveEducation))
    console.log(values);
    setOpen(true)
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  console.log(educationResp, "edcationResponce")
  console.log(studentResp, "studentResponce")
  console.log("userDteail", userDetail)

  const GenderObj = [
    {
      "$id": "1",
      "id": 1,
      "key": "Male",
      "sequenceId": 1,
      "valueInt": 1,
      "valueString": "Male",
      "isDefault": true,
      "isActive": true,
    },
    {
      "$id": "2",
      "id": 2,
      "key": "Female",
      "sequenceId": 2,
      "valueInt": 2,
      "valueString": "Female",
      "isDefault": true,
      "isActive": true,
    },
    {
      "$id": "3",
      "id": 3,
      "key": "Others",
      "sequenceId": 3,
      "valueInt": 3,
      "valueString": "Others",
      "isDefault": true,
      "isActive": true,
    },
  ]
  const LabelColors = {
    color: '#001124'
  }
  return (
    <div className="container">
      <Card style={{ backgroundColor: 'transparent' }}>
        <Form
          name="normal_signup"
          className="signUpForm"
          onFinish={(values) => onSave(values)}
          initialValues={{
            fullName: studentResp ? `${studentResp.fullName}` : '', dob: studentResp && studentResp.dob !== null ? dayjs(`${studentResp.dob}`) : null, gender: studentResp ? `${studentResp.gender == 0 || studentResp.gender == null ? "" : studentResp.gender}` : null, email: studentResp ? `${studentResp.email}` : '', mobile: studentResp ? `${studentResp.mobile}` : '', address: studentResp ? `${studentResp.address}` : '', state: studentResp ? `${studentResp.state == 0 || studentResp.state == null ? "" : studentResp.state}` : '', city: studentResp ? `${studentResp.city == 0 || studentResp.city == null ? "" : studentResp.city}` : '', pinCode: studentResp ? studentResp.pinCode != null ? studentResp.pinCode : '' : '', language: educationResp ? `${educationResp.language == 0 || educationResp.language == null ? "" : educationResp.language}` : '', board: educationResp ? `${educationResp.board == 0 || educationResp.board == null ? "" : educationResp.board}` : '', class: educationResp ? `${educationResp.class == 0 || educationResp.class == null ? "" : educationResp.class}` : ''
          }}
        >
          <Grid container>

            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={open} onClose={handleClose} autoHideDuration={1000}>
              <Alert severity="success"
                onClose={handleClose}
                sx={{ fontSize: "15px", width: "100%" }}
              >
                {t("userUpdated")}
              </Alert>
            </Snackbar>
            <h3
              style={{
                fontFamily: 'Poppins',
                color: '#012248',  // "#EC9706",
                fontWeight: '600',
                textAlign: "left",
                marginBottom: "15px",
              }}
            >
              {t("personalDetails")}
            </h3>
            <Grid item xs={12} sm={12} md={12} sx={{ background: '#fff', boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)', borderRadius: '5px', padding: '32px 45px 32px 43px' }}>
              <div className="row">
                <div className="col-sm-3">
                  <label className={styles.label} style={LabelColors} for="fullName">{t("fullName")}</label>
                  <Form.Item
                    {...formItemLayout}
                    name="fullName"

                    rules={[
                      {
                        required: true,
                        message: t("@reqFullname")
                      },
                    ]}
                  >
                    <Input placeholder={t("fullName")} style={{ fontFamily: 'Poppins', }} />
                  </Form.Item>
                </div>
                <div className="col-sm-3">
                  <label className={styles.label} style={LabelColors} for="dob">{t("dob")}</label>
                  <Form.Item
                    {...formItemLayout}
                    name="dob"
                    rules={[
                      {
                        required: true,
                        message: t("@reqDOB"),
                      },
                    ]}
                  >
                    <DatePicker
                      popupClassName={styles.customdatepickerpopup}
                      style={{ width: '-webkit-fill-available', fontFamily: 'Poppins' }}
                      placeholder={t("dob")}
                      format={dateFormat}
                      disabledDate={handleDisabledDate}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-3">
                  <label className={styles.label} style={LabelColors} for="gender">
                    {t("gender")}
                    {/* <span style={{ color: "red" }}><b>*</b></span> */}
                  </label>

                  <Form.Item
                    id="Gender"
                    {...formItemLayout}
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("gender")}
                      optionFilterProp="children"
                      style={{ textAlign: "left", fontFamily: 'Poppins' }}
                      dropdownStyle={{ fontFamily: 'Poppins' }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {GenderObj.map((st, index) => {
                        return (
                          <option
                            value={`${GenderObj[index].valueInt}`}
                          >{`${GenderObj[index].valueString}`}</option>
                        );
                      })}
                    </Select>
                  </Form.Item >
                </div >
                <div className="col-sm-3">
                  <label className={styles.label} style={LabelColors} for="email">{t("email")}</label>
                  <Form.Item
                    {...formItemLayout}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: t("reqEmailValid"),
                      },
                      {
                        required: true,
                        message: t("@reqEmail"),
                      },
                    ]}
                  >
                    <Input placeholder={t("email")} style={{ fontFamily: 'Poppins', }} />
                  </Form.Item>
                </div>

              </div>
              <div className="row">
                <div className="col-sm-3">
                  <label className={styles.label} style={LabelColors} for="mobile">{t("mobile")}</label>
                  <Form.Item
                    {...formItemLayout}
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: t("@reqMobile")
                      },
                      {
                        validator: mobileValidation
                      }
                    ]}
                  >
                    <Input placeholder={t("mobile")} style={{ fontFamily: 'Poppins', }} />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <label className={styles.label} style={LabelColors} for="address">{t("address")}</label>
                  <Form.Item
                    {...formItemLayout}
                    name="address"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: t("@reqAddress")
                  //   },
                  // ]}
                  >
                    <Input placeholder={t("address")} style={{ fontFamily: 'Poppins', }} />
                  </Form.Item>
                </div>
                <div className="col-sm-3">
                  <label className={styles.label} style={LabelColors} for="state">{t("state")}</label>
                  <Form.Item
                    {...formItemLayout}
                    name="state"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: t("@reqState")
                  //   },
                  // ]}
                  >
                    <Select
                      placeholder={t("state")}
                      optionFilterProp="children"
                      style={{ textAlign: "left", fontFamily: 'Poppins' }}
                      dropdownStyle={{ fontFamily: 'Poppins' }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {StatesData && StatesData.map((st, index) => {
                        return (
                          <option
                            value={`${StatesData[index].valueInt}`}
                          >{`${StatesData[index].valueString}`}</option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <label className={styles.label} style={LabelColors} for="city">{t("city")}</label>
                  <Form.Item
                    {...formItemLayout}
                    name="city"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: t("@reqCity")
                  //   },
                  // ]}
                  >
                    <Select
                      placeholder={t("city")}
                      optionFilterProp="children"
                      style={{ textAlign: "left", fontFamily: 'Poppins' }}
                      dropdownStyle={{ fontFamily: 'Poppins' }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {CityData && CityData.map((st, index) => {
                        return (
                          <option
                            value={`${CityData[index].valueInt}`}
                          >{`${CityData[index].valueString}`}</option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-3">
                  <label className={styles.label} style={LabelColors} for="pinCode">{t("pincode")}</label>

                  <Form.Item
                    {...formItemLayout}
                    name="pinCode"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: t("@reqPincode")
                  //   },
                  // ]}
                  >
                    <Input placeholder={t("pincode")} style={{ fontFamily: 'Poppins', }} />
                  </Form.Item>
                </div>
              </div>
            </Grid>
            <h3
              style={{
                fontFamily: 'Poppins',
                color: '#012248',    // "#EC9706",
                fontWeight: '600',
                textAlign: "left",
                marginBottom: "15px",
              }}
            >
              {t("academicDetails")}
            </h3>

            <Grid item xs={12} sm={12} md={12} sx={{ background: '#fff', boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)', borderRadius: '5px', padding: '32px 45px 32px 43px' }}>

              <div className="row">
                <div className="col-sm">
                  <label className={styles.label} style={LabelColors} for="language">{t("language")}</label>

                  <Form.Item
                    {...formItemLayout1}
                    name="language"
                    rules={[
                      {
                        required: true,
                        message: t("@reqLanguage")
                      },
                    ]}
                  >
                    <Select
                      style={{ textAlign: 'left', fontFamily: 'Poppins' }}
                      dropdownStyle={{ fontFamily: 'Poppins' }}
                      placeholder={t("language")}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {LanguageData && LanguageData.map((st, index) => {
                        return (
                          <option
                            value={`${LanguageData[index].valueInt}`}
                          >{`${LanguageData[index].valueString}`}</option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm">
                  <label className={styles.label} style={LabelColors} for="board">{t("eduBoard")}</label>

                  <Form.Item
                    {...formItemLayout1}
                    name="board"
                    rules={[
                      {
                        required: false,
                        message: t("@reqBoard")
                      },
                    ]}
                  >
                    <Select
                      style={{ textAlign: 'left', fontFamily: 'Poppins' }}
                      dropdownStyle={{ fontFamily: 'Poppins' }}
                      placeholder={t("eduBoard")}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {EduboardData && EduboardData.map((st, index) => {
                        return (
                          <option
                            value={`${EduboardData[index].valueInt}`}
                          >{EduboardData[index].valueInt == 0 || EduboardData[index].valueInt == null ? "" : `${EduboardData[index].valueString}`}</option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm">
                  <label className={styles.label} style={LabelColors} for="class">{t("class")}</label>
                  <Form.Item
                    {...formItemLayout1}
                    name="class"
                    rules={[
                      {
                        required: false,
                        message: t("@reqClass")
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ textAlign: 'left', fontFamily: 'Poppins' }}
                      dropdownStyle={{ fontFamily: 'Poppins' }}
                      placeholder={t("class")}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {ClassesdData && ClassesdData.map((st, index) => {
                        return (
                          <option
                            value={`${ClassesdData[index].valueInt}`}
                          >{`${ClassesdData[index].valueString}`}</option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>

              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
            </Grid>
            <Grid item xs={12} sm={12} md={3} sx={{ marginTop: '32px' }}>
              <Form.Item>
                <Button
                  fullWidth
                  style={{
                    width: '100%',
                    height: '42px',
                    fontWeight: 'bold',
                    fontFamily: 'Poppins',
                    backgroundColor: '#002147', //theme.palette.primaryButtonBackground.main,  // "#EC9706",
                    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)',
                    borderRadius: '5px',
                    // padding: '8px 51px 16px',
                    color: "#ffff",
                    marginBottom: "22px",
                    float: 'right'
                  }}
                  htmlType="submit"
                >
                  {t("saveBtn")}
                </Button>
              </Form.Item>
            </Grid>
          </Grid>
        </Form >
      </Card >
    </div >
  );
};

export default PersonalDetails;
