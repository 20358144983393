import axios from "axios";
import { useQuery } from "react-query";
import { configUrl } from "../config";

export default function useGetAssignedTests(userId, testStatus, filter) {

  return useQuery(["assignedTests", userId], async () => {


    console.log("userId", userId, filter);

    if (!userId) {
      return;
    }

    let response = await axios.get(
      filter == true ?
        `${configUrl.AssessmentServer}/api/Tests/getAssignedTests/${userId}/${testStatus}?filter=true`
        : `${configUrl.AssessmentServer}/api/Tests/getAssignedTests/${userId}/${testStatus}`
    );

    return response.data["$values"];
  },
    {
      staleTime: 1000 * 60 * 5,
    });
}

