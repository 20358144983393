import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dashboardStyle from "../../assets/dashboardStyle";
import loginPageStyle from "../../assets/loginPageStyle";
import { LoginContext } from "../../contexts/LoginContext";
import { TestContext } from "../../contexts/TestContext";
//import GetTestDetails from "../../services/GetTestDetails";
import { useTheme } from "@mui/material";
import newStyles from '../../styles/TestPlayer.module.css';
import Button from "../CustomButtons/Button";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import TimerView from "../Player/TimerView";
import ConcludeScreen from "../Questions/ConcludeScreen";
import FeedbackScreens from "../Questions/FeedbackScreens";
import QuestionWrapperNew from "../Questions/QuestionWrapperNew";
import WelcomeScreenNew from "../Questions/WelcomeScreenNew";
import TOCDwawer from "./TOCDwawer";

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
export default function TestPlayer(props) {
  //TODO
  console.log('hi test player')
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { showToC, UserId } = useContext(LoginContext);

  let testData = props.tests;
  let testAttemptDetailsData = props.testAttemptDetails;
  let showSubmit = props.showSubmit;
  let Finish = props.onFinish;
  let resumeTestId = localStorage.getItem(`${UserId}TestId`)
  let localUserId = localStorage.getItem(`${UserId}UserId`)
  // const getTestDetails = async () => {
  //   const testDetail = await GetTestDetails.getTestDetails(props.userId, props.testId, props.attempt)
  //   testDetail.currentPageIndex = 0;
  //   setTestAttemptDetails(testDetail);
  //   setTest(JSON.parse(testDetail.attemptDetails).value);
  // }
  // useEffect(() => {
  //   getTestDetails();
  // }, [])

  const [testAttemptDetails, setTestAttemptDetails] = useState({ ...testAttemptDetailsData, currentPageIndex: 0 });
  const [test, setTest] = useState(testData);
  const [selectEvent, setSelectEvent] = useState(true)
  const [markForReview, setMarForkReview] = useState(); // will delete it later when this is used in Bookmark component and this is used common in both player
  const [startQuestionTime, setStartQuestionTime] = useState(new Date());
  const [testTFlag, setTestTFlag] = useState(false);
  const [pulledQuestions, setPulledQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  //const [second, setSecond] = useState(test.duration * 60)
  const [isSubmit, setIsSubmit] = useState(false);
  const [timeOutSubmit, setTimeOutSubmit] = useState(false);
  const [remainingQuestions, setRemainingQuestions] = useState(0);
  const [bookmarked, setBookMarked] = useState(0);
  const [tatalQuestionCount, setTotalQuestionCount] = useState(0);
  const [toconcludeScreen, setToConcludeScreen] = useState('');

  // useEffect(() => {
  //   if (testTFlag == true) {
  //     const intervalId = setInterval(() => {
  //       if (test.duration !== null && second === 0) {
  //         setTimeOutSubmit(true);
  //         setInterval()
  //       } else {
  //         setSecond(second - 1)
  //       }
  //     }, 1000);
  //     return () => clearInterval(intervalId);
  //   }
  // }, [testTFlag, second])
  function getDiff() {
    const currentDate = new Date(); // Current date in local timezone
    const currentUtcDate = new Date(currentDate.toUTCString());

    const timezoneOffsetLocal = currentDate.getTimezoneOffset(); // Offset in minutes
    const timezoneOffsetUTC = currentUtcDate.getTimezoneOffset(); // Offset in minutes

    const offsetHoursLocal = Math.abs(timezoneOffsetLocal / 60);
    const offsetMinutesLocal = Math.abs(timezoneOffsetLocal % 60);

    const offsetHoursUTC = Math.abs(timezoneOffsetUTC / 60);
    const offsetMinutesUTC = Math.abs(timezoneOffsetUTC % 60);

    console.log(`UTC offset: ${offsetHoursUTC} hours ${offsetMinutesUTC} minutes`);
    console.log(`Local timezone offset: ${offsetHoursLocal} hours ${offsetMinutesLocal} minutes`);
    return offsetHoursLocal;
  }
  const toLocal = (UTCTime) => {
    // converting into local time start
    const utcDateTime = new Date(UTCTime);
    let diff = getDiff();
    console.log(diff, 'inDiff')
    const istOffset = diff * 60 * 60 * 1000; // IST is UTC+5.5 hours
    const istDateTime = new Date(utcDateTime.getTime() + istOffset);
    return istDateTime
  }
  const updateLocalStore = () => {
    const targetStartDateTime = props.testAttemptDetails?.testStartDate ? toLocal(props.testAttemptDetails?.testStartDate) : null;
    const targetEndDateTime = props.testAttemptDetails?.testEndDate ? toLocal(props.testAttemptDetails?.testEndDate) : null;
    const currentDateTime = new Date();

    if (resumeTestId == null || test.testId != resumeTestId) {
      try {
        // added this for if test is not expired or scheduled later in this case 
        if ((targetStartDateTime <= currentDateTime &&
          targetEndDateTime >= currentDateTime) ||
          (props.testAttemptDetails?.testStartDate == null &&
            props.testAttemptDetails?.testEndDate == null)) {
          console.log(test.testId, 'is here22', test)
          localStorage.setItem(`${UserId}AttemptedTest`, JSON.stringify(test));
          localStorage.setItem(`${UserId}UserId`, props.testAttemptDetails?.userId)
          localStorage.setItem(`${UserId}TestId`, test.testId)
        } else {
          console.log('not stored');
        }

      } catch (e) {
        console.error(e, 'localError')
      }
    }
  }
  const startButtonTime = (time, flag) => {
    // TODO - storing test id for resume perticular test.
    let currentQIndex = JSON.parse(localStorage.getItem(`${UserId}currentQuestionIndex`));
    let resumeTestId = localStorage.getItem(`${UserId}TestId`)
    console.log(testAttemptDetails.testId, resumeTestId, 'resumetest')
    if (currentQIndex != null && testAttemptDetails.testId == resumeTestId)
      setCurrentQuestionIndex(currentQIndex);
    setStartQuestionTime(time);
    setTestTFlag(flag);
    updateLocalStore();
  }

  const onNextClick = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }
  const onPrevClick = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1)
  }

  const getQuestionCount = (questions) => {
    setPulledQuestions(questions);
  }
  const onSubmitClick = () => {
    setIsSubmit(true);
  }
  useEffect(() => {
    let unsolvedQuestions = [];
    let markedForReviewCount = [];
    let totalQuestionCount = [];
    for (let j = 0; j < test.pools["$values"].length; j++) {
      for (let i = 0; i < test.pools["$values"][j].questions["$values"].length; i++) {
        totalQuestionCount.push(test.pools["$values"][j].questions["$values"][i].id);

        if (test.pools["$values"][j].questions["$values"][i].response == null || test.pools["$values"][j].questions["$values"][i].response.length === 0) {
          unsolvedQuestions.push(test.pools["$values"][j].questions["$values"][i].id)
        }
        if (test.pools["$values"][j].questions["$values"][i].markForReview == true) {
          markedForReviewCount.push(test.pools["$values"][j].questions["$values"][i].id)
        }
      }
    }
    setRemainingQuestions(unsolvedQuestions.length);
    setBookMarked(markedForReviewCount.length);
    setTotalQuestionCount(totalQuestionCount.length);
  }, [selectEvent])
  console.log(selectEvent, 'event123')
  const onHomePage = (a) => {
    setToConcludeScreen(a);
    console.log(a);
  }
  return (
    <div className={classes.wrapper}>
      <div
        style={{
          // modal Background BSS121
          background: "#ffffff ",
          //padding: "120px 0",
          position: "relative",
          minHeight: "90vh",
          display: "flex!important",
          margin: "0",
          border: "0",
          color: "white",
          alignItems: "center",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "100%",
        }}
      >
        {test && (
          <TestContext.Provider
            value={{ setTestTFlag, currentQuestionIndex, setCurrentQuestionIndex, markForReview, setMarForkReview, test, testAttemptDetails, setTestAttemptDetails, selectEvent, setSelectEvent }}
          >
            <GridContainer justifyContent="center" style={{ width: "calc(100% + 15px)" }}>
              <GridItem xs={12} sm={12} md={12}>
                {/* Head of Player */}
                <div style={{ position: 'absolute', left: '41%', top: '5%' }}>
                </div>

                {/* Body of player */}
                <GridContainer style={{ float: 'right' }}>
                  <GridItem xs={12} sm={12} md={9} lg={12} className={newStyles.showOnMobile} style={{ maxHeight: '499px', overflowY: 'auto', overflowX: 'auto' }}>
                    <div className={newStyles.TOCHover}>
                      {
                        test.navigation == 2 && showToC ?
                          <TOCDwawer tatalQuestionCount={tatalQuestionCount} remainingQuestions={remainingQuestions} bookmarked={bookmarked} QuestionPerPage={test.questionsPerPage} />
                          : null
                      }
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={test.navigation == 2 && showToC ? 9 : 12} lg={test.navigation == 2 && showToC ? 9 : 12} className={newStyles.questionBody}>
                    {
                      testAttemptDetails.currentPageIndex == 0 && (
                        <WelcomeScreenNew
                          message={test.welcomeMessage}
                          startButtonTime={startButtonTime}
                        />
                      )
                    }
                    {testAttemptDetails.currentPageIndex == 1 && (
                      <QuestionWrapperNew
                        attempt={props.attempt}
                        preview={props.preview}
                        isCourse={props.isCourse}
                        courseId={props.courseId}
                        submitFlag={isSubmit}
                        setSubmitFlag={setIsSubmit}
                        timeOutFlag={timeOutSubmit}
                        currentQuestionIndex={currentQuestionIndex}
                        getQuestionCount={getQuestionCount}
                        testStartedTime={startQuestionTime}
                        testStartFlag={testTFlag}
                        remainingQuestions={remainingQuestions}
                        questionObj={props.questionObj}
                        testId={props.testId}
                      />
                    )}
                    {testAttemptDetails.currentPageIndex == 2 && (
                      test.showResult == 2 ?
                        <FeedbackScreens
                          message={test.feedbackFail}
                          testAttemptDetails={testAttemptDetails}
                          test={test}
                          score={testAttemptDetails && testAttemptDetails.actualScore}
                          totalScore={testAttemptDetails.totalScore}
                          onHomePage={onHomePage}
                        /> : testAttemptDetails.currentPageIndex = 3
                    )}
                    {testAttemptDetails.currentPageIndex == 3 && (
                      <ConcludeScreen message={test.concludeMessage} toconcludeScreen={toconcludeScreen} onFinish={Finish} />
                    )}
                  </GridItem>
                  {console.log(test.navigation, 'navigationkk')}
                  {test.navigation == 2 && showToC ?
                    <GridItem xs={12} sm={12} md={3} lg={3} className={newStyles.showOnWeb} >
                      <TOCDwawer tatalQuestionCount={tatalQuestionCount} remainingQuestions={remainingQuestions} bookmarked={bookmarked} QuestionPerPage={test.questionsPerPage} />
                    </GridItem>
                    : null
                  }
                </GridContainer>

                {/* footer of player */}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div p={1} className={newStyles.footer4Actions} justifyContent={"center"}>
                      <div style={{ margin: '3px 0px 0px 0' }}>
                        {
                          <TimerView setTimeout={() => { setTimeOutSubmit(true) }} TestId={testAttemptDetails.testId} showTimer={test.showTimer} duration={test.duration} testTFlag={testTFlag} />
                        }

                        {!testAttemptDetails.isSubmited == true && test && testAttemptDetails.currentPageIndex == 1 && test.navigation ? (
                          <Button
                            // justIcon
                            round
                            // color="primary"
                            onClick={onPrevClick}
                            style={{
                              padding: '7px 7px',
                              color: '#453535',
                              backgroundColor: '#faf3f3'
                            }}
                            disabled={currentQuestionIndex <= 0 || test.navigation == 1}
                          >
                            <ArrowBackIcon />
                          </Button>
                        ) : null
                        }
                        {
                          !testAttemptDetails.isSubmited == true && test && testAttemptDetails.currentPageIndex == 1 && (
                            <span style={{
                              marginInline: '5px', color: '#453535'
                              , fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '16px'
                            }}>
                              {/* {t('page')} */}
                              {currentQuestionIndex + 1} {t('of')} {pulledQuestions.length}
                            </span>
                          )
                        }
                        {
                          !testAttemptDetails.isSubmited == true && test && pulledQuestions.length > 0 && (
                            <Button
                              // justIcon
                              round
                              color="primary"
                              onClick={onNextClick}
                              style={{
                                padding: '7px 7px',
                                color: '#453535',
                                backgroundColor: '#faf3f3'
                              }}
                              disabled={currentQuestionIndex >= pulledQuestions.length - 1}
                            >
                              <ArrowForwardIcon />
                            </Button>
                          )
                        }
                        <span style={{ float: 'right', marginRight: '1%' }}>
                          {!testAttemptDetails.isSubmited == true && test && pulledQuestions.length > 0 && showSubmit ? <Button style={{
                            padding: '1.2rem 2.6rem',//'6px 12px',
                            // backgroundColor: theme.palette.primaryButtonBackground.main
                            backgroundColor: '#001124', //theme.palette.primaryButtonBackground.main
                            borderRadius: '10px',
                            boxShadow: '0 1px 4px 0 rgba(25, 33, 61, 0.08)',
                            textTransform: 'capitalize',
                            lineHeight: '1.38',
                            fontSize: '16px',
                          }}
                            // round
                            onClick={onSubmitClick}>
                            {t("submit")}
                          </Button> : (!testAttemptDetails.isSubmited == true && test && pulledQuestions.length > 0 && currentQuestionIndex >= pulledQuestions.length - 1 && pulledQuestions.length > 0 ?
                            <Button style={{
                              padding: '1.2rem 2.6rem',    // '6px 12px',
                              backgroundColor: '#001124', //theme.palette.primaryButtonBackground.main
                              borderRadius: '10px',
                              boxShadow: '0 1px 4px 0 rgba(25, 33, 61, 0.08)',
                              textTransform: 'capitalize',
                              fontSize: '16px'
                            }}
                              // round
                              onClick={onSubmitClick}>
                              {t("submit")}
                            </Button> : null)
                          }
                        </span>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </TestContext.Provider>
        )}
      </div>
    </div>
  );
}
