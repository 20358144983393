import { useTheme } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import styles from '../styles/QuestionWrapper.module.css';
const DataTable = ({ data }) => {
    const theme = useTheme();
    console.log(data, 'data1235')
    const evenRowStyle = {
        backgroundColor: '#f2f2f2',
    };

    return (
        <table style={{ background: '#fff', borderRadius: '5px', boxShadow: ' 0 4px 4px 0 rgba(0, 0, 0, 0.05)', fontFamily: 'Poppins' }}>
            <thead className={styles.tableHeadings} style={{ color: '#001124', fontWeight: 'bold', borderBottom: '1px solid #09090980 50%' }}>
                <tr>
                    {/* <th>ID</th> */}
                    <th style={{ padding: '0px 10px 0px 12px' }}>{t("Section")}</th>
                    <th style={{ padding: '0px 10px 0px 12px', textAlign: 'center' }}>{t("question")}</th>
                    <th style={{ padding: '0px 10px 0px 12px', textAlign: 'center' }}>{t("attempted")}</th>
                    <th style={{ padding: '0px 10px 0px 12px', textAlign: 'center' }}>{t("correct")}</th>
                    <th style={{ padding: '0px 10px 0px 12px', textAlign: 'center' }}>{t("incorrect")}</th>
                </tr>
            </thead>
            <tbody>
                {console.log("dataaka", data)}
                {data && data?.length > 0 ?
                    data.map((section, index) => (
                        <tr key={section.id} style={index % 2 === 0 ? evenRowStyle : {}}>
                            <td style={{ padding: '0px 0px 0px 12px' }}>{section?.poolName}</td>
                            <td style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{section?.totalCount}</td>
                            <td style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{section?.attemptedCount}</td>
                            <td style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{section?.correctCount}</td>
                            <td style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{section?.incorrectCount}</td>
                        </tr>
                    ))
                    : <tr>
                        <td colspan="5" style={{ textalign: 'center' }}>
                            <h6 >No Record Found</h6>
                        </td>
                    </tr>

                }
            </tbody>
        </table>
    );
};

export default DataTable;