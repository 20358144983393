// src/components/SignUp.js
import { useTheme } from '@emotion/react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CloseIcon from '@mui/icons-material/Close';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { message } from 'antd';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { configUrl } from '../../config';
import verifyPayment from '../../Hooks/verifyPayment';
import {
  getLanguage,
} from "../../services/dropdownService";
import styles from "../../styles/signin.module.css";
import getStudentDetailByEmail from '../../userHooks/getStudentDetailByEmail';
import getUserMobileExists from '../../userHooks/getUSerMobileExists';
import CheckoutPage from '../CheckoutPage';
import Google from '../Common/GoogleAuthLogin';


function NewSignUp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { series } = location.state || {};
  const theme = useTheme();
  console.log("dagaa", series);
  //let courseId = 63;   // temporary Id but need to manage courseId from the card
  let providerId = configUrl.MediNeerProviderId;
  const [userDto, setUserDto] = useState({
    userId: 0,
    providerId: providerId,
    gender: 0,
    userName: "",
    passCode: "",
    confirm: "",
    fullName: "",
    fatherName: "",
    fatherMobile: "",
    fatherEmail: "",
    fatherProfession: 0,
    address: "",
    state: null,
    city: null,
    pinCode: null,
    mobile: "",
    email: "",
    dob: null,
    profilePicture: "",
    isActive: true,
    createdBy: "",
    createdDate: new Date(),
    updatedBy: "",
    updatedDate: new Date(),
    topics: "",
    userTypeId: 4,
  });

  const [educationInfo, setEducationInfo] = useState({
    educationId: 0,
    userId: 0,
    language: 0,
    board: 0,
    class: 0,
    createdBy: "",
    createdDate: new Date(),
    updatedBy: "",
    updatedDate: new Date(),
  });
  const [errors, setErrors] = useState({});
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [isMobileExist, setIsMobileExist] = useState(false);
  const [userId, setUserId] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState();
  const [language, setLanguage] = useState();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [googleAuth, setGoogleAuth] = React.useState(false);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    axios.defaults.headers.common = {
      providerId: providerId,
    };
  }, []);


  const encryptPassword = (password) => {
    const key = 'pj23vs7nycq18uew';
    let secretKey = CryptoJS.enc.Utf8.parse(key);
    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();
    return encryptedString;
  }

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const createOrder = async (amount) => {
    try {
      const response = await axios.post(`${configUrl.AssessmentServer}/api/Payments/createOrder`);
      return response.data.orderId;
    } catch (error) {
      console.error("Error creating order:", error);
      //return null;
    }
  };



  const verify = async (couponId, discountedPrice) => {
    setLoading(true);
    let valid = false;
    //console.log("paymentreponse", response);
    // const paymentData = {
    //   razorpay_order_id: response.razorpay_order_id,
    //   razorpay_payment_id: response.razorpay_payment_id,
    //   razorpay_signature: response.razorpay_signature,
    // };

    const paymentData = {
      razorpayOrderId: "abcd123445",
      razorpayPaymentId: "abcd123445",
      razorpaySignature: "abcd123445",
    };

    const finalAmount = discountedPrice == null ? series.price : discountedPrice;
    const paymentInfo = {
      providerId: providerId,
      courseId: series.courseId,  //courseId;,
      price: finalAmount || 0,//100, //price;,
      paymentMethod: "UPI",
      couponCodeId: couponId
    }

    let studentInfo = userDto;
    console.log("datap", studentInfo);
    studentInfo.passCode = encryptPassword(userDto.passCode);

    let request = {};
    request.paymentVerification = paymentData;
    request.studetnInfo = studentInfo //userDto;
    request.educationInfo = educationInfo;
    request.paymentInfo = paymentInfo;

    console.log("data", request, studentInfo);
    try {
      const verificationResponse = await verifyPayment(request);

      console.log("response", verificationResponse);
      if (verificationResponse?.data && verificationResponse.data?.message) {
        message.success("Sign-up and payment successful.You will get course assign within next 1 hour.");
        setTimeout(() => {
          navigate("/medineer/SignIn");
        }, 1000);
        valid = true;
      } else {
        message.error("Failed to register and create order.");
        setTimeout(() => {
          navigate("/medineer");
        }, 1000);
      }
    } catch (error) {
      console.log("error", error);
      message.error("Failed to register and create order.");
    } finally {
      console.log("in finally");
      setLoading(false);
    }

    return valid;
  }

  const initiatePayment = async () => {
    const isScriptLoaded = await loadRazorpayScript();

    if (!isScriptLoaded) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const amount = 5; // Example amount, replace with actual value
    const orderId = await createOrder(amount);

    // if (!orderId) {
    //   alert("Failed to create order. Please try again.");
    //   return;
    // }

    const options = {
      key: "pqqqowoemeffkfaff",
      amount: amount * 100,
      currency: "INR",
      name: "Splayndid",
      description: "Course Purchase",
      order_id: orderId,
      handler: (res) => { verify(res) },
      theme: {
        color: "#3399cc",
      },
    };


    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };


  // const validate = async () => {
  //   let valid = true;
  //   const tempErrors = {};

  //   if (isEmailExist) {
  //     message.error("Email already exist.");
  //     valid = false;
  //   }
  //   if (isMobileExist) {
  //     message.error("Mobile already exist.");
  //     valid = false;
  //   }
  //   // Email validation with regex
  //   const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //   if (!emailRegex.test(userDto.email)) {
  //     tempErrors.email = "Enter a valid email address";
  //     valid = false;
  //   }

  //   // Mobile validation
  //   const mobileRegex = /^[6-9]\d{9}$/gi;
  //   if (!mobileRegex.test(userDto.mobile)) {
  //     tempErrors.mobile = "Mobile number must be exactly 10 digits";
  //     valid = false;
  //   }

  //   // Password validation
  //   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])/;
  //   if (!passwordRegex.test(userDto.passCode)) {
  //     tempErrors.passCode = "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character";
  //     valid = false;
  //   }

  //   // Confirm password matching
  //   if (userDto.passCode !== confirmPassword) {
  //     tempErrors.confirmPassword = "Passwords do not match";
  //     valid = false;
  //   }

  //   // Required fields
  //   if (!userDto.fullName) {
  //     tempErrors.fullName = "Full Name is required";
  //     valid = false;
  //   }
  //   if (!userDto.gender) {
  //     tempErrors.gender = "Gender is required";
  //     valid = false;
  //   }

  //   // if (!emailRegex.test(userDto.fatherEmail)) {
  //   //   tempErrors.email = "Enter a valid email address";
  //   //   valid = false;
  //   // }

  //   setErrors(tempErrors);
  //   return valid;
  // };

  const validateField = (name, value) => {
    const tempErrors = { ...errors };

    switch (name) {
      case "email":
        if (!value) tempErrors.email = "Email is required";
        else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))
          tempErrors.email = "Enter a valid email address";
        else delete tempErrors.email;
        break;

      case "mobile":
        if (!value) tempErrors.mobile = "Mobile number is required";
        else if (!/^\d{10}$/.test(value))
          tempErrors.mobile = "Mobile number must be exactly 10 digits";
        else delete tempErrors.mobile;
        break;

      case "passCode":
        if (!value) tempErrors.passCode = "Password is required";
        else if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/.test(value)
        )
          tempErrors.passCode =
            "Password must include special characters,uppercase and lowercase letters,number and meet required length.";
        else delete tempErrors.passCode;
        break;

      case "confirmPassword":
        if (value !== userDto.passCode)
          tempErrors.confirmPassword = "Passwords do not match";
        else delete tempErrors.confirmPassword;
        break;

      case "fullName":
        if (!value) tempErrors.fullName = "Full Name is required";
        else delete tempErrors.fullName;
        break;

      case "gender":
        if (!value) tempErrors.gender = "Gender is required";
        else delete tempErrors.gender;
        break;

      case "fatherEmail":
        if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
          tempErrors.fatherEmail = "Enter a valid email address";
        else delete tempErrors.fatherEmail;
        break;

      case "fatherMobile":
        if (value && !/^\d{10}$/.test(value))
          tempErrors.fatherMobile = "Mobile number must be exactly 10 digits";
        else delete tempErrors.fatherMobile;
        break;

      default:
        break;
    }

    setErrors(tempErrors);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  useEffect(() => {
    const { data: getlanguage } = getLanguage("Language", true);
    setLanguage(getlanguage);
  }, []);

  useEffect(() => {
    if (userDto?.email) {
      setUserDto({ ...userDto, 'userName': userDto.email });
    }
  }, [userDto?.email]);


  useEffect(() => {
    console.log("daaa", series);
    if (series == null) {
      message.error("Please select a test series.");
      navigate("/medineer");
    }
    //else {
    //   if (!series?.courseId) {
    //     message.error("No details found.");
    //     navigate("/medineer");
    //   }
    // }
  }, [series]);
  useEffect(() => {
    const fetch = async () => {
      const data = await getStudentDetailByEmail.getStudentDetailByEmail(userDto.email);
      if (data?.messege === 'User already exists') {
        message.error("Email already exist.");
        setIsEmailExist(true);
      } else {
        setIsEmailExist(false);
      }
    }
    if (userDto.email) {
      fetch();
    }
  }, [userDto.email]);


  useEffect(() => {
    const fetch = async () => {
      const data = await getUserMobileExists(userDto.mobile, providerId);
      console.log(data, "a66");
      if (data && data == true) {
        message.error("Mobile already exist.");
        setIsMobileExist(true);
      } else {
        setIsMobileExist(false);
      }
    }

    if (userDto.mobile) {
      fetch();
    }
  }, [userDto.mobile]);
  // useEffect(() => {
  //   const signup = async () => {
  //     console.log("data", educationInfo);
  //     const signUpservice = await onSignUpService(educationInfo);
  //     if (signUpservice) {
  //       message.success("User sign up successfully.");
  //     }
  //   }
  //   console.log("daa1");
  //   if (userId > 0) {
  //     console.log("daa2");
  //     signup();
  //   }
  // }, [userId]);


  const toggleCheckout = (e) => {
    e.preventDefault();
    if (isEmailExist) {
      message.error("Email already exist.");
      return;
    }
    if (isMobileExist) {
      message.error("Mobile already exist.");
      return;
    }
    Object.keys(userDto).forEach((key) => validateField(key, userDto[key]));
    validateField("confirmPassword", confirmPassword);
    // If there are no errors, proceed
    if (Object.keys(errors).length === 0) {
      //setLoading(true);
      //setShowCheckout((prev) => !prev);
      handleShowCheckout();
    }
  };

  const handleShowCheckout = () => {
    setShowCheckout(true);
  };

  // Handler for closing the modal
  const handleHideCheckout = () => {
    setShowCheckout(false);
  };

  const handleSignUp = async (couponId, discountedPrice) => {
    //event.preventDefault();
    //setLoading(true);
    console.log("eventdata", userDto);
    // if (!validate()) {
    //   return;
    // } else {
    //   await initiatePayment();
    // }
    if (isEmailExist && googleAuth == false) {
      message.error("Email already exist.");
      return;
    }
    if (isMobileExist && googleAuth == false) {
      message.error("Mobile already exist.");
      return;
    }
    try {
      //const isValid = await validate();
      await verify(couponId, discountedPrice);
      // } else {
      //   console.log("aaa", isValid);
      //   console.log("Validation failed");
      // }
    } catch (e) {
      message.error("Error occured");
    }
  };

  const onGoogleAuthClick = (email, auth, newUser) => {
    setLoading(true);
    setGoogleAuth(true);
    console.log(email, 'email', auth, 'auth', newUser, 'newUser')
    if (auth) {
      // TODO
      if (newUser?.isAlreadyPurchasedCourse && newUser.isAlreadyPurchasedCourse == true) {
        message.success(`You have already purchased this test series.Please verify with login.`)
        navigate(`/medineer/SignIn`);
        return;
      }
      setUserDto(newUser);
      handleShowCheckout();
      message.success(`Hi ${newUser.firstName} ${newUser.lastName}, Your registration is completed check the email for login details.`)
      // setTimeout(() => {
      //   navigate(`/medineer/SignIn`)
      // }, 1500);
    } else {
      message.success("Your google registration is failed for " + email)
      // alert("Your google registration is failed : " + email);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile" || name == "fatherMobile") {
      if (!/^\d{0,10}$/.test(value)) return;
    }
    if (e.target.name == "confirmPassword") {
      setConfirmPassword(e.target.value);
      validateField(name, value);
    } else {
      validateField(name, value);
    }
    setUserDto({ ...userDto, [name]: value });

  };

  const handleProceedToPayment = (couponData) => {
    // Navigate to payment gateway
    console.log("Proceeding to payment with price:", couponData);
    handleHideCheckout();
    const couponDataPrice = couponData?.finalPrice;
    const couponDataId = couponData?.couponID;
    handleSignUp(couponDataId, couponDataPrice);
    // Trigger payment gateway logic here
  };

  useEffect(() => {
    if (language?.$values > 0) {
      let data = language?.$values;

      const transformedLanguageList = data
        ?.filter((language) => language.isActive)
        .map((language) => ({
          label: language.valueString,
          value: language.valueInt,
        }));

      console.log("lanuagedata1", transformedLanguageList);
      let lng = transformedLanguageList[0]?.value;
      if (transformedLanguageList?.length > 0 && educationInfo?.language == 0) {
        setEducationInfo({ ...educationInfo, "language": lng });
      }
    }
  }, language);

  const handleEducationInfo = (e) => {
    setEducationInfo({ ...educationInfo, [e.target.name]: e.target.value });
  };


  const course = {
    name: "Full-Stack Development Bootcamp",
    price: 19999,
  };


  return (
    <Container maxWidth="sm" sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      fontFamily: 'Poppins'
    }}>

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={loading}
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Processing your request, please wait...
        </Typography>
      </Backdrop>

      <Paper
        elevation={3}
        sx={{
          padding: '2rem',
          //marginTop: '2rem',
          borderRadius: '10px',
          maxWidth: "450px", // Limit the width of the form
          width: "100%", // Ensure responsiveness
          margin: "px auto",
          //width: '90%',
          //backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Typography variant="h4" align="center" gutterBottom style={{ fontFamily: 'Poppins', color: '#002147', fontWeight: 'bold' }}>
          {"SIGNUP"}
        </Typography>
        {(
          <>
            {/* {handleSignUp} */}
            <form onSubmit={toggleCheckout} style={{ width: '100%' }}>
              <Grid container spacing={1}
                sx={{
                  maxWidth: "400px", // Limit the width of the form
                  width: "100%", // Ensure responsiveness
                  margin: "auto", // Center horizontally
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="h5" align="left" gutterBottom className={styles.label}>
                    Student Name
                  </Typography>
                  <TextField
                    //label="Full Name"
                    name="fullName"
                    fullWidth
                    required
                    value={userDto.fullName}
                    onChange={handleChange}
                    error={!!errors?.fullName}
                    helperText={errors?.fullName}
                    variant="outlined"
                    size='small'
                    sx={{
                      mb: 1,
                      fontSize: "1.1rem",
                      "& .MuiFormHelperText-root": {
                        fontSize: "1rem", // Increased helper text size
                      },
                    }}
                    InputProps={{ style: { fontSize: "1.5rem" } }}
                    InputLabelProps={{ shrink: false }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" align="left" gutterBottom className={styles.label}>
                    Email
                  </Typography>
                  <TextField
                    name="email"
                    fullWidth
                    required
                    value={userDto.email}
                    onChange={handleChange}
                    error={!!errors?.email}
                    helperText={errors?.email}
                    variant="outlined"
                    size="small"
                    sx={{
                      mb: 1,
                      fontSize: "1.1rem",
                      "& .MuiFormHelperText-root": {
                        fontSize: "1rem", // Increased helper text size
                      },
                    }}
                    InputProps={{ style: { fontSize: "1.5rem" } }}
                    InputLabelProps={{ shrink: false }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" align="left" gutterBottom className={styles.label}>
                    Mobile
                  </Typography>
                  <TextField
                    name="mobile"
                    fullWidth
                    required
                    value={userDto.mobile}
                    onChange={handleChange}
                    type="text"
                    error={!!errors?.mobile}
                    helperText={errors?.mobile}
                    variant="outlined"
                    size="small"
                    sx={{
                      mb: 2,
                      fontSize: "1.1rem",
                      "& .MuiFormHelperText-root": {
                        fontSize: "1rem", // Increased helper text size
                      },
                    }}
                    InputProps={{
                      style: { fontSize: "1.5rem" },
                      inputMode: "numeric", pattern: "[0-9]*"
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" required error={!!errors?.gender}>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="gender"
                  value={userDto.gender}
                  onChange={handleChange}
                  label="Gender"
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                  <MenuItem value={3}>Other</MenuItem>
                </Select>
                {errors?.gender && <FormHelperText>{errors?.gender}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Date of Birth"
                name="dob"
                fullWidth
                required
                type="date"
                value={userDto.dob}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors?.dob}
                helperText={errors?.dob}
                variant="outlined"
              />
            </Grid> */}

                <Grid item xs={12}>
                  <Typography variant="h5" align="left" gutterBottom className={styles.label}>
                    Parent Email
                  </Typography>
                  <TextField
                    //label="Full Name"
                    name="fatherEmail"
                    fullWidth
                    value={userDto.fatherEmail}
                    onChange={handleChange}
                    error={!!errors?.fatherEmail}
                    helperText={errors?.fatherEmail}
                    variant="outlined"
                    size='small'
                    sx={{
                      mb: 1,
                      fontSize: "1.1rem",
                      "& .MuiFormHelperText-root": {
                        fontSize: "1rem", // Increased helper text size
                      },
                    }}
                    InputProps={{ style: { fontSize: "1.5rem" } }}
                    InputLabelProps={{ shrink: false }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" align="left" gutterBottom className={styles.label}>
                    Parent mobile
                  </Typography>
                  <TextField
                    //label="Full Name"
                    name="fatherMobile"
                    fullWidth
                    value={userDto.fatherMobile}
                    type="text"
                    onChange={handleChange}
                    error={!!errors?.fatherMobile}
                    helperText={errors?.fatherMobile}
                    variant="outlined"
                    size='small'
                    sx={{
                      mb: 1,
                      fontSize: "1.1rem",
                      "& .MuiFormHelperText-root": {
                        fontSize: "1rem", // Increased helper text size
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: "1.5rem",
                        inputMode: "numeric", pattern: "[0-9]*"
                      }
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </Grid>

                <Grid item xs={12} style={{ float: 'left' }}>
                  <FormControl style={{ float: 'left' }} component="fieldset" error={!!errors?.gender} required>
                    <Typography variant="h5" align="left" gutterBottom className={styles.label}>
                      Gender
                    </Typography>
                    <RadioGroup
                      row
                      name="gender"
                      value={userDto.gender}
                      onChange={handleChange}
                      sx={{ gap: 2, ml: 1 }}
                    >
                      <FormControlLabel value={1} control={<Radio sx={{ transform: "scale(1.5)" }} />}
                        label={
                          <Typography variant="body1" sx={{ fontSize: "1.3rem", fontFamily: 'Poppins' }}>
                            Male
                          </Typography>
                        }
                      />
                      <FormControlLabel value={2} control={<Radio sx={{ transform: "scale(1.5)" }} />}
                        label={
                          <Typography variant="body1" sx={{ fontSize: "1.3rem", fontFamily: 'Poppins' }}>
                            Female
                          </Typography>
                        }
                      />
                      <FormControlLabel value={3} control={<Radio sx={{ transform: "scale(1.5)" }} />}
                        label={
                          <Typography variant="body1" sx={{ fontSize: "1.3rem", fontFamily: 'Poppins' }}>
                            Other
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    {errors?.gender && <FormHelperText
                      sx={{ fontSize: "1rem", color: "text.secondary" }}
                    >{errors?.gender}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" align="left" gutterBottom className={styles.label}>
                    Password
                  </Typography>
                  <TextField
                    name="passCode"
                    fullWidth
                    required
                    type={showPassword ? "text" : "password"}
                    value={userDto.passCode}
                    onChange={handleChange}
                    error={!!errors?.passCode}
                    helperText={errors?.passCode}
                    variant="outlined"
                    size="small"
                    sx={{
                      mb: 1,
                      fontSize: "1.1rem",
                      "& .MuiFormHelperText-root": {
                        fontSize: "1rem", // Increased helper text size
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            onMouseDown={(e) => e.preventDefault()} // Prevent focus loss on click
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { fontSize: '1.5rem' }
                    }}
                    InputLabelProps={{
                      sx: { fontSize: '1.2rem' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" align="left" gutterBottom className={styles.label}>
                    Confirm Password
                  </Typography>
                  <TextField
                    name="confirmPassword"
                    fullWidth
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={handleChange}
                    error={!!errors?.confirmPassword}
                    helperText={errors?.confirmPassword}
                    variant="outlined"
                    size="small"
                    sx={{
                      mb: 1,
                      fontSize: "1.1rem",
                      "& .MuiFormHelperText-root": {
                        fontSize: "1rem", // Increased helper text size
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleConfirmPasswordVisibility}
                            onMouseDown={(e) => e.preventDefault()} // Prevent focus loss on click
                            edge="end"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { fontSize: '1.5rem' }
                    }}
                    InputLabelProps={{
                      sx: { fontSize: '1.2rem' }
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                fullWidth
                multiline
                rows={2}
                value={userDto.address}
                onChange={handleChange}
                variant="outlined"
                size="small"
                sx={{ mb: 1 }}
                InputProps={{
                  sx: { fontSize: '1.5rem' }
                }}
                InputLabelProps={{
                  sx: { fontSize: '1.2rem' }
                }}
              />
            </Grid> */}

                <Grid item xs={12}>
                  {/* <Box display="flex" justifyContent="center" alignItems="center"> */}
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1,
                      width: '100%',
                      fontSize: "1.3rem",
                      color: '#fff',
                      borderRadius: '5px',
                      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)',
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      backgroundColor: loading ? theme.palette.action.disabledBackground : '#012248',
                      '&:hover': {
                        backgroundColor: '#012248', // Keep the background color the same on hover
                      }
                    }}
                    disabled={loading}
                  >
                    {/* Sign Up */}
                    {loading ? "Processing..." : "Next"}
                  </Button>
                  {/* </Box> */}
                </Grid>

              </Grid>
            </form>
            <Grid item xs={12}>
              <Box textAlign="center" sx={{ my: 1, fontFamily: 'Poppins', color: '#817c7c' }}>
                <Typography variant="h5" style={{ fontFamily: 'Poppins' }}>or</Typography>
              </Box>
            </Grid>
            <Button
              style={{
                marginBottom: "5px",
                float: "center",
                align: 'center',
                border: "none",
                paddingInline: "5%",
              }}
            >
              <GoogleOAuthProvider clientId="233834340339-695vkqrle4p424vl6sspaj5t716l3246.apps.googleusercontent.com">
                <Google onClick={(email, auth, newUser) => onGoogleAuthClick(email, auth, newUser)} providerId={configUrl.MediNeerProviderId} medineer={"SignUp"} courseId={series?.courseId} />
              </GoogleOAuthProvider>
            </Button>
            <Box textAlign="center" sx={{ mt: 1, fontFamily: 'Poppins', color: '#817c7c' }}>
              <u>
                <Typography variant="h6" fontFamily={'Poppins'}>
                  Already have an account?{' '}
                  <Link onClick={() => navigate('/medineer/SignIn', { state: { series } })} style={{ cursor: 'pointer', color: "#333333" }}>
                    Login
                  </Link>
                </Typography>
              </u>
            </Box>
          </>)
        }
      </Paper>
      <Modal
        open={showCheckout}
        onClose={handleHideCheckout}
        aria-labelledby="checkout-modal-title"
        aria-describedby="checkout-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '600px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <IconButton
            onClick={handleHideCheckout}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="checkout-modal-title"
            variant="h4"
            align="center"
            gutterBottom
            fontFamily={'Poppins'}
            color={'#012248'}
          >
            Checkout
          </Typography>
          <CheckoutPage
            course={series} // Replace with your course data
            onProceedToPayment={handleProceedToPayment}
            closeModal={handleHideCheckout}
          />
        </Box>
      </Modal>
    </Container>
  );
}

export default NewSignUp;
