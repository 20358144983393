/* eslint-disable import/first */
import 'katex/dist/katex.min.css';
import React, { useContext } from "react";
import Latex from 'react-latex-next';
{/* latex CSS */ }
// material-ui
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyle from "../../assets/dashboardStyle";
import loginPageStyle from "../../assets/loginPageStyle";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Divider from "@material-ui/core/Divider";
import { CachedOutlined, ImportContactsSharp } from '@material-ui/icons';
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, Tooltip, useTheme } from '@mui/material';
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from 'react-i18next';
import Bookmark from "../../Bookmark/Bookmark";
import Hint from "../../Hint/Hint";
import Paragraph from '../../Paragraph/Paragraph';
import radioStyles from "../../assets/customCheckboxRadioSwitch";
import { LoginContext } from '../../contexts/LoginContext';
import { TestContext } from "../../contexts/TestContext";
import questionstyles from '../../styles/QuestionTypes.module.css';
import Button from '../CustomButtons/Button';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Solution from '../Solution/Solution';

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  ...radioStyles,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  tooltip: {
    fontSize: "1.5em",
  },
};

const useStyles = makeStyles(styles);

export default function SingleChoiceQuestion({ qNoes, question, questionObj, contentType, updateQuestion, preview }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { test, selectEvent, setSelectEvent } = useContext(TestContext);
  console.log("question123", test);
  console.log(questionObj, 'questionObj123')

  const [selectedEnabled, setSelectedEnabled] = React.useState(null);
  const [showSolution, setShowSolution] = React.useState(false);
  const { userPrivileges } = useContext(LoginContext)

  const isPrivilege = (x) => {
    if (userPrivileges?.privileges?.test?.$values?.includes(x)) {
      return true;
    } else {
      return false;
    }
  };
  const handleCheck = (x) => {
    console.log("Before handleCheck:setSelectedEnabled", x);
    setSelectedEnabled(x);
    setSelectEvent(selectEvent == true ? false : true)
    question.response = x;
    question.isCorrect = x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') == question.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '');
    console.log("handleCheck:question", question);
  };
  const onClearHandle = () => {
    setSelectedEnabled(null);
    question.response = null;
    setSelectEvent(selectEvent == true ? false : true)
  }
  React.useEffect(() => {
    //needs to be clear to avoid retaining of options
    setSelectedEnabled(null);
    setSelectedEnabled(question.response);
    console.log("questionuseEffect:", question);
  }, [question]);

  const classes = useStyles();
  var options = question.options["$values"] != undefined ? question.options["$values"] : question.options;

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" p={2}>
        <Box p={2} sx={{ width: "100%" }}>
          <GridContainer alignItems="center" spacing={1}>
            <GridItem xs={5} sm={5} md={5}>
              {/* <div style={{ float: 'left', marginRight: '5px', fontSize: '21px' }}> {t("question")} {qNoes}. </div> */}
            </GridItem>
            <GridItem xs={7} sm={7} md={7}>
              <Box p={1} style={{ float: 'right' }}>
                {(questionObj[0]?.ParagraphImage != null || questionObj[0]?.ParagraphText != null) ? (
                  <Paragraph question={question} messageImage={questionObj[0]?.ParagraphImage} messageText={questionObj[0]?.ParagraphText} contentType={contentType} questionId={question.id} />
                ) : null
                }
                {
                  preview != true ?
                    <Tooltip title={t('clearSol')} classes={{ tooltip: classes.tooltip }} placement="bottom">

                      < CachedOutlined onClick={onClearHandle} style={{ color: '#594e4e', fontSize: 30 }} />
                    </Tooltip>
                    : null
                }
                {
                  preview == true
                    ? <h6 style={{ float: 'right', fontFamily: 'Poppins', backgroundColor: '#f2f2f2', borderRadius: '78px', padding: '6px 13px 6px 12px', color: '#7f7f7f' }}> {` ${question?.poolText}`}</h6> : null
                }
                {isPrivilege("Bookmark") == true && preview != true && test && test.showBookmark == 2 ? (
                  <Bookmark question={question} message={questionObj[0]?.Hint} questionId={question.id} />
                ) : null}

                {isPrivilege("ShowHint") == true && preview != true && test && test.showHint == 2 ? (
                  <Hint question={question} message={questionObj[0]?.Hint} contentType={contentType} questionId={question.id} />
                ) : null
                }

              </Box>
            </GridItem>
          </GridContainer>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <div style={{ float: 'left', marginRight: '5px', fontSize: '16px', fontWeight: '600', color: '#9c9c9c', fontFamily: 'Poppins' }}> {t("question")}: {qNoes} </div>
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Box display="flex" p={1} style={{ padding: 0 }}>
                <Box p={1} flexGrow={1} style={{ padding: 0, float: 'left', fontSize: '16px', fontFamily: 'Poppins', fontWeight: '600' }}>
                  {
                    question && questionObj[0] && questionObj[0]?.Text != null && questionObj[0]?.Text?.length > 0 ? (contentType == 1 ?
                      <div style={{ color: '#001124', fontSize: '16px', marginBottom: '10px' }}>{questionObj[0]?.Text}</div>
                      : contentType == 3 ?
                        <Latex style={{ color: '#001124' }}>{questionObj[0]?.Text}</Latex>
                        :
                        contentType == 4 ?
                          <div style={{ color: '#001124', fontSize: '16px' }}>{questionObj[0]?.Text && ReactHtmlParser(questionObj[0]?.Text)}</div>
                          : <div style={{ color: '#001124', fontSize: '16px', marginBottom: '10px' }}>{questionObj[0]?.Text}</div>
                    )
                      : null
                  }
                </Box>
              </Box>

              <Box p={1}>
                {questionObj[0]?.Image && <img src={questionObj[0]?.Image} alt="..." className={questionstyles.Questionimage} />}
              </Box>
            </Grid>
          </Grid>
          <GridContainer style={{ justifyContent: 'end' }} alignItems="center" spacing={1}>
            {/* {console.log(x === selectedEnabled, 'selectedEnabled')} */}
            {options
              ?.filter((x) => x != null)
              .map((x, i) => (
                <GridItem xs={11} sm={5} md={5} >
                  <GridContainer spacing={1} key={i}>
                    <GridItem xs={8} sm={8} md={10}>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                              onChange={() => handleCheck(x)}
                              value="a"
                              name="radio button enabled"
                              aria-label="A"
                              icon={
                                <FiberManualRecord
                                  className={classes.radioUnchecked}
                                  style={{ color: theme.palette.primary.main }}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classes.radioChecked}
                                  style={{ color: theme.palette.primary.main }}
                                />
                              }
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot,
                              }}
                              disabled={question.isSubmitted == true}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={
                            contentType == 1 ?
                              x : contentType == 3 ?
                                <Latex style={{ color: '#001124', fontFamily: 'Poppins', fontSize: '16px' }}>{x}</Latex> :
                                contentType == 4 ?
                                  ReactHtmlParser(x) :
                                  x
                          }
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} style={{ alignSelf: 'center' }}>
                      {(question.isSubmitted == true || preview == true) && question.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                        <GridItem xs={11} sm={11} md={6}>
                          <CheckIcon style={{ color: "green" }} />
                        </GridItem>
                      ) : null}

                      {question.isSubmitted == true &&
                        question.isCorrect == false &&
                        selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === x?.trim()?.toLowerCase()?.replace(/[(),]/g, '') ? (
                        <GridItem xs={11} sm={11} md={6}>
                          <CloseIcon style={{ color: "red" }} />
                        </GridItem>
                      ) : null}
                    </GridItem>
                  </GridContainer>
                  <GridItem xs={11} sm={11} md={11}>
                    <Divider />
                  </GridItem>
                </GridItem>
              ))
            }
          </GridContainer>
          <GridContainer style={{ justifyContent: 'end' }} alignItems="center" spacing={1}>
            <GridItem xs={11} sm={10} md={10} >
              <div style={{ marginBottom: '4%' }}>
                {
                  preview == true ?
                    <Button
                      // color="primary"
                      style={{
                        backgroundColor: '#001124',
                        borderRadius: '10px',
                        fontFamily: 'Poppins',
                        lineHeight: '1.38',
                        textTransform: 'capitalize'
                      }}
                      onClick={() => setShowSolution(showSolution ? false : true)}>
                      <ImportContactsSharp style={{ marginRight: '5px' }} />
                      {
                        showSolution ? t('hideSolution') : t('showSolution')
                      }
                    </Button>
                    : null
                }
                {
                  showSolution ?
                    <Solution
                      key={qNoes}
                      question={question}
                      questionObj={questionObj}
                      contentType={question.contentType}
                      updateQuestion={() => { }}
                      preview={preview}
                    />
                    : null
                }
              </div>
            </GridItem>
          </GridContainer>

        </Box>
      </Box>
    </div>
  );
}
