//import QuestionComponent from "./QuestionComponent";

import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";


import Button from "../CustomButtons/Button";


import { Tooltip, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from "@mui/material";
import { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { LoginContext } from "../../contexts/LoginContext";
import { TestContext } from "../../contexts/TestContext";
const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: "1em",
  }
}));
const WelcomeScreen = ({
  message,
  handleConfirm,
  startButtonTime,
  testStarted,
}) => {
  const { testTFlag, setTestTFlag, testAttemptDetails } =
    useContext(TestContext);
  const { showClose, setShowModalClose, setAlertFlag } = useContext(LoginContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  function getDiff() {
    const currentDate = new Date(); // Current date in local timezone
    const currentUtcDate = new Date(currentDate.toUTCString());

    const timezoneOffsetLocal = currentDate.getTimezoneOffset(); // Offset in minutes
    const timezoneOffsetUTC = currentUtcDate.getTimezoneOffset(); // Offset in minutes

    const offsetHoursLocal = Math.abs(timezoneOffsetLocal / 60);
    const offsetMinutesLocal = Math.abs(timezoneOffsetLocal % 60);

    const offsetHoursUTC = Math.abs(timezoneOffsetUTC / 60);
    const offsetMinutesUTC = Math.abs(timezoneOffsetUTC % 60);

    console.log(
      `UTC offset: ${offsetHoursUTC} hours ${offsetMinutesUTC} minutes`
    );
    console.log(
      `Local timezone offset: ${offsetHoursLocal} hours ${offsetMinutesLocal} minutes`
    );
    return offsetHoursLocal;
  }
  const toLocal = (UTCTime) => {
    // converting into local time start
    const utcDateTime = new Date(UTCTime);
    let diff = getDiff();
    console.log(diff, "inDiff");
    const istOffset = diff * 60 * 60 * 1000; // IST is UTC+5.5 hours
    const istDateTime = new Date(utcDateTime.getTime() + istOffset);
    return istDateTime;
  };
  const onStartClick = () => {
    testAttemptDetails.currentPageIndex = 1;
    console.log(
      "redirected to questionwrapper screen - ",
      testAttemptDetails.currentPageIndex
    );
    setTestTFlag(true);
    console.log("start button Clicked", new Date());
    let time = new Date();
    let flag = true;
    startButtonTime(time, flag);
    setShowModalClose(true);
    setAlertFlag(true)
  };

  console.log(testAttemptDetails, "testAttemptDetails123");

  const targetStartDateTime = testAttemptDetails?.testStartDate
    ? toLocal(testAttemptDetails.testStartDate)
    : null;
  const targetEndDateTime = testAttemptDetails?.testEndDate
    ? toLocal(testAttemptDetails.testEndDate)
    : null;
  const currentDateTime = new Date();

  console.log(
    targetStartDateTime,
    " AAA ",
    currentDateTime,
    "compareStartDate"
  );
  console.log(targetStartDateTime <= currentDateTime, "compareStartDate1");

  return (
    <div>
      <Card>
        <CardBody style={{ textAlign: "center", paddingTop: "11%" }}>
          {((testAttemptDetails?.testStartDate !== null && targetStartDateTime <= currentDateTime) &&
            (testAttemptDetails?.testEndDate !== null ? targetEndDateTime >= currentDateTime : true))
            //  ||
            // (testAttemptDetails?.testStartDate == null &&
            //   testAttemptDetails?.testEndDate == null)
            ? (
              <h4 style={{ textAlign: "center" }}>{ReactHtmlParser(message)}</h4>
            ) : null}
          <Box p={1} justifyContent="center" alignContent="center">
            {((testAttemptDetails?.testStartDate !== null && targetStartDateTime <= currentDateTime) &&
              (testAttemptDetails?.testEndDate !== null ? targetEndDateTime >= currentDateTime : true))
              // ||
              // (testAttemptDetails?.testStartDate == null &&
              //   testAttemptDetails?.testEndDate == null) 
              ? (
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title="Press start to begin the assessment"
                >
                  <Button
                    style={{
                      backgroundColor: '#001124',
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      lineHeight: '1.38',
                      borderRadius: '8px'
                    }}
                    onClick={onStartClick}
                  >
                    {t("start")} <ArrowForwardIcon style={{ marginLeft: '15%' }} />
                  </Button>
                </Tooltip>
              ) : targetEndDateTime <= currentDateTime ? (
                setShowModalClose(true),
                <h5 style={{ color: "#FB0000" }}>
                  {t("textExpired")}
                </h5>
              ) : (
                setShowModalClose(true),
                <>
                  <h5>
                    {t("testScheduled")} {"  "}
                    <Moment
                      style={{ color: "#fd9912" }}
                      format="DD-MMM-YYYY hh:mm:ss a"
                    >
                      {toLocal(testAttemptDetails?.testStartDate)}
                    </Moment>
                  </h5>
                </>
              )}
          </Box>
        </CardBody>
      </Card>
    </div>
  );
};

export default WelcomeScreen;
