

import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import Button from '../Components/CustomButtons/Button';
import styles from '../styles/DetailingTable.module.css';

const TreeHierarchy = ({ analyticsData, pieChartAnalyticsData }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    const DataPieChartTable = (data) => {
        console.log(data, 'data1235')
        // Inline styles for the table
        const tableStyle = {
            width: '100%',
            // border: '1px solid black',
            borderCollapse: 'collapse',
            fontFamily: 'Poppins',
            fontSize: '16px',
            // fontWeight: '600'
            background: '#fff',
            borderRadius: '5px'
        };
        const cellStyle = {
            border: '1px solid #e4e4e4',
            padding: '8px',
            // textAlign: 'left',
        };
        return (
            <table style={tableStyle}>
                {/* style={{ border: `solid 1px #e4e4e4`, boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)' }}> */}
                <thead style={{
                    background: 'transparent',
                    color: '#594e4e',
                    fontFamily: 'Poppins',
                    fontSize: !isMobile && '16px',
                    fontWeight: 'bold'
                }}>
                    <tr>
                        {/* <th>ID</th> */}
                        <th style={{ ...cellStyle, padding: '0px 5px 0px 5px', textAlign: 'center' }}>{t("total")}</th>
                        <th style={{ ...cellStyle, padding: '0px 5px 0px 5px', textAlign: 'center' }}>{t("unattempted")}</th>
                        <th style={{ ...cellStyle, padding: '0px 5px 0px 5px', textAlign: 'center' }}>{t("attempted")}</th>
                        <th style={{ ...cellStyle, padding: '0px 5px 0px 5px', textAlign: 'center' }}>{t("correct")}</th>
                        <th style={{ ...cellStyle, padding: '0px 5px 0px 5px', textAlign: 'center' }}>{t("incorrect")}</th>
                    </tr>
                </thead>
                <tbody>
                    {console.log("dataaka", data, data?.data)}
                    {data?.data ?
                        <tr >
                            <td style={{ ...cellStyle, padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.totalQuestions}</td>
                            <td style={{ ...cellStyle, padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.unattempted}</td>
                            <td style={{ ...cellStyle, padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.correct + data?.data?.incorrect}</td>
                            <td style={{ ...cellStyle, padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.correct}</td>
                            <td style={{ ...cellStyle, padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.incorrect}</td>
                        </tr>
                        : <tr>
                            <td colspan="5" style={{ textalign: 'center' }}>
                                <h6 >{t("norecord")}</h6>
                            </td>
                        </tr>

                    }
                </tbody>
            </table>
        );
    };
    console.log("datapie", pieChartAnalyticsData);
    const TreeNode = ({ node, packageId }) => {
        console.log("renderTree1", node)
        console.log("renderTree Name", node?.Name)
        console.log("renderTree data", node?.CorrectQuestionCount)

        return (
            <TreeItem
                key={node.Guid}
                nodeId={node.Guid}
                // label={
                //     <div className={styles.treeNode}>
                //         <div className={styles.treeNodeText}>
                //             <strong>{node.Name}:</strong> Total Questions: {node.TotalQuestions}, Attempted: {node.Attempted}, Correct: {node.Correct}, Incorrect: {node.Incorrect}
                //         </div>
                //         <div className={styles.treeNodeButtons}>
                //             <button className={styles.button} style={{ backgroundColor: '#4C8055' }}>
                //                 Total Questions
                //             </button>
                //             <button className={styles.button} style={{ backgroundColor: '#9da39d' }}>
                //                 Attempted
                //             </button>
                //             <button className={styles.button} style={{ backgroundColor: '#34C0D3' }}>
                //                 Correct
                //             </button>
                //             <button className={styles.button} style={{ backgroundColor: '#34C0D3' }}>
                //                 Incorrect
                //             </button>
                //         </div>
                //     </div>
                // }

                label={
                    <div>
                        <div xs={6} sm={6} md={4}>
                            <p style={{ float: 'left', marginTop: '14px', fontSize: '16px', fontFamily: 'Poppins', color: '#594e4e' }}>

                                {node?.TopicName}{" "}

                            </p>
                        </div>
                        <div xs={6} sm={6} md={8} style={{ float: 'right' }}>

                            <Button
                                size="sm"
                                justIcon
                                round
                                // color="behance"
                                style={{
                                    float: 'left',
                                    marginInline: '16px',
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                    fontSize: '16px',
                                    fontFamily: 'Poppins',
                                    color: '#594e4e',
                                    height: '44px',
                                    width: '47px',
                                    fontSize: '14px'
                                }}
                            >
                                {
                                    node.Label = node?.AssignedQuestionCount
                                }

                            </Button>

                            <Button
                                size="sm"
                                justIcon
                                round
                                style={isMobile ? {
                                    float: 'initial',
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                    fontSize: '16px',
                                    fontFamily: 'Poppins',
                                    color: '#594e4e',
                                    height: '44px',
                                    width: '47px',
                                    // fontSize: '14px'
                                } : {
                                    float: 'initial',
                                    marginInline: '16px',
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                    fontSize: '16px',
                                    fontFamily: 'Poppins',
                                    color: '#594e4e',
                                    height: '44px',
                                    width: '47px',
                                    // fontSize: '14px'
                                }}
                            >
                                {
                                    node.Label = node?.AttemptedQuestionCount

                                }

                            </Button>
                            <Button
                                size="sm"
                                justIcon
                                round
                                // color="primary"
                                style={{
                                    float: 'initial',
                                    marginInline: '16px',
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                    fontSize: '16px',
                                    fontFamily: 'Poppins',
                                    color: '#594e4e',
                                    height: '44px',
                                    width: '47px',
                                    // fontSize: '14px'
                                }}
                            >
                                {
                                    node.Label = node?.CorrectQuestionCount
                                }

                            </Button>
                            <Button
                                size="sm"
                                justIcon
                                round
                                style={isMobile ? {
                                    float: 'right',
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                    fontSize: '16px',
                                    fontFamily: 'Poppins',
                                    color: '#594e4e',
                                    height: '44px',
                                    width: '47px',
                                    // fontSize: '14px'
                                } : {
                                    float: 'right',
                                    marginInline: '16px',
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                    fontSize: '16px',
                                    fontFamily: 'Poppins',
                                    color: '#594e4e',
                                    height: '44px',
                                    width: '47px',
                                    // fontSize: '14px'
                                }}
                            >
                                {
                                    node.Label = node?.IncorrectQuestionCount

                                }

                            </Button>
                        </div>
                    </div>
                }
            >
                {Array.isArray(node.SubObjectList) && node.SubObjectList.length > 0
                    ? node.SubObjectList.map((subNode) => (
                        <TreeNode key={subNode.Guid} node={subNode} />
                    ))
                    : null}
            </TreeItem>
        );
    };


    return (
        <>
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                <Grid item xs={12} sm={12} md={11} >
                    <h4 className={styles.tablePieHeirarchyHeading} style={{ color: '#012248' }}>{t("coverage")}</h4>
                </Grid>
                <Grid item xs={12} sm={12} md={11}>
                    <DataPieChartTable data={pieChartAnalyticsData} />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                </Grid>
                <Grid item xs={12} sm={12} md={11} >
                    <h4 className={styles.tablePieHeirarchyHeading} style={{ color: '#012248' }}>{t("coverageDetails")}</h4>
                </Grid>
                <Grid item xs={12} sm={12} md={11} >
                    <div className={styles.treeContainer} >
                        <div>
                            <div xs={6} sm={6} md={8} style={{ float: 'right' }}>
                                <p className={styles.tableHeadingPiHeirarchy} style={{ color: '#594e4e' }} >{t("total")}  </p>

                                <p className={styles.tableHeadingPiHeirarchy} style={{ color: '#594e4e' }} >{t("attempted")}  </p>

                                <p className={styles.tableHeadingPiHeirarchy} style={{ color: '#594e4e' }} >{t("correct")}   </p>
                                <p className={styles.tableHeadingPiHeirarchy} style={{ color: '#594e4e' }} >{t("incorrect")}   </p>

                            </div>
                        </div>
                        <TreeView
                            defaultCollapseIcon={<RemoveCircleIcon sx={{ color: '#353e5c' }} />}
                            defaultExpandIcon={<AddCircleIcon sx={{ color: '#353e5c' }} />}
                            sx={{
                                height: 264,
                                flexGrow: 1,
                                maxWidth: 1000,
                                overflowY: 'false',
                            }}
                            disableSelection={true}
                        >
                            {analyticsData?.map((node) => (
                                <TreeNode key={node.Guid} node={node} />
                            ))}
                        </TreeView>
                    </div>

                </Grid>
            </Grid>
            {/* <GridItem xs={6} sm={6} md={6} style={{ marginLeft: '3px', marginRight: '3px' }}> */}
            {/* </GridItem> */}
        </>
    );
};

export default TreeHierarchy;
