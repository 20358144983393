import { useTheme } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Button from "../CustomButtons/Button";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

const ConcludeScreen = ({ message, onFinish, toconcludeScreen }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  console.log(toconcludeScreen, 'toconcludeScreen')
  const handleSubmit = () => {
    onFinish(false);
  }
  return (
    <div>
      <Card>
        <CardBody>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: 400 }}
          >
            <GridItem xs={12} sm={12} md={3} />
            <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
              <h2 style={{ fontFamily: 'Poppins' }}>{ReactHtmlParser(message)}</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} />
            <GridItem xs={12} sm={12} md={3} />
            <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
              <Button
                //  color="primary"
                style={{
                  backgroundColor: '#001124',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  lineHeight: '1.38',
                  borderRadius: '8px'
                }}
                // round
                onClick={handleSubmit}>{t('ok')}</Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} />
          </GridContainer>
        </CardBody>
      </Card>
    </div >
  );
};

export default ConcludeScreen;
