import { useTheme } from '@emotion/react';
import { Box } from '@material-ui/core';
import { CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { message } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TestSeriesCardSubscribed from '../Card/TestSeriesCard';
import TestSeriesCardUnsubscribed from '../SplashScreens/TestSeriesCard';
import TestSeriesDetails from '../SplashScreens/TestSeriesDetails';
import { LoginContext } from '../contexts/LoginContext';
import styles from '../styles/TestSeries.module.css';
import useGetAssignedCoursesById from '../testSeriesHooks/useGetAssignedCoursesById';
import GridContainer from './Grid/GridContainer';
import GridItem from './Grid/GridItem';
import MedineerLogin from './MedineerComponents/MedineerLogin';

export default function TestSeries(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { testSeriesStatus, isDrawerNavigation } = React.useContext(LoginContext);
  const [isSubscribed, setIsSubscribed] = React.useState(isDrawerNavigation == true ? false : true);
  const [loadingState, setLoadingState] = React.useState(false);
  const [selectedSeries, setSelectedSeries] = React.useState(null);
  const [courseDetails, setCourseDetails] = React.useState();
  const [showCheckout, setShowCheckout] = React.useState(false);
  console.log("assess", isDrawerNavigation);
  const { isLoading, error, data: courses, refetch } = useGetAssignedCoursesById(isSubscribed);

  const [course, setCourses] = React.useState([]);

  React.useEffect(() => {
    if (isSubscribed != null) {
      setLoadingState(true); // Set loadingState to true when toggle happens
      refetch().finally(() => setLoadingState(false));
    }
  }, [isSubscribed]);
  const handleToggle = (event, newSubscription) => {
    if (newSubscription !== null) {
      setIsSubscribed(newSubscription === "subscribe");
    }
  };

  const handleLearnMore = (series) => {
    //navigate('/medineer/test-series-details', { state: { series } });
    //setSelectedSeries(series);
  };
  const onEnroll = (series) => {
    if (series?.assingnedStudentsCount && series?.testSeriesStudentsCount && series?.testSeriesStudentsCount > 0 && (series?.assingnedStudentsCount + 1) > series?.testSeriesStudentsCount) {
      message.error("Please contact the admin.");
    } else {
      setCourseDetails(series);
      setShowCheckout(true);
    }
    //navigate('/medineer/SignUp', { state: { series } });

  }

  const handleCloseModal = (series) => {
    refetch();
    setShowCheckout(false);
    //navigate('/medineer/SignUp', { state: { series } });

  }
  console.log(courses, "courselist")
  console.log(typeof (courses), "courselist")
  const toggleButtonStyles = (selected) => ({
    fontSize: '13px',
    fontFamily: 'Poppins',
    borderRadius: selected ? '87px' : '0',
    padding: '8px 11px',
    // fontWeight: selected ? 'bold' : 'normal',
    textTransform: 'capitalize',
    border: 'none',
    backgroundColor: selected ? "#fff" : "transparent",
    color: selected
      ? "#012248"    //theme.palette.heading.main
      : "#a0a0a0",
    cursor: 'pointer',
    boxShadow: selected ? '0 2px 2px 0 rgba(0, 0, 0, 0.1)' : '',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.heading.main,
      color: theme.palette.drawerBackground.main,
      transform: 'scale(1.05)',
    },
  });

  const toggleGroupStyles = {
    display: 'inline-flex',
    borderRadius: '87px',
    borderColor: "black",
    padding: '5px',
    marginRight: '16px',
    marginBottom: '10px',
    backgroundColor: '#f2e7e7',
    boxShadow: 'inset 0 1px 2px 0 rgba(0, 0, 0, 0.1)'
  };

  return (
    <div style={{ width: 'max' }}>
      <GridContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <GridItem xs={12} sm={12} md={9} lg={9} style={{ padding: '0px !important' }}>
          <h4 className={styles.cardPageTitle} style={{ color: theme.palette.heading.main }}>
            {isSubscribed == true ?
              '' :   //t('assignedTestSeries') :
              <span className={styles.ExploreOurTestSeries}>
                <span className={styles.ExploreourTestSeriesstyle1}>Explore our Test Series —</span>
                <span className={styles.ExploreourTestSeriesstyle2}></span>
                an essential subscription for your success.
              </span>
            }
          </h4>
        </GridItem>
        {/* Toggle Button Group */}
        <GridItem xs={12} sm={12} md={2} lg={2}>
          {testSeriesStatus == "ALL" &&
            <ToggleButtonGroup
              value={isSubscribed ? "subscribe" : "unsubscribe"}
              exclusive
              onChange={handleToggle}
              aria-label="Subscription Toggle"
              style={toggleGroupStyles}
            >
              <ToggleButton value="subscribe" disabled={isLoading || loadingState} aria-label="Subscribe" style={toggleButtonStyles(isSubscribed)} >
                {'subscribed'}
              </ToggleButton>
              <ToggleButton value="unsubscribe" disabled={isLoading || loadingState} aria-label="Unsubscribe" style={toggleButtonStyles(!isSubscribed)} >
                {'Explore'}
              </ToggleButton>
            </ToggleButtonGroup>
          }
        </GridItem>
      </GridContainer>

      {selectedSeries ? (
        <TestSeriesDetails testSerieses={selectedSeries} onBack={() => setSelectedSeries(null)} />
      )
        : ((isLoading || loadingState) ?
          <Box style={{ color: theme.palette.heading.main }}>
            <CircularProgress style={{ color: theme.palette.heading.main }} />
            <h4 >{t('loading')}</h4>
          </Box>
          // <CircularProgress style={{ color: theme.palette.heading.main }} /> 
          :
          isSubscribed == true ? (
            <GridContainer>
              {
                courses && courses.length > 0 ? Object.values(courses).map((i, x) => (
                  <GridItem xs={12} sm={6} md={6} lg={4}>
                    <TestSeriesCardSubscribed
                      courseName={courses[x].courseName}
                      objective={courses[x].objective}
                      targetAudience={courses[x].targetAudience}
                      image={courses[x].image}
                      description={courses[x].description}
                      courseId={courses[x].courseId}
                      code={courses[x].code}
                      courseTestStatusObj={courses[x].courseTestStatusObj}
                    />
                  </GridItem>
                )) :
                  <GridItem xs={12} sm={6} md={6} lg={4}>
                    <TestSeriesCardSubscribed
                      courseName={t('testseriesnotfound')}
                      // objective={courses[x].objective}
                      image={'record-not-found.png'}
                    // description={courses[x].description}
                    // courseId={courses[x].courseId}
                    // code={courses[x].code}
                    // courseTestStatusObj={courses[x].courseTestStatusObj}
                    />
                  </GridItem>
              }
            </GridContainer>
          )
            : (
              <GridContainer>
                {
                  courses && courses.length > 0 ? Object.values(courses).map((i, x) => (
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <TestSeriesCardUnsubscribed
                        title={courses[x].courseName}
                        description={courses[x]?.description}
                        image={courses[x].image ?? '/Gemini_image_not_available.jpg'}
                        bulletPoints={courses[x]?.bulletPoints}
                        price={courses[x]?.price || 0}
                        onLearnMore={() => handleLearnMore(courses[x])}
                        onEnroll={() => onEnroll(courses[x])}
                        testsCount={courses[x]?.testCount || 0}
                        //duration={series.duration}
                        courseId={courses[x].courseId}
                        isHideStudentIcon={true}
                      />

                    </GridItem>
                  )) :
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <TestSeriesCardSubscribed
                        courseName={t('testseriesnotfound')}
                        // objective={courses[x].objective}
                        image={'record-not-found.png'}
                      // description={courses[x].description}
                      // courseId={courses[x].courseId}
                      // code={courses[x].code}
                      // courseTestStatusObj={courses[x].courseTestStatusObj}
                      />
                    </GridItem>
                }
                {
                  showCheckout && <MedineerLogin course={courseDetails} closeModal={handleCloseModal} />

                }
              </GridContainer>
            )
        )
      }

    </div>
  );
}