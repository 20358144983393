import { useTheme } from "@emotion/react";
import { Alert, Snackbar } from "@mui/material";
import { Button, Col, Form, Input, Row, message } from 'antd';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import React, { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { configUrl } from "../config";
import { LoginContext } from "../contexts/LoginContext";
import styles from '../styles/signin.module.css';

const ChangePassword = () => {
  const [form] = Form.useForm();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { UserId, providerKey } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severty, setSeverty] = useState('success');

  const key = 'pj23vs7nycq18uew';
  let secretKey = CryptoJS.enc.Utf8.parse(key);

  const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
  }
  const decryptPassword = (encryptedPassword, secretKey) => {

    let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedText, 'decryptedone');
    return decryptedText;
    // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    // return bytes.toString(CryptoJS.enc.Utf8);
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    //Will call this API through service (Saurabh)
    try {
      let oldPass = encryptPassword(values.currentPassword, secretKey)
      let newPass = encryptPassword(values.newPassword, secretKey)
      let confirmPass = encryptPassword(values.confirmPassword, secretKey)
      const response = await axios.post(`${configUrl.AssessmentServer}/api/Counselling/changePassword/${UserId}`, {
        oldPassword: oldPass,
        newPassword: newPass,
        confirmPassword: confirmPass
      });
      if (response.data.message) {
        if (response.data.status == true) {
          console.log("Resppnse of passwoords", response.data);
          message.success(response.data.message);
          setOpen(true);
          setSeverty('success');
          setAlertMessage(response.data.message)

          setTimeout(() => {
            setOpen(false);
            sessionStorage.clear();
            sessionStorage.setItem("login", false);
            navigate(`/${providerKey}`);
          }, 3000);
          console.log("User passwordchanges")
        } else {
          message.error(response.data.message);
          setOpen(true);
          setSeverty('error');
          setAlertMessage(response.data.data.error)
        }
        form.resetFields();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while changing password.');
    } finally {
      setLoading(false);
    }
  };
  const LabelColors = {
    color: '#001124'
  }
  return (
    <div className="container">
      <Row justify="center">
        <Col xs={24} sm={16} md={12} lg={8} style={{ textAlign: "left" }}>
          <div style={{ padding: '20px', marginTop: '10px', background: '#fff', boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)', borderRadius: '5px' }}>
            <h3 style={{ textAlign: 'center', marginTop: '0px', marginBottom: '20px', color: '#002147', fontWeight: '600', fontFamily: 'Poppins' }}>{t("changePass")}</h3>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
              }}
            >
              <label className={styles.label} style={LabelColors} for="currentPassword">{t("currentPass")}</label>
              <Form.Item
                // label={t("currentPass")}
                name="currentPassword"
                // style={{ fontWeight: '500', fontFamily: 'Poppins', color: '#001124' }}
                rules={[{ required: true, message: <span style={{ fontFamily: 'Poppins' }}>{t("enterCurrPass")}</span> }]}
              >
                <Input.Password size="middle" style={{ fontFamily: 'Poppins' }} />
              </Form.Item>
              <label className={styles.label} style={LabelColors} for="newPassword">{t("newPass")}</label>
              <Form.Item
                // label={t("newPass")}
                name="newPassword"
                // labelCol={{ style: { color: '#939393', fontFamily: 'Poppins', fontWeight: 'normal' } }}
                rules={[{ required: true, message: <span style={{ fontFamily: 'Poppins' }}>{t("enterNewPass")}</span> }]}
              >
                <Input.Password size="middle" style={{ fontFamily: 'Poppins' }} />
              </Form.Item>
              <label className={styles.label} style={LabelColors} for="confirmPassword">{t("@confirmPass")}</label>
              <Form.Item
                // label={t("@confirmPass")}
                name="confirmPassword"
                dependencies={['newPassword']}
                // labelCol={{ style: { color: '#939393', fontFamily: 'Poppins', fontWeight: 'normal' } }}
                rules={[
                  { required: true, message: <span style={{ fontFamily: 'Poppins' }}>{t("enterconfPass")}</span> },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(<span style={{ fontFamily: 'Poppins' }}>{t("@reqPassValid")}</span>));
                    },
                  }),
                ]}
              >
                <Input.Password size="middle" onPaste={(e) => e.preventDefault()} style={{ fontFamily: 'Poppins' }} />
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <Button
                  fullWidth
                  style={{
                    width: '100%',
                    height: '42px',
                    fontWeight: 'bold',
                    fontFamily: 'Poppins',
                    backgroundColor: '#002147', //theme.palette.primaryButtonBackground.main,  // "#EC9706",
                    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)',
                    borderRadius: '5px',
                    // padding: '8px 51px 16px',
                    color: "#ffff",
                    marginBottom: "18px",

                  }} htmlType="submit" loading={loading}>
                  {t("@setPass")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          severity={severty}
          onClose={handleClose}
          sx={{ fontSize: "15px", width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ChangePassword;
