import { CircularProgress } from "@material-ui/core";
import { Alert, Snackbar, useTheme } from "@mui/material";
import { Button, Form, Input, Modal } from "antd";
import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import { getSiblingData } from "../services/parentService";

// import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import Card from "../Card/Card";
import getProviderByKey from "../services/ProviderService";
import { getStudentDetail } from "../services/parentService";
import styles from "../styles/signin.module.css";
import useGetUserByUserName from "../userHooks/useGetUserByUserName";
import useProviderLearnerLogin from "../userHooks/useProviderLearnerLogin";
// import Google from "./Common/GoogleAuthLogin";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";

/* New Code Starts here */

import "react-toastify/dist/ReactToastify.css";
/* Ends here*/

/* New Code Starts here */

import { getMessaging, getToken } from "firebase/messaging";
import "react-toastify/dist/ReactToastify.css";
import { configUrl } from "../config";
import app from "../firebase";
import getPrivilegeService from "../services/getPrivilegeService";
import resetPassService from "../services/resetPassService";
import useParentLearnerLogin from "../userHooks/useParentLearnerLogin";
import CustomSelect from "./CustomSelect/CustomSelect";
import SwitchProvider from "./SwitchProvider";
/* Ends here*/

const SignInForm = () => {
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 15,
    },
  };
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loginUsername, setLoginUsername] = React.useState("");
  const [providerUsername, setProviderUsername] = React.useState("");
  const [parentUsername, setParentUsername] = React.useState("");
  const [resetEmail, setResetEmail] = React.useState("");
  const [loginPassword, setLoginPassword] = React.useState("");
  const [retry, setRetry] = React.useState(true);
  const [showAlert, setShowAlert] = React.useState(false);
  const [UserDetails, setUserDetailsData] = useState({});
  const [showModel, setShowModel] = useState(null);
  const [attemptId, setAttemptId] = React.useState(1);

  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [severty, setSeverty] = React.useState("success");
  const [googleAuth, setGoogleAuth] = React.useState(false);
  const { id } = useParams();
  const [providerIdByUrl, setProviderIdByUrl] = React.useState(null);
  const [isSignIn, setIsSignIn] = React.useState(true);
  const [isLoginAsProvider, setLoginAsProvider] = React.useState(false);
  const [isLoginAsParent, setLoginAsParent] = React.useState(false);
  const [Loading, setIsLoading] = React.useState(false);
  const {
    setUserName,
    UserName,
    setProviderKey,
    setProviderName,
    setEmailC,
    setProviderId,
    userPrivileges,
    setPackageId,
    setUserPrivileges,
    setUserId,
    setUserDetail,
    userDetail,
    setUserType,
    siblingDetail,
    setSiblingDetail,
    setEducationDetails,
    setPageKey,
    setIsParentLogin
  } = useContext(LoginContext);

  const {
    data: loginDetails,
    isLoading,
    isSuccess,
    mutate: getUserDetails,
    error: userFetchError,
  } = useGetUserByUserName();

  const {
    data: providerLoginDetails,
    isLoading: isLoadingProviderLogin,
    isSuccess: isSuccessProviderLogin,
    mutate: getProviderUserDetails,
    error: providerFetchError,
  } = useProviderLearnerLogin();

  const {
    data: parentLoginDetails,
    isLoading: isLoadingParentLogin,
    isSuccess: isSuccessParentLogin,
    mutate: getParentUserDetails,
    error: parentFetchError,
  } = useParentLearnerLogin();

  console.log(
    loginDetails?.userDetails,
    isSuccess,
    userFetchError,
    "userDetailsIn"
  );
  const handleUserName = (e) => {
    console.log(e.target.value, "username");
    setLoginUsername(e.target.value);
  };

  const handleProviderUserName = (e) => {
    console.log(e.target.value, "providerusername");
    setProviderUsername(e.target.value);
  };
  const handleParentUserName = (e) => {
    console.log(e.target.value, "setParentUsername");
    setParentUsername(e.target.value);
  };
  const handleResetEmail = (e) => {
    console.log(e.target.value, "username");
    setResetEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setLoginPassword(e.target.value);
  };
  const handleValidityExpired = () => {
    setOpen(true);
    setSeverty("error");
    setAlertMessage("User account is not active.");
    console.log("email reset successfully.");
    setTimeout(() => {
      setOpen(false);
    }, 2500);
  };

  console.log(providerIdByUrl, "providerIds");
  const onSubmit = (values) => {
    let loginInfo = {};
    loginInfo.username = loginUsername;
    loginInfo.providerId = providerIdByUrl;
    console.log(providerIdByUrl, "providerIds", loginInfo);
    getUserDetails(loginInfo);
  };

  const onProviderLoginSubmit = (values) => {
    let providerLoginInfo = {};
    providerLoginInfo.providerUserName = providerUsername;
    providerLoginInfo.studentUserName = loginUsername;
    providerLoginInfo.providerId = providerIdByUrl;
    console.log(providerIdByUrl, "providerIds", providerLoginInfo);
    getProviderUserDetails(providerLoginInfo);
  };

  const onParentLoginSubmit = (values) => {
    let parentLoginInfo = {};
    parentLoginInfo.parentEmail = parentUsername;
    parentLoginInfo.studentUserName = loginUsername;
    parentLoginInfo.providerId = providerIdByUrl;
    console.log(providerIdByUrl, "providerIds", parentLoginInfo);
    getParentUserDetails(parentLoginInfo);
  };

  const handleReset = async () => {
    axios.defaults.headers.common = {
      providerId: providerIdByUrl,
    };
    setIsLoading(true);
    let emailResp = await resetPassService.EmailForForgotPass(resetEmail);
    setIsLoading(false);
    console.log(emailResp, "emailResp");
    if (emailResp.status == 200) {
      setOpen(true);
      setSeverty("success");
      setAlertMessage("Email send successfully");
      console.log("email reset successfully.");
      setTimeout(() => {
        setOpen(false);
        setIsSignIn(true);
      }, 3000);
    } else {
      setIsLoading(false);
      setOpen(true);
      setSeverty("error");
      setAlertMessage(emailResp.messege);
    }
  };

  const onGoogleAuthClick = (email, auth, newUser, providerIdByUrl) => {
    if (auth) {
      setGoogleAuth(true);
      setLoginUsername(email);
      let loginInfo = {};
      loginInfo.username = email;
      loginInfo.providerId = providerIdByUrl;
      getUserDetails(loginInfo);
    } else {
      setGoogleAuth(false);
    }
  };
  const getData = async () => {
    if (loginDetails?.userDetails) {
      // const getEduDta = await geteducationData(loginDetails?.userDetails.userId);
      // setEducationDetails(getEduDta);
      const getSiblingDta = await getSiblingData(
        loginDetails?.userDetails.userId
      );
      setSiblingDetail(getSiblingDta.$values[0]);
      const StudentDetail = await getStudentDetail(
        loginDetails?.userDetails.userId
      );
      setUserDetail(StudentDetail?.studetnInfo);
      setEducationDetails(StudentDetail?.educationInfo);

      console.log(StudentDetail, "getStudentDetail123");
    }
  };

  const getDataParent = async () => {
    if (parentLoginDetails?.userDetails) {
      const StudentDetail = await getStudentDetail(
        parentLoginDetails?.userDetails.userId
      );
      setUserDetail(StudentDetail?.studetnInfo);
      setEducationDetails(StudentDetail?.educationInfo);
      console.log(StudentDetail, "getStudentDetail123");
    }
  };

  const messaging = getMessaging(app);

  const activateMessages = async () => {
    console.log("messaging", messaging);
    const token = await getToken(messaging, {
      vapidKey:
        "BFLHmJUchm5llz8UsotJ8ztrq30QWeATAIwEb-Oxl2lEjztAG1qYRWXxkccoQJfLGY2vVbYj0NuQHdUteFT0570",
    }).catch((error) => console.log("error generatin token"));

    if (token) {
      console.log("After Login token", token);
      const payload = {
        userId: parentLoginDetails ? parentLoginDetails?.userDetails.userId : loginDetails?.userDetails.userId,
        fcmToken: token,
      };
      let isParent = isLoginAsParent == true ? true : false;
      let response = await axios.post(
        `${configUrl.AssessmentServer}/api/Counselling/setFCMKey/${isParent}`,
        payload
      );

      return response.data;
    } else if (!token) {
      console.log("no token");
    }
  };

  // const selectedProvider = (v, x) => {
  //   console.log(v, 'isV', x, 'isX')
  //   setAttemptId(v);
  //   console.log(providerIdByUrl, "check me again");
  //   setProviderId(v);
  //   setProviderName(x.map((x) => x.providerName));
  //   axios.defaults.headers.common = {
  //     providerId: v,
  //   };

  //   sessionStorage.setItem("login", true);
  //   console.log("navigatingggg  from signup");
  //   navigate("/Home");

  // }
  const showProviderList = async (providerlist) => {
    console.log(providerlist, "providerlists");
    setShowModel(
      <Modal
        title={t("select")}
        open={true}
        onOk={hideEvaluationModel}
        onCancel={hideEvaluationModel}
        footer={null}
        centered
      >
        <SwitchProvider
          providerlist={providerlist}
          onClose={hideEvaluationModel}
          onValidityExpired={handleValidityExpired}
          isParent={isLoginAsParent == true ? true : false}
        />
      </Modal>
      // </Modal>
      // <CustomModal
      //   modal={true}
      //   setModal={hideEvaluationModel}
      //   content={
      //     <SwitchProvider
      //       providerlist={providerlist}
      //     />
      //     // <CustomSelect
      //     //   style={{ marginInline: 10 }}
      //     //   options={providerlist.map((x) => ({ id: x.id, name: x.providerName }))}
      //     //   value={1}
      //     //   setvalue={(v) => {
      //     //     console.log(v, 'vvvkkk');
      //     //     selectedProvider(v, providerlist.filter((x) => x.id == v));
      //     //     // setAttemptId(v);
      //     //     // getQuestions(v);
      //     //   }}
      //     //   label={t('selectAttempt')}
      //     // />
      //   }
      //   showOk={false}
      //   showCancel={false}
      //   onCancelClick={hideEvaluationModel}
      //   title={t('selectProvider')}
      //   showTitle={false}
      //   // maxWidth="lg"
      //   hideTitle={true}
      // // fullWidth={false}
      // />
    );
  };

  const hideEvaluationModel = (event, reason) => {
    console.log("reason", reason);
    if (reason === "backdropClick") {
      return;
    }
    setShowModel(null);
  };

  const isValidId = async () => {
    try {
      const providerData = await getProviderByKey(id);
      console.log("providerdaada", providerData);
      setProviderIdByUrl(providerData.providerId);
      setProviderKey(providerData.providerKey);
      // setProviderName(providerData.name);
      console.log("names:", providerData.name);
      // If the providerData is retrieved, the ID is valid
      if (providerData == null) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      //  (ID not found ) return false
      console.log(error);
    }
  };
  // Common Url - uncommit below changes
  // useEffect(() => {
  //   if (providerIdByUrl === null) {
  //     isValidId()
  //       .then(isValid => {
  //         if (!isValid) {
  //           //alert("Invalid URL");
  //           console.log("im false");
  //           message.error("Path not valid");
  //           setTimeout(() => {
  //             navigate('/');
  //           }, 2000);
  //         } else {
  //           console.log("im true");
  //         }
  //       })
  //       .catch(error => {
  //         console.error('Error fetching provider data:', error);
  //         alert("Error fetching provider data. Please try again.");
  //       });
  //   }
  // }, []);
  const setPrivileges = async () => {
    let userId = parentLoginDetails ? parentLoginDetails?.userDetails.userId : loginDetails?.userDetails.userId;
    const privilegesDetails = await getPrivilegeService.getPrivileges(
      userId
    );
    setUserPrivileges(privilegesDetails);
    setPackageId(privilegesDetails.packageId);
  };
  const convertToLocalTime = (utcDateTime, endOfDay = false) => {
    const date = new Date(utcDateTime);
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );

    if (endOfDay) {
      localDate.setHours(23, 59, 59, 999);
    } else {
      localDate.setHours(0, 0, 0, 0);
    }
    console.log(localDate, "localDates");
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");
    const seconds = String(localDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(localDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  };
  const getCurrentDateTimeFormatted = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
    return `${year}-${month}-${day}T00:00:00.00Z`;
  };

  useEffect(() => {
    if (userFetchError === "Not Found") {
      setOpen(true);
      setSeverty("error");
      setAlertMessage("Please check userId or password");
      console.log("user name not exist.");
    }
    if (isSuccess) {
      if (
        loginDetails?.userDetails &&
        loginDetails?.userDetails.userName.toLowerCase() ==
        loginUsername.toLowerCase() &&
        (loginDetails?.userDetails.passCode == loginPassword ||
          googleAuth == true)
      ) {
        console.log(loginDetails, "CVer123");
        setUserId(loginDetails?.userDetails.userId);
        setUserName(loginDetails?.userDetails.fullName);
        setEmailC(loginDetails?.userDetails.userName);
        // let privileges = loginDetails?.privilegeObject ? JSON.parse(loginDetails?.privilegeObject) : "";
        // setUserPrivileges(privileges?.Value);

        setPageKey(1);
        // Common Url - check diff 18/05/2024.
        if (loginDetails.userDetails.providerLists["$values"].length === 1) {
          const validFromLocal = convertToLocalTime(
            loginDetails?.userDetails.validFrom,
            false
          );
          const validToLocal = convertToLocalTime(
            loginDetails?.userDetails.validTo,
            false
          );
          const now = getCurrentDateTimeFormatted();

          if (
            new Date(now) >= new Date(validFromLocal) &&
            new Date(now) <= new Date(validToLocal)
          ) {
            console.log(
              "is inactive acc1",
              now,
              "From",
              validFromLocal,
              "To",
              validToLocal
            );
            setProviderId(loginDetails.userDetails.providerId);
            axios.defaults.headers.common = {
              providerId: loginDetails.userDetails.providerId,
            };
            setProviderName(
              loginDetails.userDetails.providerLists["$values"][0].providerName
            );
            setPrivileges();
            getData();
            sessionStorage.setItem("login", true);
            console.log("navigatingggg  from signup");
            navigate("/Home");
            activateMessages();
          } else {
            console.log(
              "is inactive acc1",
              now,
              "From",
              validFromLocal,
              "To",
              validToLocal
            );
            console.log(
              new Date(now) >= new Date(validFromLocal),
              "xx",
              new Date(now) <= new Date(validToLocal),
              "case"
            );
            setAlertMessage("User account is not active.");
            setSeverty("error");
            setOpen(true);
          }
        } else {
          showProviderList(
            loginDetails.userDetails.providerLists != null &&
            loginDetails.userDetails.providerLists["$values"]
          );
        }
        // console.log(providerIdByUrl, "check me again");
        // setProviderId(providerIdByUrl);
        // axios.defaults.headers.common = {
        //   providerId: providerIdByUrl,
        // };

        // sessionStorage.setItem("login", true);
        // console.log("navigatingggg  from signup");
        // navigate("/Home");
      } else {
        setOpen(true);
        setSeverty("error");
        setAlertMessage("Please check userId or password");
        console.log("check username and password ");
      }
    } else {
      console.log("user name not exist.");
    }
  }, [loginDetails]);

  useEffect(() => {
    if (providerFetchError === "Not Found") {
      setOpen(true);
      setSeverty("error");
      setAlertMessage("Please check userId or password");
      console.log("user name not exist.");
    }
    console.log(isSuccessProviderLogin, "PL1");
    if (isSuccessProviderLogin) {
      console.log(loginPassword, "PW1");
      // console.log(providerLoginDetails?.userDetails.passCode, "PW2");

      if (
        providerLoginDetails?.userDetails &&
        providerLoginDetails?.userDetails.userName.toLowerCase() ==
        providerUsername.toLowerCase() &&
        providerLoginDetails?.userDetails.passCode == loginPassword
      ) {
        console.log(providerLoginDetails, "CVer123");
        setUserId(providerLoginDetails?.userDetails.userId);
        setUserName(providerLoginDetails?.userDetails.fullName);
        setEmailC(providerLoginDetails?.userDetails.email);

        setPageKey(1);
        // Common Url - check diff 18/05/2024.
        if (
          providerLoginDetails.userDetails.providerLists["$values"].length === 1
        ) {
          const validFromLocal = convertToLocalTime(
            providerLoginDetails?.userDetails.validFrom,
            false
          );
          const validToLocal = convertToLocalTime(
            providerLoginDetails?.userDetails.validTo,
            false
          );
          const now = getCurrentDateTimeFormatted();

          if (
            new Date(now) >= new Date(validFromLocal) &&
            new Date(now) <= new Date(validToLocal)
          ) {
            console.log(
              "is inactive acc1",
              now,
              "From",
              validFromLocal,
              "To",
              validToLocal
            );
            setProviderId(providerLoginDetails.userDetails.providerId);
            axios.defaults.headers.common = {
              providerId: providerLoginDetails.userDetails.providerId,
            };
            setProviderName(
              providerLoginDetails.userDetails.providerLists["$values"][0]
                .providerName
            );
            // setPrivileges();
            let privileges = providerLoginDetails?.privilegeObject
              ? JSON.parse(providerLoginDetails?.privilegeObject)
              : "";
            console.log("Provider Privileges", privileges);
            setUserPrivileges(privileges?.Value);
            console.log("Provider PackageId", privileges?.Value.PackageId);
            setPackageId(privileges?.Value.PackageId);
            //getData();
            sessionStorage.setItem("login", true);

            navigate("/Home");
            // activateMessages();
          } else {
            console.log(
              "is inactive acc1",
              now,
              "From",
              validFromLocal,
              "To",
              validToLocal
            );
            console.log(
              new Date(now) >= new Date(validFromLocal),
              "xx",
              new Date(now) <= new Date(validToLocal),
              "case"
            );
            setAlertMessage("User account is not active.");
            setSeverty("error");
            setOpen(true);
          }
        } else {
          showProviderList(
            providerLoginDetails.userDetails.providerLists != null &&
            providerLoginDetails.userDetails.providerLists["$values"]
          );
        }
      } else {
        setOpen(true);
        setSeverty("error");
        setAlertMessage("Please check userId or password");
        console.log("check username and password ");
      }
    } else {
      console.log("user name not exist.");
    }
  }, [providerLoginDetails]);

  // console.log("setuserdetailsData",UserDetails)
  // console.log(UserDetails.userName, "username", UserDetails.passCode);

  useEffect(() => {
    if (parentFetchError === "Not Found") {
      setOpen(true);
      setSeverty("error");
      setAlertMessage("Please check userId or password");
      console.log("user name not exist.");
    }
    console.log("parentdata", parentLoginDetails);
    if (isSuccessParentLogin) {
      if (
        parentLoginDetails?.userDetails &&
        parentLoginDetails?.userDetails.userName.toLowerCase() ==
        loginUsername.toLowerCase() &&
        (parentLoginDetails?.userDetails.passCode == loginPassword ||
          googleAuth == true)
      ) {
        console.log(parentLoginDetails, "CVer123");
        setUserId(parentLoginDetails?.userDetails.userId);
        setUserName(parentLoginDetails?.userDetails.fullName);
        setEmailC(parentLoginDetails?.userDetails.userName);
        setIsParentLogin(true);
        // let privileges = loginDetails?.privilegeObject ? JSON.parse(loginDetails?.privilegeObject) : "";
        // setUserPrivileges(privileges?.Value);

        setPageKey(1);
        // Common Url - check diff 18/05/2024.
        if (parentLoginDetails.userDetails.providerLists["$values"].length === 1) {
          const validFromLocal = convertToLocalTime(
            parentLoginDetails?.userDetails.validFrom,
            false
          );
          const validToLocal = convertToLocalTime(
            parentLoginDetails?.userDetails.validTo,
            false
          );
          const now = getCurrentDateTimeFormatted();

          if (
            new Date(now) >= new Date(validFromLocal) &&
            new Date(now) <= new Date(validToLocal)
          ) {
            console.log(
              "is inactive acc1",
              now,
              "From",
              validFromLocal,
              "To",
              validToLocal
            );
            setProviderId(parentLoginDetails.userDetails.providerId);
            axios.defaults.headers.common = {
              providerId: parentLoginDetails.userDetails.providerId,
            };
            setProviderName(
              parentLoginDetails.userDetails.providerLists["$values"][0].providerName
            );
            //setPrivileges();
            let privileges = parentLoginDetails?.privilegeObject
              ? JSON.parse(parentLoginDetails?.privilegeObject)
              : "";
            console.log("Provider Privileges", privileges);
            setUserPrivileges(privileges?.Value);
            console.log("Provider PackageId", privileges?.Value.PackageId);
            setPackageId(privileges?.Value.PackageId);
            getDataParent();
            sessionStorage.setItem("login", true);
            console.log("navigatingggg  from signup");
            navigate("/Home");
            activateMessages();
          } else {
            console.log(
              "is inactive acc1",
              now,
              "From",
              validFromLocal,
              "To",
              validToLocal
            );
            console.log(
              new Date(now) >= new Date(validFromLocal),
              "xx",
              new Date(now) <= new Date(validToLocal),
              "case"
            );
            setAlertMessage("User account is not active.");
            setSeverty("error");
            setOpen(true);
          }
        } else {
          showProviderList(
            parentLoginDetails.userDetails.providerLists != null &&
            parentLoginDetails.userDetails.providerLists["$values"]
          );
        }
        // console.log(providerIdByUrl, "check me again");
        // setProviderId(providerIdByUrl);
        // axios.defaults.headers.common = {
        //   providerId: providerIdByUrl,
        // };

        // sessionStorage.setItem("login", true);
        // console.log("navigatingggg  from signup");
        // navigate("/Home");
      } else {
        setOpen(true);
        setSeverty("error");
        setAlertMessage("Please check userId or password");
        console.log("check username and password ");
      }
    } else {
      console.log("user name not exist.");
    }
  }, [parentLoginDetails]);


  const changeLang = (data) => {
    // alert(data);
    sessionStorage.setItem("lang", data);
    i18next.changeLanguage(data);
  };
  useEffect(() => {
    let currentLang = sessionStorage.getItem("lang");
    i18next.changeLanguage(currentLang);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleIsProviderStatus = () => {
    setIsSignIn(false);
    setLoginAsProvider(true);
  };

  const handleIsParentStatus = () => {
    setIsSignIn(false);
    setLoginAsParent(true);
  };

  const handleForgotPasswordStatus = () => {
    setIsSignIn(false);
    setLoginAsProvider(false);
  };

  return (
    <div className={styles.cardRow}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={3} style={{ marginInline: "11px" }}>
          <Card
            style={{
              padding: "11px 11px 0px",
              zIndex: "9",
              boxShadow: "10px 16px 28px #000000",
            }}
          >
            {isSignIn ? (
              <>
                <h4 className={styles.Sheading}>{t("welcome")}</h4>
                <Form
                  name="normal_login"
                  // className={styles.responsive}
                  onFinish={(values) => onSubmit(values)}
                  onFinishFailed={({ values, errorFields, outOfDate }) => { }}
                >
                  <label className={styles.label} htmlFor="email">
                    {t("@email")}
                  </label>
                  <Form.Item
                    id="email"
                    // {...formItemLayout}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t("reqUsername"),
                      },
                    ]}
                  >
                    <Input
                      onChange={handleUserName}
                      placeholder="Enter your email Id"
                    />
                  </Form.Item>
                  <label className={styles.label} htmlFor="password">
                    {t("@pass")}
                  </label>
                  <Form.Item
                    id="password"
                    // {...formItemLayout}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t("@reqPasswrd"),
                      },
                    ]}
                  >
                    <Input.Password
                      onChange={handlePassword}
                      placeholder="Enter your password"
                      minLength={4}
                    />
                  </Form.Item>
                  <Form.Item
                    // {...formItemLayout}
                    className={styles.minControls}
                  >
                    {/* <Checkbox className={styles.rememberMe}>
                        {t("rememberme")}
                      </Checkbox> */}
                    <a
                      className={styles.forgotPass}
                      style={{
                        color: theme.palette.primary.main,
                        fontFamily: 'Poppins'
                      }}
                      onClick={handleForgotPasswordStatus}
                    >
                      {t("forgotPass")}
                    </a>
                  </Form.Item>
                  <Form.Item
                  // {...formItemLayout}
                  >
                    {isLoading == true ? (
                      <CircularProgress
                        style={{ size: "1rem", color: theme.palette.primary.main }}
                      />
                    ) : (
                      <Button
                        // type="primary"
                        htmlType="submit"
                        className={styles.loginButton}
                        // style={{ backgroundColor: "#FF802B" }}
                        style={{
                          backgroundColor: theme.palette.primaryButtonBackground.main
                        }}
                      >
                        {t("@signIn")}
                      </Button>
                    )}
                  </Form.Item>
                  <Form.Item
                    // {...formItemLayout}
                    className={styles.minControls}
                  >
                    {/* <Checkbox className={styles.rememberMe}>
                        {t("rememberme")}
                      </Checkbox> */}
                    <center>
                      <a
                        className={styles.forgotPass}
                        style={{ float: "none", fontFamily: 'Poppins', color: theme.palette.primary.main }}
                        onClick={handleIsProviderStatus}
                      >
                        {t("loginAsProvider")}
                      </a>
                    </center>
                  </Form.Item>
                  <Form.Item
                    // {...formItemLayout}
                    className={styles.minControls}
                  >
                    {/* <Checkbox className={styles.rememberMe}>
                        {t("rememberme")}
                      </Checkbox> */}
                    <center>
                      <a
                        className={styles.forgotPass}
                        style={{ float: "none", fontFamily: 'Poppins', color: theme.palette.primary.main }}
                        onClick={handleIsParentStatus}
                      >
                        {"Login As Parent"}
                      </a>
                    </center>
                  </Form.Item>
                  <Form.Item>
                    {/* <h4 className={styles.Hline}>Or</h4> */}
                    {/* <br /> */}
                    {/*  */}

                    {/* <div className={styles.flotingButtons}>
                        <Button>
                          <GoogleOAuthProvider clientId="233834340339-695vkqrle4p424vl6sspaj5t716l3246.apps.googleusercontent.com">
                            <Google
                              onClick={(email, auth, newUser) =>
                                onGoogleAuthClick(email, auth, newUser, providerIdByUrl)
                              }
                              providerId={providerIdByUrl}
                            />
                          </GoogleOAuthProvider>
                        </Button>
                      </div> */}
                    {/* <small className={styles.toSignUp}>
                      {t("donthaveacc")}
                      <a
                        style={{ color: "#FF802B", marginLeft: "5px" }}
                        href={`/signup/${id}`}
                      >
                        {t("@signUp")}
                      </a>
                    </small> */}
                    <br />
                    <div style={{ marginInline: "15%", textAlign: "left" }}>
                      <CustomSelect
                        options={[
                          {
                            id: "en",
                            name: "English",
                          },
                          {
                            id: "mr",
                            name: "मराठी",
                          },
                        ]}
                        value={i18next.language}
                        setvalue={(v) => {
                          console.log(v, "vvvkkk");
                          changeLang(v);
                        }}
                        label={t("Change Language")}
                      />
                    </div>
                    {/* <select
                        style={{ margin: "23px 18px 5px 22px" }}
                        className="selectLanguage"
                        value={i18next.language}
                        onChange={(e) => changeLang(e.target.value)}
                      >
                        <option value="en">English</option>
                        <option value="mr">मराठी</option>
                      </select> */}
                  </Form.Item>
                </Form>
              </>
            ) : isLoginAsProvider ? (
              <>
                <h4 className={styles.Sheading}>{t("welcome")}</h4>
                <Form
                  name="provider_login"
                  // className={styles.responsive}
                  onFinish={(values) => onProviderLoginSubmit(values)}
                  onFinishFailed={({ values, errorFields, outOfDate }) => { }}
                >
                  <label className={styles.label} htmlFor="providerEmail">
                    {t("@providerEmail")}
                  </label>
                  <Form.Item
                    id="providerEmail"
                    // {...formItemLayout}
                    name="providerEmail"
                    rules={[
                      {
                        required: true,
                        message: t("reqUsername"),
                      },
                    ]}
                  >
                    <Input
                      onChange={handleProviderUserName}
                      placeholder="Enter mentor email Id"
                    />
                  </Form.Item>
                  <label className={styles.label} htmlFor="password">
                    {t("@pass")}
                  </label>
                  <Form.Item
                    id="password"
                    // {...formItemLayout}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t("@reqPasswrd"),
                      },
                    ]}
                  >
                    <Input.Password
                      onChange={handlePassword}
                      placeholder="Enter your password"
                      minLength={4}
                    />
                  </Form.Item>
                  <label className={styles.label} htmlFor="studentEmail">
                    {t("@studentEmail")}
                  </label>
                  <Form.Item
                    id="studentEmail"
                    // {...formItemLayout}
                    name="studentEmail"
                    rules={[
                      {
                        required: true,
                        message: t("reqUsername"),
                      },
                    ]}
                  >
                    <Input
                      onChange={handleUserName}
                      placeholder="Enter students email Id"
                    />
                  </Form.Item>
                  <Form.Item>
                    {isLoadingProviderLogin == true ? (
                      <CircularProgress
                        // style={{ size: "1rem", color: "#FF802B" }}
                        style={{
                          size: "1rem",
                          color: theme.palette.primary.main
                        }}
                      />
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className={styles.loginButton}
                        // style={{ backgroundColor: "#FF802B" }}
                        style={{
                          backgroundColor: theme.palette.primaryButtonBackground.main
                        }}
                      >
                        {t("@signIn")}
                      </Button>
                    )}
                  </Form.Item>
                  <Form.Item>
                    {/* <h4 className={styles.Hline}>Or</h4> */}
                    {/* <br /> */}
                    {/*  */}

                    <small className={styles.toSignUp}>
                      {t("@signINRedirect")}
                      <a
                        style={{ color: theme.palette.primary.main, marginLeft: "5px" }}
                        href={`/signIn`}
                      >
                        {t("@signIn")}
                      </a>
                    </small>
                    <br />
                  </Form.Item>
                </Form>
              </>
            ) :
              isLoginAsParent ? (
                <>
                  <h4 className={styles.Sheading}>{t("welcome")}</h4>
                  <Form
                    name="parent_login"
                    // className={styles.responsive}
                    onFinish={(values) => onParentLoginSubmit(values)}
                    onFinishFailed={({ values, errorFields, outOfDate }) => { }}
                  >
                    <label className={styles.label} htmlFor="parentEmail">
                      {"Parent Email"}
                    </label>
                    <Form.Item
                      id="ParentEmail"
                      // {...formItemLayout}
                      name="ParentEmail"
                      rules={[
                        {
                          required: true,
                          message: t("reqUsername"),
                        },
                      ]}
                    >
                      <Input
                        onChange={handleParentUserName}
                        placeholder="Enter parent email Id"
                      />
                    </Form.Item>
                    <label className={styles.label} htmlFor="password">
                      {t("@pass")}
                    </label>
                    <Form.Item
                      id="password"
                      // {...formItemLayout}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: t("@reqPasswrd"),
                        },
                      ]}
                    >
                      <Input.Password
                        onChange={handlePassword}
                        placeholder="Enter your password"
                        minLength={4}
                      />
                    </Form.Item>
                    <label className={styles.label} htmlFor="studentEmail">
                      {t("@studentEmail")}
                    </label>
                    <Form.Item
                      id="studentEmail"
                      // {...formItemLayout}
                      name="studentEmail"
                      rules={[
                        {
                          required: true,
                          message: t("reqUsername"),
                        },
                      ]}
                    >
                      <Input
                        onChange={handleUserName}
                        placeholder="Enter students email Id"
                      />
                    </Form.Item>
                    <Form.Item>
                      {isLoadingParentLogin == true ? (
                        <CircularProgress
                          // style={{ size: "1rem", color: "#FF802B" }}
                          style={{
                            size: "1rem",
                            color: theme.palette.primary.main
                          }}
                        />
                      ) : (
                        <Button
                          type="primary"
                          htmlType="submit"
                          className={styles.loginButton}
                          // style={{ backgroundColor: "#FF802B" }}
                          style={{
                            backgroundColor: theme.palette.primaryButtonBackground.main
                          }}
                        >
                          {t("@signIn")}
                        </Button>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {/* <h4 className={styles.Hline}>Or</h4> */}
                      {/* <br /> */}
                      {/*  */}

                      <small className={styles.toSignUp}>
                        {t("@signINRedirect")}
                        <a
                          style={{ color: theme.palette.primary.main, marginLeft: "5px" }}
                          href={`/signIn`}
                        >
                          {t("@signIn")}
                        </a>
                      </small>
                      <br />
                    </Form.Item>
                  </Form>
                </>
              ) : (
                <>
                  <h4 className={styles.Sheading}>{t("Reset Password")}</h4>
                  <Form
                    name="normal_login"
                    // className={styles.responsive}
                    onFinish={(values) => handleReset(values)}
                    onFinishFailed={({ values, errorFields, outOfDate }) => { }}
                  >
                    <label className={styles.label} htmlFor="email">
                      {t("@email")}
                    </label>
                    <Form.Item
                      id="email"
                      // {...formItemLayout}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: t("reqUsername"),
                        },
                      ]}
                    >
                      <Input
                        onChange={handleResetEmail}
                        placeholder="Enter your email Id"
                      />
                    </Form.Item>

                    {/* <Form.Item
                        // {...formItemLayout}
                        className={styles.minControls}
                      >
                        <a className={styles.forgotPass} href="#" onClick={() => setIsSignIn(true)}>
                          {t("@signIn")}
                        </a>
                      </Form.Item> */}
                    <Form.Item
                    // {...formItemLayout}
                    >
                      {Loading == true ? (
                        <CircularProgress
                          style={{ size: "1rem", color: theme.palette.primary.main }}
                        />
                      ) : (
                        <Button
                          type="primary"
                          htmlType="submit"
                          className={styles.loginButton}
                          // style={{ backgroundColor: "#FF802B" }}
                          style={{
                            backgroundColor: theme.palette.primaryButtonBackground.main
                          }}
                        >
                          {t("Submit")}
                        </Button>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {/* <h4 className={styles.Hline}>Or</h4> */}
                      {/* <br /> */}
                      {/*  */}

                      <small className={styles.toSignUp}>
                        {t("@signINRedirect")}
                        <a
                          style={{ color: theme.palette.primary.main, marginLeft: "5px" }}
                          href={`/signIn`} ///signIn/${id}
                        >
                          {t("@signIn")}
                        </a>
                      </small>
                      <br />
                    </Form.Item>
                  </Form>
                </>
              )}
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          severity={severty}
          onClose={handleClose}
          sx={{ fontSize: "15px", width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {showModel}
    </div>
  );
};

export default SignInForm;
