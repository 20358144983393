import { useTheme } from '@emotion/react';
import { Box, Card, CircularProgress } from '@material-ui/core';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import UseGetNotificationDescription from '../../Hooks/notificationsHooks/UseGetNotificationDescription';
import useGetAllNotifications from '../../Hooks/notificationsHooks/useGetAllNotifications';
import { LoginContext } from '../../contexts/LoginContext';
import styles from '../../styles/Notification.module.css';
import CustomModal from '../CustomModal/CustomModal';
import NotificationDetails from '../Notification/NotificationDetails';


export default function Notification() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { UserId } = React.useContext(LoginContext);

  const { isLoading, data } = useGetAllNotifications(UserId);
  const [notificationModal, setNotificationModal] = React.useState(null);

  const showNotificationModel = async (id) => {
    const notificationDetails = await UseGetNotificationDescription(id)
    console.log("attNotification", id);
    console.log('notificationDetails', notificationDetails)
    setNotificationModal(
      <CustomModal
        modal={true}
        setModal={hideNotificationModal}
        content={
          <NotificationDetails
            key={notificationDetails.notificationIds}
            notificationDetails={notificationDetails}
          />
        }
        showOk={false}
        okButtonMessage={'Ok'}
        showCancel={false}
        onCancelClick={hideNotificationModal}
        title={notificationDetails?.notification?.title}
        // maxWidth="lg"
        // fullWidth={true}
        hideTitle={true}
      // fullScreen
      />
    );
  };
  const hideNotificationModal = (event, reason) => {
    if (reason) console.log("reason", reason);
    setNotificationModal(null);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} sx={{ paddingInline: '12px' }}>
          <h4 className={styles.notificationHead} >{t('activitiesboard')}</h4>
        </Grid>
        {
          isLoading == true ?
            <Grid item xs={12} sm={12} md={12} sx={{ paddingInline: '12px' }}>
              <Box className={styles.Loader}>
                <CircularProgress />
                <h4 >{t('loading')}</h4>
              </Box>
            </Grid>
            :
            data && data["$values"].length != 0 ? data["$values"].map((x, i) => (
              <Grid item xs={12} sm={12} md={6} sx={{ paddingInline: '12px' }}>
                <Card className={styles.card} onClick={() => showNotificationModel(data["$values"][i].notificationId)}>
                  <div className={styles.floatingHead}
                  >
                    <Moment format="MMM">
                      {data["$values"][i].sendDateTime}
                    </Moment>
                    <br />
                    <Moment format="DD">
                      {data["$values"][i].sendDateTime}
                    </Moment>
                  </div>
                  <h4 className={styles.notificationTextTitle}>{data["$values"][i].title.substr(0, 298)}</h4>
                  {/* <h6 className={styles.notificationText}>{data["$values"][i].desciption.length > 298 ? data["$values"][i].desciption.substr(0, 298) + '...' : data["$values"][i].desciption}</h6> */}
                </Card>
              </Grid>
            ))
              :
              <Grid item xs={12} sm={12} md={6} sx={{ paddingInline: '12px' }}>
                <Card className={styles.card}>
                  <div className={styles.floatingHead}>
                    <Moment format="MMM">
                      {/* {new Date()} */}
                    </Moment>
                    <br />
                    <Moment format="DD">
                      {/* {new Date()} */}
                    </Moment>
                  </div>
                  <h4 className={styles.notificationTextTitle}>{t('notificationnotfound')}</h4>
                  {/* <h6 className={styles.notificationText}>{data["$values"][i].desciption}</h6> */}
                </Card>
              </Grid>
        }
        {notificationModal}
      </Grid>
    </div>
  )
}
