export default function swDev() {
  const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(swUrl)
      .then((registration) => {
        console.warn('Service Worker registered:', registration);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }
}
