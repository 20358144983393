// src/components/Header.js
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    useMediaQuery,
    useTheme
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { LoginContext } from '../contexts/LoginContext';
import styles from '../styles/Home.module.css';
function Header({ onThemeChange, isTransperant }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [themeMenuAnchor, setThemeMenuAnchor] = useState(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const { themeColor, setThemeColor } = useContext(LoginContext)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleThemeMenuOpen = (event) => setThemeMenuAnchor(event.currentTarget);
    const handleThemeMenuClose = (color) => {
        setThemeMenuAnchor(null);
        if (color) setThemeColor(color); // Passing selected color to App component
    };

    const menuItems = [
        // { text: 'Home', link: '/medineer' },
        { text: 'Scholarship', link: '/medineer/scholarship' },     //'/scholarship' },
        { text: 'Test Series', link: '/medineer/test-series' },  //_/test-series' },
    ];

    const activeStyle = {
        fontWeight: 'bold',
        border: `1px solid ${theme.palette.activeNavs.main}`,
        background: `${theme.palette.activeNavs.main}`,
        boxShadow: `1px 2px 7px ${theme.palette.activeNavsShadow.main}`,
        color: theme.palette.activeNavsLinks.main, // Highlighting color for active tab
    };

    const renderMenuButtons = () =>
        menuItems.map((item) => (
            <Button
                key={item.text}
                // color={theme.palette.navLinks.main}    //"inherit"
                component={NavLink}
                to={item.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                sx={{
                    color: theme.palette.navLinks.main,
                    fontFamily: 'Poppins',  // using and Apply Fira Sans font for headers only
                    fontSize: '16px',
                    textTransform: 'none',
                    // marginInline: '21px',
                    paddingInline: '20px'
                }}
            >
                {item.text}
            </Button>
        ));
    const handleRedirectToLearner = () => {
        navigate("/medineer/SignIn");
        // Navigate to the external URL
        // window.location.href = 'https://livestorage1710.z29.web.core.windows.net/signIn';
    };
    const gradientBackgrounds = {
        // vividorange: 'linear-gradient(to right, #ff6a00ab,#fab237)',
        // vividorange: 'linear-gradient(to right, #ffffff,#ffffff)',
    };
    return (
        // position="sticky"    will add in appbar for this position
        <AppBar position="fixed"
            style={{
                background: 'linear-gradient(to bottom, #b8dcee, #e9fde0)',
                boxShadow: 'none',
                //  paddingTop: '15px',
                // paddingBottom: isTransperant == false ? '10px' : '10px'
                height: '90px',
                justifyContent: 'space-evenly'
            }} >
            {/* gradientBackgrounds[themeColor] }} > */}
            {/* //color={"primary"}> */}
            <Toolbar>

                {isMobile ? (
                    <>
                        <IconButton color="inherit" edge="start" onClick={toggleDrawer(true)} sx={{ mr: 2 }}>
                            <MenuIcon />
                        </IconButton>
                        <span className={styles.MEDINEERfrMobile} onClick={() => navigate("/medineer")}>MEDINEER</span>
                        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                            <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                                {menuItems.map((item) => (
                                    <ListItem
                                        button
                                        key={item.text}
                                        component={NavLink}
                                        to={item.link}
                                        style={({ isActive }) => (isActive ? activeStyle : undefined)}
                                        disabled={(item.text == 'Scholarship' || item.text == 'Test Series') ? true : false}
                                    >
                                        <ListItemText primary={item.text} />
                                    </ListItem>
                                ))}
                                {/* <ListItem>
                                    <Button                                // color="inherit"
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '17px',
                                            textTransform: 'none',
                                            marginInline: '21px',
                                            paddingInline: '15px',
                                            color: theme.palette.navLinks.main,
                                        }} onClick={handleThemeMenuOpen}>
                                        Change Theme
                                    </Button>
                                    <Menu
                                        anchorEl={themeMenuAnchor}
                                        open={Boolean(themeMenuAnchor)}
                                        onClose={() => handleThemeMenuClose()}
                                    >
                                        <MenuItem onClick={() => handleThemeMenuClose('purple')}>Purple</MenuItem>
                                        <MenuItem onClick={() => handleThemeMenuClose('green')}>Green</MenuItem>
                                        <MenuItem onClick={() => handleThemeMenuClose('blue')}>Blue</MenuItem>
                                        <MenuItem onClick={() => handleThemeMenuClose('teal')}>Teal</MenuItem>
                                        <MenuItem onClick={() => handleThemeMenuClose('deamblue')}>Deam Blue</MenuItem>
                                        <MenuItem onClick={() => handleThemeMenuClose('vividorange')}>Vivid Orange</MenuItem>
                                    </Menu>
                                </ListItem> */}
                                <ListItem>
                                    <Button
                                        sx={{
                                            // ml: 'auto',
                                            fontFamily: 'Poppins',
                                            // fontSize: '17px',
                                            textTransform: 'none',
                                            // marginInline: '21px',
                                            // paddingInline: '15px',
                                            color: 'gray',
                                        }}
                                        onClick={handleRedirectToLearner}
                                    >
                                        Sign In
                                    </Button>
                                </ListItem>
                            </List>
                        </Drawer>
                    </>
                ) : (
                    <>
                        <Box sx={{ paddingLeft: '33px' }}>
                            <span className={styles.MEDINEER} onClick={() => navigate("/medineer")}>
                                MEDINEER
                            </span>
                        </Box>
                        {/* Theme Menu Button */}
                        <Box sx={{ ml: 'auto' }}>
                            {
                                renderMenuButtons()
                            }
                            {/* <Button                                // color="inherit"
                                sx={{
                                    fontFamily: 'Fira Sans, sans-serif',
                                    fontSize: '17px',
                                    textTransform: 'none',
                                    marginInline: '21px',
                                    paddingInline: '15px',
                                    color: theme.palette.navLinks.main,
                                }} onClick={handleThemeMenuOpen}>
                                Change Theme
                            </Button>
                            <Menu
                                anchorEl={themeMenuAnchor}
                                open={Boolean(themeMenuAnchor)}
                                onClose={() => handleThemeMenuClose()}
                            >
                                <MenuItem onClick={() => handleThemeMenuClose('purple')}>Purple</MenuItem>
                                <MenuItem onClick={() => handleThemeMenuClose('green')}>Green</MenuItem>
                                <MenuItem onClick={() => handleThemeMenuClose('blue')}>Blue</MenuItem>
                                <MenuItem onClick={() => handleThemeMenuClose('teal')}>Teal</MenuItem>
                                <MenuItem onClick={() => handleThemeMenuClose('deamblue')}>Deam Blue</MenuItem>
                                <MenuItem onClick={() => handleThemeMenuClose('vividorange')}>Vivid Orange</MenuItem>
                            </Menu> */}
                            <Button
                                className={styles.Rectangle21}
                                // sx={{
                                //     ml: 'auto', fontFamily: 'Fira Sans, sans-serif', fontSize: '17px', textTransform: 'none',
                                //     marginInline: '21px',
                                //     paddingInline: '15px',
                                //     color: theme.palette.navLinks.main,
                                // }}
                                onClick={handleRedirectToLearner}
                            >
                                Sign in
                            </Button>
                        </Box>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Header;
