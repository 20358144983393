import React, { useState } from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import InfoOutline from "@material-ui/icons/InfoOutline";

// core components
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Components/Grid/GridContainer";
import GridItem from "../Components/Grid/GridItem";



import styles from "../assets/dashboardStyle";

import { Assignment, AssignmentTurnedIn, Help, ListAlt, Timeline } from "@material-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";


import { Divider } from "@material-ui/core";
import { PublishedWithChanges } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UseGetPieCharAnalytics } from "../Components/analyticsHooks/UseGetPieChartData";
import { UseGetTopFiveAnalytics } from "../Components/analyticsHooks/UseGetTopFiveAnalytics";
import { LoginContext } from "../contexts/LoginContext";
import useGetCourseCount from "../testSeriesHooks/useGetCourseCount";
import useGetAssignedTestsDetails from "../testsHooks/useGetAssignedTestsDetails";
import DashboardTable from "./DashboardTable";
import PieChartComponent from "./PieChartDashboard";

const useStyles = makeStyles(styles);

function StudentDashboard(props) {

  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const { userPrivileges, pageKey, setPageKey, setIsDrawerNavigation, testSeriesStatus, setTestSeriesStatus, setStatus, status, setAssessmet, UserId } = useContext(LoginContext)
  const [topFive, setTopFiveTests] = useState([]);
  const [pieChartAnalyticsData, setPieChartAnaltyticsData] = useState();
  console.log("data:", userPrivileges);
  useEffect(() => {
    const getChartData = async () => {
      try {
        const pieChartData = await UseGetPieCharAnalytics(UserId);
        if (pieChartData != null) {
          console.log("piecharatdatatata", pieChartData);
          setPieChartAnaltyticsData(pieChartData);
        };
      } catch (e) {
        console.log("errrr getting pie data", e);
      }
    }
    getChartData();
  }, [UserId])

  const getServices = async () => {
    if (topFive?.length < 1) {
      const testAnalytics = await UseGetTopFiveAnalytics(UserId);
      if (topFive?.length < testAnalytics?.length) {
        console.log("length is greater")
        setTopFiveTests(testAnalytics)
      }
      console.log("consoleanalyticsData", testAnalytics)
    }
  }

  useEffect(() => {
    getServices();
  }, [UserId])

  const { data: details } = useGetAssignedTestsDetails(0);

  const { data: courseCount } = useGetCourseCount();


  const navigate = useNavigate();

  // const isPrivilege = (x) => {
  //   console.log(userPrivileges?.privileges, 'privileges1234')
  //   if (userPrivileges?.privileges?.Test?.indexOf(x) !== -1) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  function getDiff() {
    const currentDate = new Date(); // Current date in local timezone
    const currentUtcDate = new Date(currentDate.toUTCString());

    const timezoneOffsetLocal = currentDate.getTimezoneOffset(); // Offset in minutes
    const timezoneOffsetUTC = currentUtcDate.getTimezoneOffset(); // Offset in minutes

    const offsetHoursLocal = Math.abs(timezoneOffsetLocal / 60);
    const offsetMinutesLocal = Math.abs(timezoneOffsetLocal % 60);

    const offsetHoursUTC = Math.abs(timezoneOffsetUTC / 60);
    const offsetMinutesUTC = Math.abs(timezoneOffsetUTC % 60);

    return offsetHoursLocal;
  }
  const toLocalCheckExpired = (UTCTime) => {
    // converting into local time start
    const now = new Date();
    const utcDateTime = new Date(UTCTime);
    let diff = getDiff();
    console.log(diff, 'inDiff')
    const istOffset = diff * 60 * 60 * 1000; // IST is UTC+5.5 hours
    const istDateTime = new Date(utcDateTime.getTime() + istOffset);
    return (istDateTime < now)
    // return istDateTime
  }

  const isPrivilege = (x) => {
    if (userPrivileges?.privileges?.test?.$values?.includes(x)) {
      return true;
    } else if (userPrivileges?.Privileges?.Test.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card
            style={{ cursor: "pointer", border: 'solid 2px #f8cb1e', borderRadius: '12px 12px 0px 0px' }}
          >
            <CardHeader color="warning" style={{ background: 'linear-gradient(to left, #fb8d02, #ffa422)', borderRadius: '12px 12px 0px 0px', padding: '12px 0' }} stats icon>
              {/* <CardIcon color="warning">
                <ListAlt />
              </CardIcon> */}
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle} style={{ color: 'white' }}>
                <ListAlt style={{ width: '24px', height: '24px' }} />{t('totalTests')}
                {/* ({details ? details.length : "..."}) */}
              </h4>
            </CardHeader>
            <CardFooter stats style={{ margin: '0px', padding: '0px', justifyContent: 'space-around' }}>
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(6);
                  setIsDrawerNavigation(false);
                  setTestSeriesStatus("ALL")
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTestSeries')} ({courseCount ? courseCount.total : "..."})</h6>
                {/* <LaunchOutlined className={classes.cardfooterButtonsIcons}/>  */}
              </Button>
              <Divider orientation="vertical" flexItem style={{ backgroundColor: "#F8CD26", width: '2px' }} />
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(5)
                  setStatus("ALL")
                  setAssessmet(true)
                }} ><h6 className={classes.cardfooterText}>{t('assignedTests')} ({details ? details.length : "..."})</h6> </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card
            style={{ cursor: "pointer", border: 'solid 2px #f8cb1e', borderRadius: '12px 12px 0px 0px' }}
          >
            <CardHeader color="danger" style={{ background: 'linear-gradient(to left,#e63a36, #f0524f)', borderRadius: '12px 12px 0px 0px', padding: '12px 0' }} stats icon>
              {/* <CardIcon color="danger">
                <PublishedWithChanges />
              </CardIcon> */}
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle} style={{ color: 'white' }}>
                <PublishedWithChanges style={{ width: '24px', height: '24px' }} />{t('InProgress')}
              </h4>
            </CardHeader>
            <CardFooter stats style={{ margin: '0px', padding: '0px', justifyContent: 'space-around' }}>
              {/* {t('seeDetails')} */}
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(6)
                  setIsDrawerNavigation(false);
                  setTestSeriesStatus("INPROGRESS")
                }} ><h6 className={classes.cardfooterText}>{t('assignedTestSeries')} ({courseCount ? courseCount.inProgress : "..."})</h6> </Button>
              <Divider orientation="vertical" flexItem style={{ backgroundColor: "#F8CD26", width: '2px' }} />
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(5)
                  setStatus("INPROGRESS")
                  setAssessmet(true)
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTests')} ({details ? details.filter(x => x.attemptNumber >= 1 && x.attemptNumber < x.maxAttempts).length : "..."})</h6> </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card
            style={{ cursor: "pointer", border: 'solid 2px #f8cb1e', borderRadius: '12px 12px 0px 0px' }}
          >
            <CardHeader color="info" style={{ background: 'linear-gradient(to left,#01adc1, #24c5d9)', borderRadius: '12px 12px 0px 0px', padding: '12px 0' }} stats icon>
              {/* <CardIcon color="info">
                <Help />
              </CardIcon> */}
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle} style={{ color: 'white' }}>
                <Help style={{ width: '24px', height: '24px' }} />{t('pending')}
              </h4>
            </CardHeader>
            <CardFooter stats style={{ margin: '0px', padding: '0px', justifyContent: 'space-around' }}>
              {/* {t('seeDetails')} */}
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(6);
                  setIsDrawerNavigation(false);
                  setTestSeriesStatus("PENDING")
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTestSeries')} ({courseCount ? courseCount.pending : "..."})</h6> </Button>
              <Divider orientation="vertical" flexItem style={{ backgroundColor: "#F8CD26", width: '2px' }} />
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(5)
                  setStatus("PENDING")
                  setAssessmet(true)
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTests')} ({details ? details.filter(x => (x.attemptNumber < x.maxAttempts && (x.endDate === null || !toLocalCheckExpired(x.endDate)))).length : "..."})</h6> </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card
            style={{ cursor: "pointer", border: 'solid 2px #f8cb1e', borderRadius: '12px 12px 0px 0px' }}
          >
            <CardHeader color="success" style={{ background: 'linear-gradient(to left,#44a148, #64b968)', borderRadius: '12px 12px 0px 0px', padding: '12px 0' }} stats icon>
              {/* <CardIcon color="success">
                <Check />
                <AssignmentTurnedIn />
              </CardIcon> */}
              <p className={classes.cardCategory}></p>
              <h4 className={classes.cardTitle} style={{ color: 'white' }}>
                <AssignmentTurnedIn style={{ width: '24px', height: '24px' }} />{t('completed')}
              </h4>
            </CardHeader>
            <CardFooter stats style={{ margin: '0px', padding: '0px', justifyContent: 'space-around' }}>
              {/* {t('seeDetails')} */}
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(6);
                  setIsDrawerNavigation(false);
                  setTestSeriesStatus("COMPLETED")
                }}><h6 className={classes.cardfooterText}>{t('assignedTestSeries')} ({courseCount ? courseCount.completed : "..."})</h6> </Button>
              <Divider orientation="vertical" flexItem style={{ backgroundColor: "#F8CD26", width: '2px' }} />
              <Button
                className={classes.cardfooterButtons}
                onClick={() => {
                  setPageKey(5)
                  setStatus("COMPLETED")
                  setAssessmet(true)
                }}
              ><h6 className={classes.cardfooterText}>{t('assignedTests')} ({details ? details.filter(x => x.attemptNumber >= x.maxAttempts || (x.endDate != null && (toLocalCheckExpired(x.endDate)) == true ? true : false)).length : "..."})</h6> </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {
        console.log(isPrivilege("ComparativeAnalyticsForTop5Tests") == true, 'addedLogfortop5')
      }
      <GridContainer>
        {
          isPrivilege("ComparativeAnalyticsForTop5Tests") == true ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card className={classes.lastfiveTestCard} style={{ marginBottom: '8px', border: 'solid 1px #cbd0e3', borderRadius: '12px 12px 0px 0px' }}>
                <CardHeader color="success" icon style={{ color: '#2e2f30', padding: '1% 2%', borderBottom: '1px solid #dddedf', fontFamily: 'Poppins', fontSize: '18px ' }}>
                  {/* <CardIcon color="success">
                    <Assignment />
                  </CardIcon> */}
                  <h4 style={{ textAlign: 'left' }}> <Assignment style={{ margin: '0px 4px 2px' }} />&nbsp;{t('lastFivetests')}</h4>
                </CardHeader>
                <CardBody className={classes.dashboardCardBody} style={{ padding: '0px', overflowY: 'hidden' }}>
                  {topFive ? <DashboardTable topFive={topFive} /> : <></>}
                </CardBody>
              </Card>
              <small style={{ float: 'right', color: '#f62a25', fontFamily: 'Poppins', fontSize: '12px' }}>* Below Average of last 5 tests</small>
            </GridItem> : null

        }
        {
          isPrivilege("ComparativeAnalyticsForTop5Tests") == true ?
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card style={{ border: 'solid 1px #cbd0e3', borderRadius: '12px 12px 0px 0px', boxShadow: '0 2px 5.2px 2px rgba(0, 0, 0, 0.05)' }}>
                <CardHeader color="success" icon style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#2e2f30', padding: '1% 2%', borderBottom: '1px solid #dddedf', boxShadow: '0 2px 5.2px 2px rgba(0, 0, 0, 0.05)', fontFamily: 'Poppins', fontSize: '18px ' }}>
                  {/* <CardIcon color="success">
                    <Timeline />
                  </CardIcon> */}
                  <h4 className={classes.cardIconTitle} style={{ textAlign: 'left' }}> <Timeline style={{ margin: '0px 4px 2px' }} />&nbsp;{t('whereareYou')}</h4>
                </CardHeader>
                <CardBody style={{ backgroundColor: '#f5f6fa', padding: '0.9375rem 5px' }}>
                  {/* {topFive ? <DashboardChart topFive={topFive} /> : <></>} */}
                  <PieChartComponent pieChartAnalyticsData={pieChartAnalyticsData} />
                </CardBody>
              </Card>
            </GridItem> : null

        }
      </GridContainer>

    </div >
  );
}
export default StudentDashboard;
