import React from "react";
// material-ui components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
// core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";

import styles from "../../assets/customSelectStyle";

const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
  console.log(props.options)
  const [simpleSelect, setSimpleSelect] = React.useState(props.value);



  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);

    props.setvalue(event.target.value)
  };

  const classes = useStyles();
  return (
    // props.isTopMargin == true ? '36px 0 5px 10px !important'
    <FormControl fullWidth className={props.isTopMargin == true ? classes.selectFormControlwithTopMargin : classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel} style={{ fontSize: '18px' }}>
        {props.label}
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={simpleSelect}
        onChange={handleSimple}
        inputProps={{
          name: "simpleSelect",
          id: "simple-select",
        }}
      >
        {props.options.map((x, i) =>
          <MenuItem
            key={i}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={x.id}
          >
            {x.name}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
